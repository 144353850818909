import { crmConstants } from "../constants/crmConstants";

export function crmLogic(state = {}, action) {
  switch (action.type) {
    //get customer
    case crmConstants.GET_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action?.message?.data?.data,
        type: "success",
        message: action.message,
      };
    case crmConstants.GET_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // add customer
    case crmConstants.ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "ADD_CUSTOMER_SUCCESS",
      };
    case crmConstants.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // edit customer
    case crmConstants.EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "EDIT_CUSTOMER_SUCCESS",
      };
    case crmConstants.EDIT_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // delete customer
    case crmConstants.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "DELETE_CUSTOMER_SUCCESS",
      };
    case crmConstants.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // get all notes
    case crmConstants.GET_ALL_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.GET_ALL_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: action.notes,
        type: "GET_ALL_NOTES_SUCCESS",
      };
    case crmConstants.GET_ALL_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        notes: [],
        message: action.message,
        type: "error",
      };

    // add notes
    case crmConstants.ADD_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.ADD_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "ADD_NOTES_SUCCESS",
      };
    case crmConstants.ADD_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };

    // delete notes
    case crmConstants.DELETE_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.DELETE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "DELETE_NOTES_SUCCESS",
      };
    case crmConstants.DELETE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };

    // edit note
    case crmConstants.EDIT_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case crmConstants.EDIT_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        type: crmConstants.EDIT_NOTES_SUCCESS,
      };
    case crmConstants.EDIT_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };

    default:
      return state;
  }
}
