import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { timezones } from './timezones';

const TimezoneDropdown = ({ selectedTimezone, handleTimezoneChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="timezone-label">Select Timezone Offset</InputLabel>
      <Select
        labelId="timezone-label"
        id="timezone"
        value={selectedTimezone}
        label="Select Timezone Offset"
        onChange={(e) => handleTimezoneChange(e.target.value)}
      >
        {timezones.map((timezone) => (
          <MenuItem key={timezone.value} value={timezone.value}>
            {timezone.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimezoneDropdown;
