import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { userService } from '../services';

function PrivateRoute({ component: Component, roles, ...rest }) {
    return (
        <Route {...rest} render={props => {
            if (!localStorage.getItem('user')) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            else {
                let res = <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                userService.checkToken()
                    .then(
                        // logged in so return component
                        res = <Component {...props} />
                    );

                return res;
            }

            
        }} />
    );
}

export { PrivateRoute };