import { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import JsSIP from "jssip";
import io from "socket.io-client";
import { customCallActions } from "../actions/customCallActions";

const {
  registerSIP,
  setIncommingCall,
  declineCallRequest,
  getAudioRef,
  startCaptureVoice,
  stopCaptureVoice,
} = customCallActions;

const SOCKET_URL = "https://testvocapp-ws.articence.com";
const SIP_WS_URL = "wss://pbx.articence.com:8089/ws";
const SIP_URI = "sip:1002@pbx.articence.com";
const SIP_PASSWORD = "3dad3e8534cc407efc50efd127356075";

// const SIP_URI = "sip:1003@pbx.articence.com";
// const SIP_PASSWORD = "a71d4dfbe178d6ff9d6d37484ed05ada";

const RECONNECT_INTERVAL = 30000;
const HEARTBEAT_INTERVAL = 60000;
const REGISTRATION_INTERVAL = 30;

const SipConnector = () => {
  const dispatch = useDispatch();
  const uaRef = useRef(null);
  const sessionRef = useRef(null);
  const socketRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const reconnectTimerRef = useRef(null);
  const heartbeatTimerRef = useRef(null);
  const registrationTimerRef = useRef(null);
  const isConnectedRef = useRef(false);

  const [transcriptions, setTranscriptions] = useState([]);

  const setupSocketConnection = useCallback(() => {
    if (socketRef.current?.connected) return;

    socketRef.current = io(SOCKET_URL, {
      transports: ["websocket"],
      upgrade: false,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000, // Start with a 1-second delay
      reconnectionDelayMax: 30000, // Maximum delay of 30 seconds
      timeout: 20000, // Timeout for connecting
      pingTimeout: 5000, // 5-second timeout for pings
      pingInterval: 10000, // 10 seconds between pings
    });

    // Append the transcription to the existing ones
    socketRef.current.on("transcription_update", (data) => {
      setTranscriptions((prevTranscriptions) => [
        ...prevTranscriptions,
        data.transcription,
      ]);
      console.log("Transcription:", data.transcription);
    });
    socketRef.current.emit("toggle_transcription", { action: "start" });

    socketRef.current.on("connect", () => {
      console.log("Socket.IO connected");
      socketRef.current.emit("toggle_transcription", { action: "start" });
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket.IO disconnected, attempting to reconnect...");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });
  }, []);

  const forceReregister = useCallback(() => {
    if (uaRef.current && uaRef.current.isRegistered()) {
      console.log("Forcing re-registration with SIP server");
      uaRef.current.register();
    }
  }, []);

  const setupSIPConnection = useCallback(() => {
    if (uaRef.current?.isConnected()) return;

    const sipSocket = new JsSIP.WebSocketInterface(SIP_WS_URL);
    const configuration = {
      sockets: [sipSocket],
      uri: SIP_URI,
      password: SIP_PASSWORD,
      register: true,
      register_expires: REGISTRATION_INTERVAL,
      connection_recovery_min_interval: 2,
      connection_recovery_max_interval: 30,
      use_preloaded_route: true, // Helps maintain connection
      hack_ip_in_contact: true, // Helps with NAT traversal
      contact_uri: null, // Will be automatically generated
    };

    const ua = new JsSIP.UA(configuration);
    uaRef.current = ua;

    ua.on("connected", () => {
      console.log("WebSocket connected");
      isConnectedRef.current = true;
      startHeartbeat();
      if (reconnectTimerRef.current) {
        clearInterval(reconnectTimerRef.current);
        reconnectTimerRef.current = null;
      }
    });

    ua.on("disconnected", () => {
      console.log("WebSocket disconnected");
      isConnectedRef.current = false;
      stopHeartbeat();
      startReconnectionTimer();
    });

    ua.on("registered", () => {
      console.log("Registered with SIP server");
      dispatch(registerSIP(ua));
      // Start registration renewal timer
      if (registrationTimerRef.current) {
        clearInterval(registrationTimerRef.current);
      }
      registrationTimerRef.current = setInterval(() => {
        forceReregister();
      }, (REGISTRATION_INTERVAL * 1000) / 2); // Renew at half the expiry time
    });

    ua.on("unregistered", () => {
      console.log("Unregistered from SIP server");
      if (registrationTimerRef.current) {
        clearInterval(registrationTimerRef.current);
      }
      startReconnectionTimer();
    });

    ua.on("registrationFailed", (event) => {
      console.error("Registration failed:", event);
      if (registrationTimerRef.current) {
        clearInterval(registrationTimerRef.current);
      }
      startReconnectionTimer();
    });

    ua.on("newRTCSession", handleNewRTCSession);

    ua.start();
  }, [dispatch, forceReregister]);

  const startHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) return;

    heartbeatTimerRef.current = setInterval(() => {
      if (uaRef.current && socketRef.current) {
        // Check SIP connection
        if (!uaRef.current.isConnected() || !uaRef.current.isRegistered()) {
          console.log("Heartbeat detected connection issue, reconnecting...");
          // setupSIPConnection();
        }

        if (!socketRef.current.connected) {
          console.log(
            "Heartbeat detected Socket.IO disconnection, reconnecting..."
          );
          // setupSocketConnection();
        }
      }
    }, HEARTBEAT_INTERVAL);
  }, [setupSIPConnection, setupSocketConnection]);

  const stopHeartbeat = useCallback(() => {
    if (heartbeatTimerRef.current) {
      clearInterval(heartbeatTimerRef.current);
      heartbeatTimerRef.current = null;
    }
  }, []);

  // Rest of the code remains the same as in previous version
  const handleNewRTCSession = useCallback(
    (data) => {
      const session = data.session;
      sessionRef.current = session;

      dispatch(setIncommingCall(session));

      if (session.direction === "incoming") {
        playRingtone();
        dispatch(setIncommingCall(session));
        dispatch(startCaptureVoice());
        session.on("peerconnection", (e) => {
          const peerConnection = e.peerconnection;

          peerConnection.addEventListener("track", (event) => {
            if (event.track.kind === "audio") {
              const stream = event.streams[0];
              if (remoteAudioRef.current) {
                remoteAudioRef.current.srcObject = stream;
                remoteAudioRef.current.play().catch((error) => {
                  console.error("Error playing audio:", error);
                });

                dispatch(
                  getAudioRef({
                    audioRef: remoteAudioRef.current,
                  })
                );
              }
            }
          });
        });
      }

      const sessionEvents = {
        accepted: () => {
          console.log("Call accepted");
          window.isCallConnected = true;
          dispatch(customCallActions.startCaptureVoice());
        },
        ended: () => {
          console.log("Call ended");
          dispatch(declineCallRequest());
          window.isCallConnected = false;
          // dispatch(customCallActions.StoreCallLogs());
          dispatch(stopCaptureVoice());
        },
        failed: () => {
          console.log("Call failed");
          dispatch(declineCallRequest());
          window.isCallConnected = false;
          // dispatch(customCallActions.StoreCallLogs());
          dispatch(stopCaptureVoice());
        },

        hold: () => {
          console.log("Call on hold");
          window.isCallConnected = true;
        },
        unhold: () => {
          console.log("Call resumed");
          window.isCallConnected = true;
        },
        muted: () => {
          console.log("Call muted");
          window.isCallConnected = true;
        },
        unmuted: () => {
          console.log("Call unmuted");
          window.isCallConnected = true;
        },
      };

      Object.entries(sessionEvents).forEach(([event, handler]) => {
        session.on(event, handler);
      });

      session.connection.ontrack = (event) => {
        const audioRef = (remoteAudioRef.current.srcObject = event.streams[0]);
        dispatch(getAudioRef(audioRef));
      };
    },
    [dispatch]
  );
  

  const startReconnectionTimer = useCallback(() => {
    if (reconnectTimerRef.current) return;

    reconnectTimerRef.current = setInterval(() => {
      if (!isConnectedRef.current) {
        console.log("Attempting to reconnect...");
        // setupSocketConnection();
        // setupSIPConnection();
      }
    }, RECONNECT_INTERVAL);
  }, [setupSocketConnection, setupSIPConnection]);

  const playRingtone = () => {
    const ringtone = new Audio("/path/to/ringtone.mp3");
    ringtone.loop = true;
    ringtone.play().catch((error) => {
      console.error("Error playing ringtone:", error);
    });
  };

  setupSocketConnection();
  setupSIPConnection();

  // useEffect(() => {
  //   setupSocketConnection();
  //   setupSIPConnection();

  //   return () => {
  //     if (reconnectTimerRef.current) {
  //       clearInterval(reconnectTimerRef.current);
  //     }
  //     if (heartbeatTimerRef.current) {
  //       clearInterval(heartbeatTimerRef.current);
  //     }
  //     if (registrationTimerRef.current) {
  //       clearInterval(registrationTimerRef.current);
  //     }
  //     if (uaRef.current) {
  //       uaRef.current.stop();
  //     }
  //     if (socketRef.current) {
  //       socketRef.current.disconnect();
  //     }
  //   };
  // }, [setupSocketConnection, setupSIPConnection]);

  return <audio ref={remoteAudioRef} autoPlay />;
};

export default SipConnector;
