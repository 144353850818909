import React from 'react';
import cn from 'classnames';
import './inputsFields.css';

const Button = ({ id, style, customCSS, btnTextClass, type, small, text, clickHandler, isSpinner, disable=false}) => {
    const click = (id) => {
        clickHandler(id);
    };

    let css = cn('customBtn', customCSS, type, { small }, disable && 'disableBtn');
    return (
        <a id={id} disabled={true} className={css} style={style} onClick={() => click(id)}><span className={btnTextClass}>{isSpinner && <span className="spinner-border spinner-border-sm mr-1"></span>}{text}</span></a>
    );
};

export default Button;