import api from "./api";

async function addRules(addRulesData, Id) {
    const result = await api.post(`/createcallHandelingRules`, addRulesData);
    return result;
  }

  async function getRules() {
    const result = await api.get(`/getallcallHandelingRules`);
    return result;
  }

  async function editRules(addRulesData, RuleId) {
    const result = await api.put(`/editparticularcallRules/${RuleId}`, addRulesData);
    return result;
  }

  async function getRuleById(RuleId) {
    const result = await api.get(`/getparticularcallRules/${RuleId}`);
    return result;
  }

  async function deleteRule(RuleId) {
    const result = await api.delete(`/deleteparticularcallRules/${RuleId}`);
    return result;
  }

  export const RulesService = {
    addRules,
    getRules,
    editRules,
    getRuleById,
    deleteRule
  }