import { dropDownSerivce } from "../services/dropDownService.js";
import {dropDownConstants} from "../constants/dropDownConstants.js";
import { swalAlert } from "../voc/Common/SwalAlert.js";
import { alertActions } from "./alertActions.js";

const fetchCountriesOptions = () => {
  const request = () => ({
    type: dropDownConstants.FETCH_COUNTRIES_REQUEST,
  });
  const success = (countries) => ({
    type: dropDownConstants.FETCH_COUNTRIES_SUCCESS,
    countries,
  });
  const failure = (error) => ({
    type: dropDownConstants.FETCH_COUNTRIES_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    dropDownSerivce.getCountriesOptions().then(
      (countries) => {
        if (countries) {
          dispatch(success(countries));
        }
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const fetchStatesOptions = (countryCode) => {
  const request = () => ({
    type: dropDownConstants.FETCH_STATES_REQUEST,
  });
  const success = (states) => ({
    type: dropDownConstants.FETCH_STATES_SUCCESS,
    states,
  });
  const failure = (error) => ({
    type: dropDownConstants.FETCH_STATES_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    dropDownSerivce.getStateOptions(countryCode).then(
      (states) => {
        if (states) {
          dispatch(success(states));
        }
      },
      (error) => {
        const errorMessage = error?.response?.data?.message?.toString();
        dispatch(failure(errorMessage));
        dispatch(alertActions.error(errorMessage));
        swalAlert({
          icon: "error",
          title: errorMessage,
          showConfirmButton: true,
        });
      }
    );
  };
};
export const dropDownActions = {
  fetchCountriesOptions,
  fetchStatesOptions,
};
