import { surveyConstants } from "../constants";

export function survey(
  state = {
    surveyType: "month",
    longSurveyType: "month",
    questionAnalysisData: null,
    lexicalQuestionAnalysisData: null,

    alertQuestionsData: null,
    lexicalSurveyYear: new Date().getFullYear(),
    lexicalSurveyMonth: "all",
    surveyYear: new Date().getFullYear(),
    surveyMonth: "all",
    answerData: null,
    longSurveyYear: new Date().getFullYear(),
    longSurveyMonth: "all",
    surveyEffectivenessYear: new Date().getFullYear(),
    surveyEffectivenessMonth: "all",
    otherQuestionId: null,
    longQuestionId: null,
    longTextSummary: null,
    longTextAudienceSummary: null,
    rawDataQuestionId: null,
    rawDataProfanity: "all",
    rawDataSentiments: "all",
    rawDataPageFilter: 1,
    rawDataSorting: "",
    rawDataSearching: "",
    shareAlert: null,
    alertQuestionId: null,
    lexicalQuestionId: null,

    alertProfanity: "all",
    alertSentiment: "all",
    alertPageFilter: 1,
    alertSorting: "",
    alertSearching: "",
    alertStatus: "current",

    prompt: false,
    categoryInsights: null,
    aiRecommendationData: null,
    categoryInsightsByQuestion: null,
    aiFilter: "all",
    audienceAiFilter: "all",
    audienceStateFilter: "all",

    lexicalAudienceStateFilter: "all",

    surveyEffectivenessChannel: "email_link",
    surveyEffectivenessCountry: "all",
    surveyEffectivenessData: null,
    surveyEffectivenessSocialGroup: "all",

    surveyEffectivenessAbandonedChannel: "email_link",
    surveyEffectivenessAbandonedCountry: "all",
    surveyEffectivenessAbandonedData: null,
    surveyEffectivenessAbandonedSocialGroup: "all",

    surveyLexicalEffectivenessAbandonedChannel: "email_link",
    surveyLexicalEffectivenessAbandonedCountry: "all",
    surveyLexicalEffectivenessAbandonedData: null,
    surveyLexicalEffectivenessAbandonedSocialGroup: "all",
    profanityFilter: "all",
    shortAnswerFilter: "all",
    updateCustomCategory: null,
    getCustomCategory: null,
    activeMenu: null,
    sentimentFilter: [],
    trigger: null,
    allFilter: true,
    categories: null,
    addCategories: null,
    updateCategories: null,
    updateComboCategories: null,
    categorizationQuestionId: null,
    aiRecommendationStatus: null,
    processingStatus: false,
    aiRecommendationQuestionId: null,
    rawData: null,

    alertData: null,

    items: {},
    lexicalItems: {},
  },
  action
) {
  switch (action.type) {
    case surveyConstants.GETSURVEY_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case surveyConstants.GETSURVEY_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case surveyConstants.GETLEXICALSURVEY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETSHAREALERT_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETSHAREALERT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case surveyConstants.GETSHAREALERT_SUCCESS:
      return {
        ...state,
        shareAlert: action.survey.data,
      };
    case surveyConstants.AIRECOMMENDATIONSQUESTIONID:
      return {
        ...state,

        aiRecommendationQuestionId: action.questionId,
      };
    case surveyConstants.GETALERT_STATUS:
      return {
        ...state,
        alertStatus: action.filter,
      };
    case surveyConstants.SHOWPROMPT:
      return {
        ...state,

        prompt: action.prompt,
      };
    case surveyConstants.AIRECOMMENDATIONSPROCESSINGSTATUS:
      return {
        ...state,
        processingStatus: action.status,
      };
    case surveyConstants.AIRECOMMENDATIONSSTATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case surveyConstants.AIRECOMMENDATIONSSTATUS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case surveyConstants.AIRECOMMENDATIONSSTATUS_SUCCESS:
      return {
        ...state,
        aiRecommendationStatus: action.survey.data,
      };
    case surveyConstants.Tags_QUESTIONID:
      return {
        ...state,

        categorizationQuestionId: action.questionId,
      };
    case surveyConstants.ACTIVE_MENU:
      return {
        ...state,

        activeMenu: action.menu,
      };

    case surveyConstants.UPDATECUSTOMCATEGORIES_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.UPDATECUSTOMCATEGORIES_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.UPDATECUSTOMCATEGORIES_SUCCESS:
      return {
        ...state,

        updateCustomCategory: action.survey.data,
      };

    case surveyConstants.UPDATECUSTOMCATEGORIES_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.UPDATECOMBOCATEGORY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.UPDATECOMBOCATEGORY_SUCCESS:
      return {
        ...state,

        updateComboCategories: action.survey.data,
      };

    case surveyConstants.GETCUSTOMCATEGORIES_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETCUSTOMCATEGORIES_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETCUSTOMCATEGORIES_SUCCESS:
      return {
        ...state,

        getCustomCategory: action.survey.data,
      };

    case surveyConstants.GETCATEGORY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETCATEGORY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETCATEGORY_SUCCESS:
      return {
        ...state,

        categories: action.survey.data,
      };

    case surveyConstants.ADDCATEGORY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.ADDCATEGORY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.ADDCATEGORY_SUCCESS:
      return {
        ...state,

        addCategories: action.survey.data,
      };

    case surveyConstants.UPDATECATEGORY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.UPDATECATEGORY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.UPDATECATEGORY_SUCCESS:
      return {
        ...state,

        updateCategories: action.survey.data,
      };

    case surveyConstants.GETPROFANITYFILTER:
      return {
        ...state,

        profanityFilter: action.filter,
      };
    case surveyConstants.GETSENTIMENTFILTER:
      return {
        ...state,

        sentimentFilter: action.filter,
      };
    case surveyConstants.GETSHORTANSWERFILTER:
      return {
        ...state,

        shortAnswerFilter: action.filter,
      };
    case surveyConstants.GETALLFILTER:
      return {
        ...state,

        allFilter: action.filter,
      };
    case surveyConstants.GETAUDIENCE_AIFILTER:
      return {
        ...state,

        audienceAiFilter: action.filter,
      };
    case surveyConstants.GETRAWDATAPAGE_FILTER:
      return {
        ...state,

        rawDataPageFilter: action.filter,
      };

    case surveyConstants.GETALERTPAGE_FILTER:
      return {
        ...state,

        alertPageFilter: action.filter,
      };

    case surveyConstants.GETAUDIENCE_STATEFILTER:
      return {
        ...state,

        audienceStateFilter: action.filter,
      };

    case surveyConstants.GETLEXICALAUDIENCE_STATEFILTER:
      return {
        ...state,

        lexicalAudienceStateFilter: action.filter,
      };

    case surveyConstants.GETLONGSURVEY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETANSWERDATA_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETANSWERDATA_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETANSWERDATA_SUCCESS:
      return {
        ...state,

        answerData: action.survey.data,
      };

    case surveyConstants.RAWDATAQUESTION_ID:
      return {
        ...state,

        rawDataQuestionId: action.questionId,
      };

    case surveyConstants.ALERTQUESTION_ID:
      return {
        ...state,

        alertQuestionId: action.questionId,
      };

    case surveyConstants.RAWDATAPROFANITY:
      return {
        ...state,

        rawDataProfanity: action.filter,
      };

    case surveyConstants.ALERTPROFANITY:
      return {
        ...state,

        alertProfanity: action.filter,
      };
    case surveyConstants.ALERTSENTIMENT:
      return {
        ...state,

        alertSentiment: action.filter,
      };

    case surveyConstants.RAWDATASENTIMENT:
      return {
        ...state,

        rawDataSentiments: action.filter,
      };

    case surveyConstants.ALERTSORTING:
      return {
        ...state,

        alertSorting: action.filter,
      };

    case surveyConstants.RAWDATASORTING:
      return {
        ...state,

        rawDataSorting: action.filter,
      };

    case surveyConstants.ALERTSEARCHING:
      return {
        ...state,

        alertSearching: action.filter,
      };

    case surveyConstants.RAWDATASEARCHING:
      return {
        ...state,

        rawDataSearching: action.filter,
      };
    case surveyConstants.GETSURVEY_YEAR:
      return {
        ...state,

        surveyYear: action.surveyYear,
      };
    case surveyConstants.GETSURVEY_MONTH:
      return {
        ...state,

        surveyMonth: action.surveyMonth,
      };
    case surveyConstants.GETLEXICALSURVEY_MONTH:
      return {
        ...state,

        lexicalSurveyMonth: action.surveyMonth,
      };

    case surveyConstants.GETLEXICALSURVEY_YEAR:
      return {
        ...state,

        lexicalSurveyYear: action.surveyYear,
      };
    case surveyConstants.GETSURVEY_AIFILTER:
      return {
        ...state,

        aiFilter: action.filter,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESS_CHANNELFILTER:
      return {
        ...state,

        surveyEffectivenessChannel: action.channel,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESS_SOCIALGROUPFILTER:
      return {
        ...state,

        surveyEffectivenessSocialGroup: action.channel,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER:
      return {
        ...state,

        surveyEffectivenessAbandonedCountry: action.country,
      };

    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER:
      return {
        ...state,

        surveyLexicalEffectivenessAbandonedCountry: action.country,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER:
      return {
        ...state,

        surveyEffectivenessAbandonedChannel: action.channel,
      };

    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER:
      return {
        ...state,

        surveyLexicalEffectivenessAbandonedChannel: action.channel,
      };

    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER:
      return {
        ...state,

        surveyLexicalEffectivenessAbandonedSocialGroup: action.channel,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER:
      return {
        ...state,

        surveyEffectivenessAbandonedSocialGroup: action.channel,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESS_COUNTRYFILTER:
      return {
        ...state,

        surveyEffectivenessCountry: action.country,
      };

    case surveyConstants.GETLONGSURVEY_YEAR:
      return {
        ...state,

        longSurveyYear: action.longSurveyYear,
      };
    case surveyConstants.GETLONGSURVEY_MONTH:
      return {
        ...state,

        longSurveyMonth: action.longSurveyMonth,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESS_YEAR:
      return {
        ...state,

        surveyEffectivenessYear: action.year,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESS_MONTH:
      return {
        ...state,

        surveyEffectivenessMonth: action.month,
      };
    case surveyConstants.GETHELPTYPETRIGGER:
      return {
        ...state,
        trigger: action.trigger,
      };
    case surveyConstants.GETSURVEY_SUCCESS:
      return {
        ...state,
        items: action.survey.data,
      };
    case surveyConstants.GETSURVEY_FAILURE:
      return {
        ...state,

        error: action.error,
      };

    case surveyConstants.GETLEXICALSURVEY_SUCCESS:
      return {
        ...state,
        lexicalItems: action.survey.data,
      };
    case surveyConstants.GETLEXICALSURVEY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETLONGTEXT_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETLONGTEXT_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETLONGTEXT_SUCCESS:
      return {
        ...state,

        longTextSummary: action.survey.data,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESS_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESS_SUCCESS:
      return {
        ...state,

        surveyEffectivenessData: action.survey.data,
      };

    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETSURVEYEFFECTIVENESSABANDONED_SUCCESS:
      return {
        ...state,

        surveyEffectivenessAbandonedData: action.survey.data,
      };
    case surveyConstants.GETLEXICALSURVEYEFFECTIVENESSABANDONED_SUCCESS:
      return {
        ...state,

        surveyLexicalEffectivenessAbandonedData: action.survey.data,
      };
    case surveyConstants.GETRAWDATA_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETRAWDATA_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETRAWDATA_SUCCESS:
      return {
        ...state,

        rawData: action.survey.data,
      };

    case surveyConstants.GETALERTDATA_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETALERTDATA_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETALERTDATA_SUCCESS:
      return {
        ...state,

        alertData: action.survey.data,
      };

    case surveyConstants.CATEGORYINSIGHTS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.CATEGORYINSIGHTS_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.CATEGORYINSIGHTS_SUCCESS:
      return {
        ...state,

        categoryInsights: action.survey.data,
      };

    case surveyConstants.AIRECOMMENDATIONS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.AIRECOMMENDATIONS_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.AIRECOMMENDATIONS_SUCCESS:
      return {
        ...state,

        aiRecommendationData: action.survey.recommended,
      };

    case surveyConstants.AIRECOMMENDATIONSPOST_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.AIRECOMMENDATIONSPOST_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.AIRECOMMENDATIONSPOST_SUCCESS:
      return {
        ...state,

        aiRecommendationData: action.survey.data,
      };

    case surveyConstants.CATEGORYINSIGHTSBYQUESTION_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.CATEGORYINSIGHTSBYQUESTION_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.CATEGORYINSIGHTSBYQUESTION_SUCCESS:
      return {
        ...state,

        categoryInsightsByQuestion: action.survey.data,
      };
    case surveyConstants.GETLONGTEXTAUDIENCE_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETLONGTEXTAUDIENCE_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETLONGTEXTAUDIENCE_SUCCESS:
      return {
        ...state,

        longTextAudienceSummary: action.survey.data,
      };
    case surveyConstants.GETLONGSURVEY_SUCCESS:
      return {
        ...state,
        longSurveyItems: action.survey.data,
      };
    case surveyConstants.GETLONGSURVEY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETQUESTIONANALYSIS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETQUESTIONANALYSIS_SUCCESS:
      return {
        ...state,
        questionAnalysisData: action.survey.data,
      };

    case surveyConstants.GETLEXICALQUESTIONANALYSIS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETLEXICALQUESTIONANALYSIS_SUCCESS:
      return {
        ...state,
        lexicalQuestionAnalysisData: action.survey.data,
      };
    case surveyConstants.GETLONGQUESTION_ID:
      return {
        ...state,
        longQuestionId: action.questionId,
      };
    case surveyConstants.GETOTHERQUESTION_ID:
      return {
        ...state,
        otherQuestionId: action.questionId,
      };

    case surveyConstants.GETLEXICALQUESTION_ID:
      return {
        ...state,
        lexicalQuestionId: action.questionId,
      };
    case surveyConstants.GETQUESTIONANALYSIS_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETLEXICALQUESTIONANALYSIS_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case surveyConstants.GETQUESTIONALERTS_FAILURE:
      return {
        ...state,

        error: action.error,
      };

    case surveyConstants.GETQUESTIONALERTS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case surveyConstants.GETQUESTIONALERTS_SUCCESS:
      return {
        ...state,
        alertQuestionsData: action.survey.data,
      };
    case surveyConstants.GETSURVEY_RESET:
      return {
        surveyType: "month",
        longSurveyType: "month",
        questionAnalysisData: null,
        surveyYear: new Date().getFullYear(),
        surveyMonth: "all",
        answerData: null,
        loading: false,
        longSurveyYear: new Date().getFullYear(),
        longSurveyMonth: "all",
        surveyEffectivenessYear: new Date().getFullYear(),
        surveyEffectivenessMonth: "all",
        otherQuestionId: null,
        longQuestionId: null,
        longSurveyItems: null,
        longTextSummary: null,
        longTextAudienceSummary: null,
        rawDataQuestionId: null,
        rawDataProfanity: "all",
        rawDataSentiments: "all",
        rawDataPageFilter: 1,
        rawDataSorting: "",
        rawDataSearching: "",
        prompt: false,
        categoryInsights: null,
        aiRecommendationData: null,
        categoryInsightsByQuestion: null,
        aiFilter: "all",
        audienceAiFilter: "all",
        audienceStateFilter: "all",
        surveyEffectivenessChannel: "email_link",
        surveyEffectivenessCountry: "all",
        surveyEffectivenessData: null,
        surveyEffectivenessSocialGroup: "all",

        surveyEffectivenessAbandonedChannel: "email_link",
        surveyEffectivenessAbandonedCountry: "all",
        surveyEffectivenessAbandonedData: null,
        surveyEffectivenessAbandonedSocialGroup: "all",
        profanityFilter: "all",
        shortAnswerFilter: "all",
        updateCustomCategory: null,
        getCustomCategory: null,
        activeMenu: null,
        sentimentFilter: [],
        allFilter: true,
        categories: null,
        addCategories: null,
        updateCategories: null,
        updateComboCategories: null,
        categorizationQuestionId: null,
        aiRecommendationStatus: null,
        processingStatus: false,
        aiRecommendationQuestionId: null,
        rawData: null,
        items: {},
        shareAlert: null,
        alertQuestionId: null,
        alertProfanity: "all",
        alertSentiment: "all",
        alertPageFilter: 1,
        alertSorting: "",
        alertSearching: "",
        alertStatus: "current",
        alertQuestionsData: null,
        alertData: null,
        lexicalQuestionAnalysisData: null,
        lexicalSurveyYear: new Date().getFullYear(),
        lexicalSurveyMonth: "all",
        items: {},
        lexicalItems: {},

        surveyLexicalEffectivenessAbandonedChannel: "email_link",
        surveyLexicalEffectivenessAbandonedCountry: "all",
        surveyLexicalEffectivenessAbandonedData: null,
        surveyLexicalEffectivenessAbandonedSocialGroup: "all",
        lexicalAudienceStateFilter: "all",
        lexicalQuestionId: null,
      };
    default:
      return state;
  }
}
