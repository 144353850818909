export const audienceConstants = {
  GETAUDIENCE_REQUEST: "AUDIENCE_GETAUDIENCE_REQUEST",
  GETAUDIENCE_SUCCESS: "AUDIENCE_GETAUDIENCE_SUCCESS",
  GETAUDIENCE_FAILURE: "AUDIENCE_GETAUDIENCE_FAILURE",

  GETLEXICALAUDIENCE_REQUEST: "LEXICALAUDIENCE_GETLEXICALAUDIENCE_REQUEST",
  GETLEXICALAUDIENCE_SUCCESS: "LEXICALAUDIENCE_GETLEXICALAUDIENCE_SUCCESS",
  GETLEXICALAUDIENCE_FAILURE: "LEXICALAUDIENCE_GETLEXICALAUDIENCE_FAILURE",

  GETLEXICALAUDIENCE_TYPE: "AUDIENCE_GETLEXICALAUDIENCE_TYPE",

  GETAUDIENCE_TYPE: "AUDIENCE_GETAUDIENCE_TYPE",
  GETAUDIENCE_RESET: "AUDIENCE_GETAUDIENCE_RESET",

  GETQUESTION_ID: "AUDIENCE_GETQUESTION_ID",
  GETSURVEYSTATUS: "AUDIENCE_GETSURVEYSTATUS",

  GETLEXICALSURVEYSTATUS: "AUDIENCE_GETLEXICALSURVEYSTATUS",
};
