import React, { useState, useEffect } from "react";
import {
  useMsal,
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";
import {
  InteractionType,
  PublicClientApplication,
  InteractionRequiredAuthError,
  InteractionStatus,
  UserAgentApplication,
} from "@azure/msal-browser";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { setLocalStorage, getLocalStorage } from "../../voc/Common/Utils";

import "./socialLogin.css";

const MS_CLIENT_ID = "4fc66ea1-17c7-4331-967c-d0e771c4a2a5",
  MS_REDIRECT_URI = "https://voc.articence.com/",
  MS_SCOPES = ["mail.send", "Contacts.Read", "offline_access"],
  LOGIN_REQUEST = {
    scopes: MS_SCOPES,
  };

const pca = new PublicClientApplication({
  auth: {
    clientId: MS_CLIENT_ID,
    redirectUri: MS_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

const Outlook = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false),
    [outlookAccessToken, setAccessToken] = useState();

  const signInClickHandler = () => {
    try {
      pca
        .loginPopup(LOGIN_REQUEST)
        .then(function (loginResponse = {}) {
          const { accessToken, account = {} } = loginResponse,
            { tenantId, username, name } = account;
          const obj = {
            name,
            email: username,
            accountId: tenantId,
            type: "outlook",
          };
          setIsLoggedIn(true);
          setAccessToken(accessToken);
          setLocalStorage("auth", { ...obj, account, accessToken });
          props.onSuccess(obj);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log("USER CANCELLED LOGIN FLOW");
      window.location.reload();
    }
  };

  const SignInButton = () => {
    return <MicrosoftLoginButton onClick={signInClickHandler} />;
  };

  return props.showOnlyIcon ? (
    <span
      className="msalIconCircle"
      onClick={signInClickHandler}
      style={props.style || {}}
    >
      <img src="images/msLogo.png" className="msalIcon" />
    </span>
  ) : (
    <SignInButton />
  );
};

const getAccessToken = (cb) => {
  const { account = {} } = getLocalStorage("auth") || {};
  const req = { ...LOGIN_REQUEST, account };
  try {
    pca
      .acquireTokenSilent(req)
      .then((res) => {
        cb(res);
      })
      .catch((err) => {
        if (isInteractionRequired(err)) {
          pca.acquireTokenPopup(req).then((res) => {
            cb(res);
          });
        } else {
          cb(null, err);
        }
      });
  } catch (err) {
    cb(null, err);
  }
};

const isInteractionRequired = (err) => {
  if (!err.message || err.message.length <= 0) {
    return false;
  }

  return (
    err.message.indexOf("consent_required") > -1 ||
    err.message.indexOf("interaction_required") > -1 ||
    err.message.indexOf("login_required") > -1 ||
    err.message.indexOf("no_account_in_silent_request") > -1
  );
};

export default Outlook;
export { getAccessToken };
