import React, { useState, useEffect } from "react";
import Input from "../../common/controls/inputs/Input";
import { Link } from "react-router-dom";
import { INPUT_ERRORS } from "../Common/Constants";
import { checkPassword } from "../Common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions";
import { WrapperComp } from "../Common/CommonWrapper";
import { history } from "../../helpers";
import "./../teamRegister/teamRegisterStyle.scss";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

const RightForm = ({
  onSubmitHandle,
  details,
  setDetails,
  isError,
  notMatch,
}) => {
  const [invalidPwd, setValidation] = useState(false);
  const [submitting, setSubmit] = useState(false);
  const [doNotMatched, setMatched] = useState(notMatch);

  useEffect(()=>{
      setMatched(notMatch);
  },[notMatch]);

    useEffect(()=>{
        if(submitting){
            onSubmit();
        }
        setSubmit(false);
    },[submitting]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setDetails((details) => ({ ...details, [name]: value }));
  };

  const onBlur = (e) => {
      const { name, value } = e.target;

    if(name === "confirmPassword"){
        setMatched(details.password !== value);
        return;
    }
    if (details.password !== "") {
      const validPwd = checkPassword(details.password);
      setValidation(!validPwd);
    } else {
      setValidation(false);
    }
  };

  const onSubmit = (e) => {
      if(invalidPwd || doNotMatched){
          return;
      }
      onSubmitHandle(e)
  };

  const onKeyPress = (e) => {
      if (e && e.key === "Enter"){
          onBlur(e);
          setSubmit(true);
      }
  };

  return (
    <div className={"rightRegister"}>
      <div className={"registerHeader"}>
        <div className="logoRegister">
          {" "}
          <img src="/images/logo.png" />
        </div>
        <h1>Forgot Password?</h1>
      </div>
      <div className="signUplink">
        <p>Don’t have an account? </p>
        <button
          className="btn btnDefault"
          onClick={() => {
            history.push("/signup");
          }}
        >
          Sign up
        </button>
      </div>
      <div className="formWrap">
        <div className="row">
          {/* <div className="col-md-6">
            <Input
              config={{
                label: "Token",
                name: "token",
                type: "text",
                className: "registerField",
                labelClass: "registerFieldLabel",
                placeholder: "",
              }}
              value={details.token}
              onChange={onChange}
              isError={isError && details.token == ""}
              error={INPUT_ERRORS.required}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            <Input
              config={{
                label: "New Password",
                name: "password",
                type: "password",
                className: "registerField",
                labelClass: "registerFieldLabel",
                placeholder: "New Password",
                required:true,
              }}
              value={details.password}
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress = {onKeyPress}
              isError={(isError && details.password == "") || invalidPwd}
              error={INPUT_ERRORS[invalidPwd ? "invalidPassword" : "required"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Input
              config={{
                label: "Confirm Password",
                name: "confirmPassword",
                type: "password",
                className: "registerField",
                labelClass: "registerFieldLabel",
                placeholder: "Confirm Password",
                required:true,
              }}
              value={details.confirmPassword}
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress = {onKeyPress}
              isError={(isError && details.confirmPassword == "") || doNotMatched}
              error={INPUT_ERRORS[doNotMatched ? "unmatchPwd" : "required"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button className="btn btnDefault" onClick={onSubmit}>
              Change the Password
            </button>
            <div className="link__container">
                <a onClick={()=>history.push("/login")} className="blue__link">Back to Sign In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SuccessfullyRegister = () => {
  const query = new URLSearchParams(window.location.search);

  return (
    <div className="rightRegister finshiedWrapper">
      <div
        className="registerFinishWrap"
        style={{ backgroundImage: "url(images/SVG/submitForm-icon.svg)" }}
      >
        <h1>Password Change Successfully</h1>
        <h4>
            Please check out the latest  capabilities to make your customer
            journey successful!!!
        </h4>
        {/*<h5>if you did not receive the email,</h5>
        <Link to={"/"}>Resend another email</Link>*/}
          <button className="btn btnDefault" onClick={()=>{history.push("/login")}} style={{marginTop:20}}>
              Sign in
          </button>
      </div>
    </div>
  );
};

const TokenVerify = (props) => {
  const [successfullyRegister, setSuccessfullyRegister] = React.useState(false);
  const [details, setDetails] = useState({
    email: "",
    token: "",
    password: "",
    confirmPassword: "",
  });
  const [isErr, showErr] = useState(false);
  const [notMatch, setNotMatchedTxt] = useState();
  const dispatch = useDispatch();
  const { resetPasswordLoading = false, resetPasswordSuccess = false } =
    useSelector((state) => state.users);

  useEffect(() => {
    resetPasswordSuccess && setSuccessfullyRegister(true);
  }, [resetPasswordSuccess]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    // if (props.location.search.includes("email")) {
    //   let param = props.location.search.split("?email=");
    //   setDetails((details) => ({ ...details, ["email"]: param[1] }));
    // }
    setDetails({
      ...details,
      email: query.get("email"),
      token: query.get("token"),
    });
  }, [props.location.search]);

  const onSubmitHandle = () => {
    if (
      details.email !== "" &&
      details.token !== "" &&
      details.password !== "" &&
      details.confirmPassword !== ""
    ) {
      if (details.password === details.confirmPassword) {
        dispatch(
          userActions.reset(
            details.email,
            details.token,
            details.confirmPassword
          )
        );
      } else {
        setNotMatchedTxt(true);
      }
    } else {
      showErr(true);
    }
  };
  return (
    <WrapperComp isLoader={resetPasswordLoading}>
      <div className={"teamRegister forgetPassword tokenVerify"}>
        <div className="leftRegister">
          {/* <img src="/images/Untitled-2.gif" /> */}
          <div className="bannerWrapper">
            <div className="bannerWrapper--left">
                <h1>Start your free trial today</h1>
                {/* <h2>No credit card required</h2> */}
                <p>The trial includes:</p>
                <ul>
                <li>
                    <DoneRoundedIcon />
                    Customized data based on your information
                </li>
                <li>
                    <DoneRoundedIcon />
                    Pre-configured dashboards and reports
                </li>
                <li>
                    <DoneRoundedIcon />
                    Customized templates and question banks
                </li>
                <li>
                    <DoneRoundedIcon />
                    Easy help and reference
                </li>
                <li>
                    <DoneRoundedIcon />
                    AI Analysis
                </li>
                </ul>
            </div>
            
            <div className="bannerWrapper--right">
                <img src="images/mainBannerFull.png" />
            </div>
            
          </div>
        </div>
        {!successfullyRegister ? (
          <RightForm
            onSubmitHandle={onSubmitHandle}
            details={details}
            setDetails={setDetails}
            isError={isErr}
            notMatch={notMatch}
          />
        ) : (
          <SuccessfullyRegister />
        )}
      </div>
    </WrapperComp>
  );
};

export default TokenVerify;
