import React from 'react';
import BackdropLoader from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const Backdrop = ({show = false}) => {
    return <BackdropLoader sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
        <CircularProgress color="inherit" />
    </BackdropLoader>
};

export default Backdrop;