import api from "./api";

async function addAgents(addAgentsData, Id) {
  const result = await api.post(`/createaiagent`, addAgentsData);
  return result;
}

async function editAgents(addAgentsData, agentId) {
  const result = await api.put(`/editaiagent/${agentId}`, addAgentsData);
  return result;
}

async function deleteAgents(agentId) {
  const result = await api.delete(`/deleteaiagent/${agentId}`);
  return result;
}


async function getAgents() {
  const result = await api.get(`/getaiagent`);
  return result;
}

async function getAgentById(agentId) {
  const result = await api.get(`/particularaiagent/${agentId}`);
  return result;
}

async function getExtension() {
  const result = await api.get(`/getUserExtension`);
  return result;
}

export const AgentsService = {
  addAgents,
  getAgents,
  getAgentById,
  getExtension,
  editAgents,
  deleteAgents
}