import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Box,
  Paper,
  Fade,
  Zoom,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
} from "@mui/material";
import {
  Close,
  Call,
  Email,
  Feedback,
  AccountBalanceWallet,
  Dashboard,
} from "@mui/icons-material";
import CustomerDashboardContainer from "./CustomerDashboard";
import { User2Icon } from "lucide-react";

const CallDetailsPopup = ({ open, onClose, customerName, customerId }) => {
  const [selectedTab, setSelectedTab] = useState("dashboard");

  // Mock data for engagements
  const engagementData = {
    calls: [
      {
        text: "Call with support team",
        date: "2023-10-12",
        duration: "15 mins",
        sentiment: "Positive",
      },
      {
        text: "Follow-up call",
        date: "2023-10-20",
        duration: "10 mins",
        sentiment: "Neutral",
      },
    ],
    emails: [
      {
        text: "Order confirmation",
        date: "2023-09-15",
        sentiment: "Positive",
        responseTime: "2 hours",
      },
      {
        text: "Product inquiry response",
        date: "2023-09-18",
        sentiment: "Positive",
        responseTime: "1 day",
      },
    ],
    feedbacks: [
      {
        text: "Survey feedback",
        date: "2023-08-25",
        sentiment: "Positive",
        comments: "Great service!",
      },
      {
        text: "Form submission",
        date: "2023-09-10",
        sentiment: "Neutral",
        comments: "Average experience.",
      },
    ],
  };

  const paymentHistory = [
    {
      text: "Payment for Invoice #1234",
      date: "2023-08-01",
      amount: "$100",
      status: "Completed",
    },
    {
      text: "Payment for Invoice #5678",
      date: "2023-09-01",
      amount: "$150",
      status: "Completed",
    },
  ];

  const menuItems = [
    { label: "Dashboard", icon: <Dashboard />, key: "dashboard" },
    { label: "Calls", icon: <Call />, key: "calls" },
    { label: "Emails", icon: <Email />, key: "emails" },
    { label: "Feedback", icon: <Feedback />, key: "feedbacks" },
    { label: "Payments", icon: <AccountBalanceWallet />, key: "payments" },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Zoom}
      transitionDuration={300}
      PaperProps={{
        sx: {
          borderRadius: 4,
          overflow: "hidden",
          boxShadow: 8,
          height: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "#d1ecff",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          zIndex: 1200,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <User2Icon color="black" />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Customer Engagement Details
          </Typography>
        </Box>
        <IconButton edge="end" onClick={onClose} sx={{ color: "black" }}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: 0,
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            minWidth: "240px",
            bgcolor: "#f5f5f5",
            borderRight: "1px solid #e0e0e0",
            overflow: "auto",
            position: "sticky",
            top: 0,
            height: "100%",
            zIndex: 1100,
          }}
        >
          <List sx={{ p: 2 }}>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.key}
                selected={selectedTab === item.key}
                onClick={() => setSelectedTab(item.key)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  bgcolor: selectedTab === item.key ? "#d1ecff" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: selectedTab === item.key ? "#d1ecff" : "#e3e3e3",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 1,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.secondary",
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: selectedTab === item.key ? 600 : 500,
                    color:
                      selectedTab === item.key ? "#1976d2" : "text.primary",
                  }}
                >
                  {item.label}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto", // Enable scroll for content
            height: "100%",
          }}
        >
          <Fade in={open} timeout={500}>
            <Box>
              {selectedTab === "dashboard" && <CustomerDashboardContainer />}
              {selectedTab === "calls" && (
                <ContentSection title="Call History">
                  <StyledTable rows={engagementData.calls} />
                </ContentSection>
              )}
              {selectedTab === "emails" && (
                <ContentSection title="Email History">
                  <StyledTable rows={engagementData.emails} />
                </ContentSection>
              )}
              {selectedTab === "feedbacks" && (
                <ContentSection title="Feedback Responses">
                  <StyledTable rows={engagementData.feedbacks} />
                </ContentSection>
              )}
              {selectedTab === "payments" && (
                <ContentSection title="Payment History">
                  <StyledTable rows={paymentHistory} />
                </ContentSection>
              )}
            </Box>
          </Fade>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// ContentSection and StyledTable components remain the same
const ContentSection = ({ title, children }) => (
  <Box>
    <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
      {title}
    </Typography>
    <Divider sx={{ mb: 2 }} />
    {children}
  </Box>
);

const StyledTable = ({ rows = [] }) => {
  const columns = rows.length > 0 ? Object.keys(rows[0]) : [];

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell
                key={col}
                sx={{ fontWeight: "bold", borderBottom: "1px solid #ddd" }}
              >
                {col.charAt(0).toUpperCase() + col.slice(1)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align="center"
                sx={{ color: "text.secondary" }}
              >
                No data found
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, index) => (
              <TableRow key={index}>
                {columns.map((col) => (
                  <TableCell key={col} sx={{ borderBottom: "1px solid #eee" }}>
                    {row[col] || "N/A"}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CallDetailsPopup;
