import { userConstants, teamConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";
import { swalAlert } from "../voc/Common/SwalAlert";

const login = (username, password, from, socialProfile) => {
  const request = (user) => ({ type: userConstants.LOGIN_REQUEST, user });
  const success = (user) => ({ type: userConstants.LOGIN_SUCCESS, user });
  const failure = (error) => ({ type: userConstants.LOGIN_FAILURE, error });
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password, socialProfile).then(
      (user) => {
        //swalAlert({icon: 'success', title: 'Login Successfully', showConfirmButton: true });
        if (user.isProfileCreated) {
          dispatch(success(user));
          // const redirectUrl = user.default_camp_id ? '/survey/'+user.default_camp_id : from;
          history.push("/");
        } else {
          history.push("/questions");
        }
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message.toString()));
        dispatch(alertActions.error(error?.response?.data?.message.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const logout = () => {
  userService.logout();
  return { type: userConstants.LOGOUT };
};

const register = (user) => {
  const request = (user) => ({ type: userConstants.REGISTER_REQUEST, user });
  const success = (user) => ({ type: userConstants.REGISTER_SUCCESS, user });
  const failure = (error) => ({ type: userConstants.REGISTER_FAILURE, error });

  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/questions");
        // swalAlert({
        //   icon: "success",
        //   title: "Profile Saved Successfully",
        //   showConfirmButton: true,
        // });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateUser = (
  industry,
  job_role,
  company_name,
  company_size,
  company_website,
  signup_address
) => {
  const request = (user) => ({ type: userConstants.USER_UPDATE_REQUEST, user });
  const success = (user) => ({ type: userConstants.USER_UPDATE_SUCCESS, user });
  const failure = (error) => ({
    type: userConstants.USER_UPDATE_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request({}));
    userService
      .updateUser(
        industry,
        job_role,
        company_name,
        company_size,
        company_website,
        signup_address
      )
      .then(
        (user) => {
          dispatch(success(user));
          swalAlert({
            icon: "success",
            title: "Profile saved successfully",
            showConfirmButton: true,
          }).then(() => {
            history.push("/");
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          dispatch(
            alertActions.error(error?.response?.data?.message?.toString())
          );
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
  };
};

const getAll = () => {
  const request = () => ({ type: userConstants.GETALL_REQUEST });
  const success = (users) => ({ type: userConstants.GETALL_SUCCESS, users });
  const failure = (error) => ({ type: userConstants.GETALL_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        logout();
      }
    );
  };
};

const getTeam = (team_id) => {
  const request = (team_id) => ({
    type: teamConstants.GET_TEAM_REQUEST,
    team_id,
  });
  const success = (team) => ({ type: teamConstants.GET_TEAM_SUCCESS, team });
  const failure = (error) => ({ type: teamConstants.GET_TEAM_FAILURE, error });

  return (dispatch) => {
    dispatch(request(team_id));

    userService.getTeam(team_id).then(
      (team_name) => {
        dispatch(success(team_name));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const _delete = (id) => {
  const request = (id) => ({ type: userConstants.DELETE_REQUEST, id });
  const success = (id) => ({ type: userConstants.DELETE_SUCCESS, id });
  const failure = (id, error) => ({
    type: userConstants.DELETE_FAILURE,
    id,
    error,
  });

  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => {
        dispatch(failure(id, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const check = () => {
  const request = () => ({ type: userConstants.CHECK_REQUEST });
  const success = (users) => ({ type: userConstants.CHECK_SUCCESS, users });
  const failure = (error) => ({ type: userConstants.CHECK_FAILURE, error });

  return (dispatch) => {
    dispatch(request());

    userService.checkToken().then(
      (users) => {
        // console.log("check login errrusersusersusersrrrrrrrrrr : ", users);
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/login");
      }
    );
  };
};

const getOrg = (team_id) => {
  const request = (team_id) => ({
    type: userConstants.GET_ORG_REQUEST,
    team_id,
  });
  const success = (organization) => ({
    type: userConstants.GET_ORG_SUCCESS,
    organization,
  });
  const failure = (error) => ({ type: userConstants.GET_ORG_FAILURE, error });

  return (dispatch) => {
    dispatch(request(team_id));

    userService.getOrg(team_id).then(
      (organization) => {
        dispatch(success(organization));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const sendSurvey = (emails, sender) => {
  const request = (emails, sender) => ({
    type: teamConstants.SEND_TO_TEAM_REQUEST,
    emails,
  });
  const success = (emails, sender) => ({
    type: teamConstants.SEND_TO_TEAM_SUCCESS,
    emails,
  });
  const failure = (emails, sender, error) => ({
    type: teamConstants.SEND_TO_TEAM_FAILURE,
    emails,
    error,
  });

  return (dispatch) => {
    dispatch(request(emails, sender));

    userService.sendEmails(emails, sender).then(
      (user) => {
        dispatch(success(emails, sender));
      },
      (error) => {
        dispatch(
          failure(emails, sender, error?.response?.data?.message?.toString())
        );
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const forgot = (email) => {
  const request = (email) => ({ type: userConstants.FORGOT_REQUEST, email });
  const success = (email) => ({ type: userConstants.FORGOT_SUCCESS, email });
  const failure = (email, error) => ({
    type: userConstants.FORGOT_FAILURE,
    email,
    error,
  });

  return (dispatch) => {
    dispatch(request(email));

    userService.forgotPassword(email).then(
      (user) => {
        dispatch(success(email));
      },
      (error) => {
        dispatch(failure(email, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const teamRegister = (team) => {
  const request = (team) => ({
    type: userConstants.TEAM_REGISTER_REQUEST,
    team,
  });
  const success = (team) => ({
    type: userConstants.TEAM_REGISTER_SUCCESS,
    team,
  });
  const failure = (error) => ({
    type: userConstants.TEAM_REGISTER_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(team));

    userService.teamRegister(team).then(
      (team) => {
        dispatch(success(team));
        dispatch(alertActions.success("Team registration successful"));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const teamUpdate = (team) => {
  const request = (team) => ({ type: userConstants.TEAM_UPDATE_REQUEST, team });
  const success = (team) => ({ type: userConstants.TEAM_UPDATE_SUCCESS, team });
  const failure = (error) => ({
    type: userConstants.TEAM_UPDATE_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(team));

    userService.updateTeam(team).then(
      (team) => {
        dispatch(success(team));
        dispatch(alertActions.success("Team update successful"));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const reset = (email, token, newPassword) => {
  const request = (email) => ({ type: userConstants.RESET_REQUEST, email });
  const success = (email) => ({ type: userConstants.RESET_SUCCESS, email });
  const failure = (error) => ({ type: userConstants.RESET_FAILURE, email });

  return (dispatch) => {
    dispatch(request(email));

    userService.reset(email, token, newPassword).then(
      (team) => {
        dispatch(success(email));
        dispatch(alertActions.success("Reset was successful"));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createContactUs = (result) => {
  const request = (result) => ({
    type: teamConstants.CREATE_CONTACT_US_REQUEST,
    result,
  });
  const success = (result) => ({
    type: teamConstants.CREATE_CONTACT_US_SUCCESS,
    result,
  });
  const failure = (result) => ({
    type: teamConstants.CREATE_CONTACT_US_FAILURE,
    result,
  });

  return (dispatch) => {
    dispatch(request(result));

    userService.createContactUs(result).then(
      (user) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure(result, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

//
// const reset = ({ password, token }) => {
//   const config = {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   };
//   const body = JSON.stringify({ password, token });
//   try {
//     const res = await axios.put(
//       `http://localhost:3000/api/auth/reset/${token}`,
//       body,
//       config
//     );
//     dispatch({
//       type: RESET_PASSWORD,
//       payload: res.data
//     });
//   } catch (err) {
//     const errors = err.response.data.errors;
//     if (errors) {
//       errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//     }
//   }
// };

const verify = (email, from, link) => {
  const request = (user) => ({ type: userConstants.USER_VERIFY_REQUEST, user });
  const success = (user) => ({ type: userConstants.USER_VERIFY_SUCCESS, user });
  const failure = (error) => ({
    type: userConstants.USER_VERIFY_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request({ email }));

    userService.verify(email, link).then(
      (user) => {
        localStorage.setItem("VocSentEmail", email);
        dispatch(success(user));
        swalAlert({
          icon: "success",
          title: "Link has been sent on given email.",
          showConfirmButton: true,
        });
        history.push(from);
      },
      (error) => {
        dispatch(failure(error.response.data.message));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const resendActivationEmail = (email, from, link) => {
  const request = (user) => ({
    type: userConstants.RESEND_EMAIL_REQUEST,
    user,
  });
  const success = (user) => ({
    type: userConstants.RESEND_EMAIL_SUCCESS,
    user,
  });
  const failure = (error) => ({
    type: userConstants.RESEND_EMAIL_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request({ email }));

    userService.resendActivationEmail(email, link).then(
      (user) => {
        localStorage.setItem("VocSentEmail", email);
        dispatch(success(user));
        swalAlert({
          icon: "success",
          title: "Link has been resent on given email.",
          showConfirmButton: true,
        });
        history.push(from);
      },
      (error) => {
        dispatch(failure(error.response.data.message));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getProfilePic = () => {
  const request = (data) => ({ type: userConstants.PROFILE_PIC_REQUEST, data });
  const success = (data) => ({ type: userConstants.PROFILE_PIC_SUCCESS, data });
  const failure = (error) => ({
    type: userConstants.PROFILE_PIC_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    userService.getProfilePic().then(
      (res) => {
        dispatch(success(res?.data));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateProfilePic = (data) => {
  return (dispatch) => {
    return userService.updateProfilePic(data).then(
      (res) => {
        console.log("res--update-----> ", res);
        dispatch(getProfilePic());
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        return error;
      }
    );
  };
};

export const userActions = {
  login,
  logout,
  reset,
  register,
  getAll,
  getTeam,
  delete: _delete,
  check,
  forgot,
  teamRegister,
  teamUpdate,
  getOrg,
  sendSurvey,
  createContactUs,
  updateUser,
  verify,
  resendActivationEmail,
  updateProfilePic,
  getProfilePic,
};
