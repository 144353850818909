import { commonService } from "./CommonService";
import api from "./api";

const logout = commonService.logout;

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  forgotPassword,
  checkToken,
  teamRegister,
  updateTeam,
  getTeam,
  sendEmails,
  getOrg,
  reset,
  createContactUs,
  updateUser,
  verify,
  resendActivationEmail,
  getProfilePic,
  updateProfilePic,
};

async function login(username, password, profile) {
  const isSocialLogin = profile ? true : false,
    socialProfile = profile || {};
  const user = await api.post(`/login`, {
    username,
    password: password || "",
    isSocialLogin,
    socialProfile,
  });
  const msg = user?.data?.message?.split("Welcome") || [];
  const email = user?.data?.email;
  localStorage.setItem("user", JSON.stringify({ ...user.data, email }));
  return user.data;
}

async function updateUser(
  industry,
  job_role,
  company_name,
  company_size,
  company_website,
  signup_address
) {
  const result = await api.put(`/updateUser`, {
    industry,
    job_role,
    company_name,
    company_size,
    company_website,
    signup_address,
  });

  let user = localStorage.getItem("user");
  user = user && JSON.parse(user);
  user.isProfileCreated = true;
  localStorage.setItem("user", JSON.stringify(user));
  return result.data;
}

async function getAll() {
  const result = await api.get(`/users`);

  return result.data;
}

async function getTeam(team_id) {
  const result = await api.get(`/obtTeam`, {
    params: {
      team_id: team_id,
    },
  });
  return result.data;
}

async function getOrg(team_id) {
  const result = await api.get(`/obtOrg`, {
    params: {
      team_id: team_id,
    },
  });
  return result.data;
}

async function checkToken() {
  const response = await api.get(`/checkLogin`);

  if (response.data.status === 401) {
    return Promise.reject();
  }
  return response.data;
}

async function getById(id) {
  const result = await api.get(`/users/${id}`);
  return result.data;
}

async function register(user) {
  const result = await api.post(`/registration`, user);
  localStorage.setItem("user", JSON.stringify(result.data));

  return result.data;
}

async function update(user) {
  const result = await api.put(`/users/${user.id}`, user);

  return result.data;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
  const result = await api.delete(`/users/${id}`);

  return result.data;
}

async function forgotPassword(email) {
  const result = await api.post(`/forgotPassword`, email);

  return result.data;
}

async function sendEmails(emails, sender) {
  const result = await api.post(`/sendEmails`, {
    emails,
    sender,
  });

  return result.data;
}

async function teamRegister(team) {
  const result = await api.post(`/teamRegister`, team);

  return result.data;
}

async function updateTeam(team) {
  const result = await api.put(`/updateTeam`, team);

  return result.data;
}

async function reset(email, token, newPassword) {
  const result = await api.post(`/resetPassword`, {
    params: {
      email: email,
      token: token,
      newPassword: newPassword,
    },
  });

  return result.data;
}

async function createContactUs(contact_us) {
  const result = await api.post(`/createContactUs`, contact_us);

  return result.data;
}

async function verify(email, link) {
  const result = await api.post(`/userVerify`, {
    email,
    link,
  });

  return result.data;
}

async function resendActivationEmail(email, link) {
  const result = await api.post(`/resendActivationEmail`, {
    email,
    link,
  });

  return result.data;
}

async function getProfilePic(detail_id) {
  const result = await api.get(`/getProfilePicture`, {
    params: {
      details_id: detail_id,
    },
  });
  return result;
}

async function updateProfilePic(src) {
  const result = await api.post(`/updateProfilePicture`, src);
  return result;
}
