import api from "./api";

async function getPageLogic(surveyId) {
  const result = await api.get(`/getUpdatedQuestDesc?survey_id=${surveyId}`);
  return result;
}

async function updatePageLogic(obj) {
  const result = await api.post(`/createQuestDesc`, obj);
  return result;
}

export const pageLogicServices = {
  getPageLogic,
  updatePageLogic,
};
