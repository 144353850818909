import { alertConstants } from "../constants";
import { alertServices } from "../services";
import { swalAlert } from "../voc/Common/SwalAlert";

const request = (message) => ({ type: alertConstants.REQUEST, message });

const success = (message) => ({ type: alertConstants.SUCCESS, message });

const error = (message) => ({ type: alertConstants.ERROR, message });

const clear = () => ({ type: alertConstants.CLEAR });

const getAlerts = () => {
  return (dispatch) => {
    dispatch(request());

    alertServices.getAlerts().then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateAlert = (id) => {
  return (dispatch) => {
    dispatch(request());

    alertServices.updateAlert(id).then(
      (alerts) => {
        dispatch(success(alerts));
        dispatch(getAllAlerts());
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAllAlerts = () => {
  return (dispatch) => {
    dispatch(request());

    alertServices.getAllAlerts().then(
      (alerts) => {
        dispatch(success(alerts));
      },
      (error) => {
        dispatch(error(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const alertActions = {
  getAllAlerts,
  getAlerts,
  updateAlert,
  success,
  error,
  clear,
};
