import React, {useState, useEffect} from 'react';
import Input from "../../common/controls/inputs/Input";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions';
import { INPUT_ERRORS } from '../Common/Constants';
import { WrapperComp } from '../Common/CommonWrapper';
import { checkEmail, checkPhone } from '../Common/Utils';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import './teamRegisterStyle.scss';
import {Link} from "react-router-dom";

const RightForm = ({onSubmitHandle, team, setTeam, error, setError})=>{
    const [invalid, setValidation] = useState({});

    const onBlur = (e) => {
        if(e && team[e.target.name]?.length){
            let isValid = false;
            if(e.target.name === 'contact_email'){
                isValid = checkEmail(team.contact_email);
            }else{
                isValid = checkPhone(team.contact_phone);
            }

            setValidation(invalid => ({ ...invalid, [e.target.name] : !isValid }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTeam(team => ({ ...team, [name]: value }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setTeam(team => ({ ...team, [name]: value.trim() }));
    };

    const checkErr = (field) => {
        return error && !(team[field] && team[field] !== "");
    };

    const onSubmit = (e) => {
        if(!Object.values(invalid).includes(true)){
            onSubmitHandle(e);
        }
    };

    const onKeyPress = (e) => {
        if(e && e.key === "Enter" ){
            let isValid = false;
            if(e && team[e.target.name]?.length){
                if(e.target.name === 'contact_email'){
                    isValid = checkEmail(team.contact_email);
                }else if (e.target.name === 'contact_phone'){
                    isValid = checkPhone(team.contact_phone);
                }
                setValidation(invalid => ({ ...invalid, [e.target.name] : !isValid }));
            }
            onSubmitHandle(e,isValid);
        }

    }

    return(
        <div className={"rightRegister"}>
            <div className={'registerHeader'}>
                <div className="logoRegister"> <img src="images/logo.png"/></div>
                <h1>Register a Team</h1>
                <p>You can always sign up for a team after registering  individual accounts. Send us the details and we will take  care</p>
            </div>
            <div className="formWrap">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <Input config={{ label: 'Team Name', required:true, name: 'team_name', type: 'text', className: 'registerField', labelClass: 'registerFieldLabel', placeholder: 'Team Name' }} value={team.team_name} onChange={handleChange} onBlur={handleBlur} onKeyPress={onKeyPress} isError={checkErr('team_name')} error={INPUT_ERRORS.required}/>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <Input config={{ label: 'Organisation Name', required:true, name: 'organization_name', type: 'text', className: 'registerField', labelClass: 'registerFieldLabel', placeholder: 'Organisation Name' }} value={team.organization_name} onChange={handleChange} onBlur={handleBlur} onKeyPress={onKeyPress} isError={checkErr('organization_name')} error={INPUT_ERRORS.required}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input config={{ label: 'Contact Email', required:true, name: 'contact_email', type: 'email', className: 'registerField', labelClass: 'registerFieldLabel', placeholder: 'Email' }} value={team.contact_email} onChange={handleChange} onBlur={onBlur} onKeyPress={onKeyPress}  isError={checkErr('contact_email') || invalid.contact_email} error={INPUT_ERRORS[invalid.contact_email ? 'invalidEmail':'required']}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <Input config={{ label: 'Contact Person Name', required:true, name: 'contact_person', type: 'text', className: 'registerField', labelClass: 'registerFieldLabel', placeholder: '' }} value={team.contact_person} onChange={handleChange} onBlur={handleBlur} onKeyPress={onKeyPress} isError={checkErr('contact_person')} error={INPUT_ERRORS.required}/>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <Input config={{ label: 'Phone Number', required:true, name: 'contact_phone', type: 'text', className: 'registerField', labelClass: 'registerFieldLabel', placeholder: '' }} value={team.contact_phone} onChange={handleChange} onBlur={onBlur} onKeyPress={onKeyPress} isError={checkErr('contact_phone')|| invalid.contact_phone} error={INPUT_ERRORS[invalid.contact_phone ? 'invalidPhone' : 'required']}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btnDefault" onClick={onSubmit} style={{width:'100%',height:'40px'}}>Register Team</button>
                        <p>Don’t have an account? <Link to="/signup">Sign up for free!</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
const SuccessfullyRegister = ({email, onSubmitHandle})=>{
    return(
        <div className="rightRegister finshiedWrapper">
            <div className="registerFinishWrap" style={{backgroundImage:"url(images/SVG/submitForm-icon.svg)"}}>
                <h1>Register Team Request Received!</h1>
                <h4>We've sent an email to {email},
                    Please check to get the details.</h4>
                <h5>if you did not receive the email,</h5>
                <a onClick={onSubmitHandle}>Resend another email</a>
            </div>
        </div>
    )
}

const TeamRegister = ()=>{
    const [successfullyRegister, setSuccessfullyRegister] = React.useState(false);
    const [error, setError] = React.useState(false);
    const { teamRegistering = false, teamRegSuccess=false} = useSelector(state => state.users);
    const [team, setTeam] = useState({
        team_name: '',
        organization_name: '',
        contact_email: '',
        contact_person: '',
        contact_phone: ''
    });
    const dispatch = useDispatch();

    useEffect(()=>{
        teamRegSuccess && setSuccessfullyRegister(true);
    },[teamRegistering, teamRegSuccess]);


    function handleSubmit(e,is_Valid=true) {
        e.preventDefault();

        if (team.team_name && team.organization_name && team.contact_email && team.contact_person && team.contact_phone && is_Valid) {
            dispatch(userActions.teamRegister(team));
        } else {
            setError(true)
        }
    }

    return <WrapperComp isLoader={teamRegistering}>
        <div className={"teamRegister"}>
            <div className="leftRegister">
                <div className="bannerWrapper">
                    <div className="bannerWrapper--left">
                        <h1>Start your free trial today</h1>
                        {/* <h2>No credit card required</h2> */}
                        <p>The trial includes:</p>
                        <ul>
                        <li>
                            <DoneRoundedIcon />
                            Customized data based on your information
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Pre-configured dashboards and reports
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Customized templates and question banks
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            Easy help and reference
                        </li>
                        <li>
                            <DoneRoundedIcon />
                            AI Analysis
                        </li>
                        </ul>
                    </div>
                    
                    <div className="bannerWrapper--right">
                        <img src="images/mainBannerFull.png" />
                    </div>
                    
                </div>
                {/* <img src="images/Untitled-2.gif"/> */}
            </div>
            {!successfullyRegister ?<RightForm onSubmitHandle={handleSubmit} team={team} setTeam={setTeam} error={error} />:<SuccessfullyRegister email={team.contact_email} onSubmitHandle={handleSubmit}/>}
        </div>
    </WrapperComp>
}


export default TeamRegister;
