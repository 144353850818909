import { userConstants } from '../constants';

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                items: action.users.data.users
            };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter(user => user.id !== action.id)
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        case userConstants.USER_VERIFY_REQUEST:
            return {
                verifying: true
            };
        case userConstants.USER_VERIFY_SUCCESS:
            return {
                verifying: false,
                message:action?.user?.message
            };
        case userConstants.USER_VERIFY_FAILURE:
            return {
                verifying: false,
                error:action
            };
        case userConstants.USER_UPDATE_REQUEST:
            return  {
                updating: true
            };
        case userConstants.USER_UPDATE_SUCCESS:
            return  {
                updating: false
            };
        case userConstants.USER_UPDATE_FAILURE:
            return  {
                updating: false,
                error: action.error
            };

        case userConstants.TEAM_REGISTER_REQUEST:
            return {
                teamRegistering: true
            };
        case userConstants.TEAM_REGISTER_SUCCESS:
            return {
                teamRegistering: false,
                teamRegSuccess: true
            };
        case userConstants.TEAM_REGISTER_FAILURE:
            return {
                teamRegistering: false,
                error: action.error
            };
        case userConstants.FORGOT_REQUEST:
            return {
                forgotPwdLoading: true
            };
        case userConstants.FORGOT_SUCCESS:
            return {
                forgotPwdLoading: false,
                forgotPwdSuccess: true
            };
        case userConstants.FORGOT_FAILURE:
            return {
                forgotPwdLoading: false
            };
        case userConstants.RESET_SUCCESS:
            return {
                resetPasswordSuccess: true,
                resetPasswordLoading: false
            };
        case userConstants.RESET_REQUEST:
            return {
                resetPasswordLoading: true
            };
        case userConstants.RESET_FAILURE:
            return {
                resetPasswordLoading: false
            };

        default:
                    return state
    }
}

export function profilePic (state = {}, action) {
    switch (action.type) {
        case userConstants.PROFILE_PIC_REQUEST:
            return {
                loading: true
            };
        case userConstants.PROFILE_PIC_SUCCESS:
            return {
                items: action
            };
        case userConstants.PROFILE_PIC_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}