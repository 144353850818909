import React, {useState, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from "../../services/api";
import { getUser, arrObjSorting, checkEmail, checkPhone } from "../Common/Utils";
import countryList from 'react-select-country-list';
import mccList from "mcc-mnc-list";
import Backdrop from "../Common/Backdrop";
import Propmt from "../Common/ConfirmationPrompt";
import './Settings.scss';

const UpdateAlert = async (data) => {
    const result = await api.put(
        `/updateUser`,
        data
    );
    return result;
};

const Alert = () => {
    const [countriesList, setCountryList] = React.useState([]);
    const [carrier, setCarrier] = React.useState([]);

    const [msg, setMsg] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");

    useEffect(()=> {
        const usr = getUser();
        if(usr){
            if(usr?.alert_mobile_country?.length){
                const code = usr.alert_mobile_country.split("-");
                getCarrier(code[0]);
            }
            setData({
                alert_email: usr.alert_email,
                alert_mobile: usr.alert_mobile,
                alert_mobile_carrier: usr.alert_mobile_carrier,
                alert_mobile_country: usr.alert_mobile_country
            });
        }
    }, []);

    useEffect(()=>{
        const options = countryList().getData();
        if(options){
            setCountryList(options)
        }
    });

    const getCarrier = (countryCode) => {
        let prev = [];
        const list = mccList.filter({statusCode: 'Operational', countryCode});
        const filterList = list.filter(c=> {
            const lbl = c.brand;//c.brand+" - "+c.operator;
            if(c.brand && !prev.includes(lbl)){
                prev.push(lbl);
                return true;
            }
        });
        setData(data => ({...data, ["alert_mobile_carrier"]: "" }));
        setCarrier(arrObjSorting(filterList, 'brand'));
    };

    const onChange = (e) => {
        const {name, value} = e && e.target || {};
        setData(data => ({...data, [name]: value }));
        if(name === "alert_mobile_country" && value.length){
            const code = value.split("-");
            getCarrier(code[0]);
        }
    };

    const onUpdate = () => {
        let alert_email="", alert_mobile="", alert_mobile_country="", alert_mobile_carrier="";

        if(data?.alert_mobile?.length){
            !data?.alert_mobile_country?.length && setErr("Country is required");
            !data?.alert_mobile_carrier?.length && setErr("Carrier is required");
            if(data?.alert_mobile_country?.length && data?.alert_mobile_carrier?.length){
                const validPhone = checkPhone(data.alert_mobile);
                if(!validPhone){
                    setErr("Enter a valid telephone number");
                    return;
                }else{
                    alert_mobile = data.alert_mobile;
                    alert_mobile_country = data.alert_mobile_country;
                    alert_mobile_carrier = data.alert_mobile_carrier;
                }
            } else {
                return;
            }
        }
        if(data?.alert_email?.length){
            const validEmail = checkEmail(data.alert_email);
            if(!validEmail){
                setErr("Enter a valid email");
                return;
            }else{
                alert_email = data.alert_email
            }
        }
        if(alert_email === "" && alert_mobile === ""){
            setErr("Enter email or telephone to proceed");
        }else{
            setErr("");
            setLoading(true);
            UpdateAlert({alert_email, alert_mobile, alert_mobile_country, alert_mobile_carrier}).then( (res) =>{
                    if(res && res.status === 200 && res.data.data){
                        let user = localStorage.getItem("user");
                        user = user && JSON.parse(user);
                        localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data}));
                    }
                    setLoading(false);
                },
                (error) => {
                    const e = error?.response?.data?.message?.toString() || "";
                    setErr(e?.length ? e : "Something went wrong");
                    setLoading(false);
                });
        }

    };

    return <div className='profile__form'>
        <div className="form-group">
            <label htmlFor="email" className="form-label">Email</label>
            <div className="input-group">
                <input type="email" id="email" className="form-input" name="alert_email"
                       placeholder="Enter Email" value={data.alert_email || ""} onChange={onChange}/>
                <p className="input-description">Email can be different from your account email. This will be used to send you <br /> alerts on the responses, set for specific conditions.</p>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="tel" className="form-label">Telephone </label>
            <div className="input-group">
                <input type="tel" id="tel" className="form-input" name="alert_mobile"
                       placeholder="Enter Telephone" value={data.alert_mobile || ""} onChange={onChange}/>
                <p className="input-description">Set up phone number for text alerts based on responses, set for specific<br />conditions.. Text alert is only available for US customers. If you would like to<br /> participate please fill up details.</p>
            </div>

            <div className="form-select-group">
                <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                        name="alert_mobile_country"
                        value={data.alert_mobile_country || ""}
                        onChange={onChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ maxHeight: 200 }}
                        MenuProps={{ classes: { paper: "h-max-200" } }}
                    >
                        <MenuItem key="Empty" value="" disabled>Country</MenuItem>
                        {countriesList.map(c=><MenuItem key={c.value} value={c.value+"-"+c.label}>{c.label}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                        name="alert_mobile_carrier"
                        value={data.alert_mobile_carrier || ""}
                        onChange={onChange}
                        onOpen = {()=>{
                            const c = data.alert_mobile_country||"";
                            setMsg({show: carrier.length === 0, text: c !== "" ? "No carrier available for this Country" : 'Please select the Country first'});
                        }}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{ classes: { paper: "h-max-200" } }}
                    >
                        <MenuItem value="" disabled>Carrier</MenuItem>
                        {carrier.map((c,i)=>{
                            return <MenuItem key={"car_"+i} value={c.brand}>{c.brand}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>

        { err.length ? <span style={{color: 'red'}}>{err}</span> : null}
        <button className="form-submit" onClick={onUpdate}>Update</button>
        <Backdrop show={loading} />
        <Propmt show={msg.show} handler={()=>setMsg({})} message={msg.text} singleBtn={true} btnLabel="OK"/>
    </div>
};

export default Alert;