import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Header, MainMenu } from '../Common/Header';
import AIAgentModal from '../CRM/CustomerTable/AgentModal';
import { agentsAction } from '../../actions/agentsActions';
import { useDispatch, useSelector } from "react-redux";
import { AgentsService } from '../../services/AgentsService';
import { agentsConstants } from '../../constants/agentsConstants';


const VoiceAgentsTable = () => {

  const [openAIAgentModal, setOpenAIAgentModal] = useState(false);
  const [editAIAgent, setEditAIAgent] = useState(false);
  const [agentsData, setAgentsData] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [filterText, setFilterText] = useState('');

  const dispatch = useDispatch();
  const isAgentAdded = useSelector((state) => state.agentsLogic.isAgentAdded);

  // useEffect(() => {
  //   (async () => {
  //     const data = await AgentsService.getAgents();
  //     setAgentsData(data?.data?.ai_agents);
  //   })();
  // }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      const data = await AgentsService.getAgents();
      setAgentsData(data?.data?.ai_agents);
    };

    fetchAgents();

    if (isAgentAdded) {
      fetchAgents();
      dispatch({ type: agentsConstants.RESET_AGENT_ADDED }); // Reset flag after re-fetch
    }
  }, [isAgentAdded, dispatch]);

  const columns = ["Agent Name", "Type", "Language", "Call Script", "Extension", "Instructions", "Status"];
  const rows = [
    {
      agent_id: 1,
      agent_name: "Agent 1",
      agent_type: "Inbound",
      preferred_lang: "English",
      call_script: "Welcome Script",
      extension: "101",
      sp_instructions: "Handle with care",
      status: "active"
    },
    {
      agent_id: 2,
      agent_name: "Agent 2",
      agent_type: "Outbound",
      preferred_lang: "Spanish",
      call_script: "Sales Pitch",
      extension: "102",
      sp_instructions: "Focus on product benefits",
      status: "active"
    }
  ];

  // const handleAIAgentSubmit = (agentData) => {
  //   console.log('AI Agent submitted:', agentData);
  //   dispatch(agentsAction.addAgents(agentData))
  //   dispatch(agentsAction.editAgent(agentData))
  //   setOpenAIAgentModal(false);
  // };

  const handleRowClick = (agent_id) => {
    setSelectedAgentId(agent_id);
  };
  

  const handleAIAgentSubmit = (agentData) => {

    if (editAIAgent) {
      dispatch(agentsAction.editAgent(agentData, selectedAgentId));
    } else {
      dispatch(agentsAction.addAgents(agentData));
    }

    setOpenAIAgentModal(false);
  };


  const handleAgentClick = () => {
    setOpenAIAgentModal(true);
  }
  
  const handleEditClick = () => {
    setOpenAIAgentModal(true);
    setEditAIAgent(true);
  }

  const filteredAgents = agentsData ? agentsData.filter(agent => 
    agent.agent_name.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.agent_type.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.preferred_lang.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.call_script.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.extension.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.sp_instructions.toLowerCase().includes(filterText.toLowerCase()) ||
    agent.status.toLowerCase().includes(filterText.toLowerCase())
  ) : [];


  return (
    <div>
      <Header />
      <MainMenu />
      <Container sx={{ mt: 4, maxWidth: '1400px' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          AI Voice Agents
        </Typography>

        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAgentClick}>
          Add Agent
        </Button>

        <TextField
          variant="outlined"
          placeholder="Enter content to filter"
          fullWidth
          sx={{ mb: 2 }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)} // Update filterText state
        />

        {/* <Button variant="contained" color="primary" sx={{ mb: 2 }}>
        Sort By
      </Button> */}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} sx={{ fontWeight: 'bold' }}>
                    {col}
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAgents && filteredAgents.length > 0 ? (
                filteredAgents.map((row) => (
                  <TableRow key={row.agent_id} onClick={() => handleRowClick(row.agent_id)} style={{ cursor: 'pointer' }}>
                    <TableCell>{row.agent_name}</TableCell>
                    <TableCell>{row.agent_type}</TableCell>
                    <TableCell>{row.preferred_lang}</TableCell>
                    <TableCell>{row.call_script}</TableCell>
                    <TableCell>{row.extension}</TableCell>
                    <TableCell>{row.sp_instructions}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                      <Button variant="contained" color="primary" size="small"
                        // startIcon={<EditIcon sx={{ fontSize: 13 }}/>}  
                        sx={{ mr: 1, fontSize: '0.75rem', textTransform: 'none', whiteSpace: 'nowrap' }}
                        onClick={handleEditClick}>
                        Edit Agent
                      </Button>
                      <IconButton color="primary"
                        onClick={() => dispatch(agentsAction.deleteAgent(row.agent_id))}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>


        <TablePagination
          component="div"
          count={1}
          rowsPerPage={10}
          page={0}
          onPageChange={() => { }}
          onRowsPerPageChange={() => { }}
        />
      </Container>

      <AIAgentModal
        open={openAIAgentModal}
        onClose={() => setOpenAIAgentModal(false)}
        handleSubmit={handleAIAgentSubmit}
        editAIAgent={editAIAgent}
        selectedAgentId={selectedAgentId}
        setEditAIAgent={setEditAIAgent}
      />

    </div>
  );
};

export default VoiceAgentsTable;
