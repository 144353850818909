import api from "./api";

async function getLatestSurveys(team_id) {
  const result = await api.get(`/getLatestSurveys`, {
    params: {
      team_id: team_id,
    },
  });

  return result;
}

const getAllThemes = async (params) => {
  const result = await api.get(`/getAllThemes`, {
    params: params,
  });
  return result;
};

const createDemo = async (params) => {
  const result = await api.post(`/createDemoSurvey`, params);
  return result;
};
export const dashboardService = {
  getLatestSurveys,
  getAllThemes,
  createDemo
};
