export const customCallConstant = {
  // GET DATA
  REGISTER_SIP: "REGISTER_SIP",
  GET_AUDIO_REF: "GET_AUDIO_REF",
  MAKE_CALL: "MAKE_CALL",
  SET_INCOMING_CALL: "SET_INCOMING_CALL",
  ACCEPT_CALL: "ACCEPT_CALL",
  HOLD_CALL: "HOLD_CALL",
  UNHOLD_CALL: "UNHOLD_CALL",
  MUTE_CALL: "MUTE_CALL",
  UNMUTE_CALL: "UNMUTE_CALL",
  TRANSFER_CALL: "TRANSFER_CALL",
  END_CALL: "END_CALL",
  DECLINE_CALL: "DECLINE_CALL",
  START_CAPTURE_VOICE: "START_CAPTURE_VOICE",
  STOP_CAPTURE_VOICE: "STOP_CAPTURE_VOICE",
};
