import { agentsConstants } from "../constants/agentsConstants";

const initialState = {
  loading: false,
  isAgentAdded: false,
  agents: [],
  message: "",
};

export function agentsLogic(state = initialState, action) {
  switch (action.type) {
    case agentsConstants.ADD_AGENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case agentsConstants.ADD_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAgentAdded: true, // Set success flag to true
        agents: [...state.agents, action.agents], // Optional: update agents list in state if needed
      };
    case agentsConstants.ADD_AGENTS_FAILURE:
      return {
        ...state,
        loading: false, 
        message: action.error,
        type: "error",
      };
      case agentsConstants.RESET_AGENT_ADDED:
  return {
    ...state,
    isAgentAdded: false,
  };

    default:
      return state;
  }
}
