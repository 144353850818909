import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import React from "react";
import { useParams } from "react-router-dom";

const VideoCall = () => {
  const { roomId } = useParams();

  const myMeeting = async (element) => {
    const appID = 907877787;
    const serverSecret = "2f9796ecc2f713612d5c08a677e6ee63";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomId,
      Date.now().toString(),
      "Bhavesh"
    );
    const zc = ZegoUIKitPrebuilt.create(kitToken);
    zc.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: "Copy Link",
          url: `http://localhost:3000/room/${roomId}`,
        },
      ],
      scenario: { mode: ZegoUIKitPrebuilt.OneONoneCall },
      showScreenSharingButton: false,
    });
  };
  return (
    <>
      <div>
        <div ref={myMeeting} />
      </div>
    </>
  );
};

export default VideoCall;
