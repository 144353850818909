import React from "react";
import MainDashboard from "../Dashboards/MainDashboard";

const UserSetting = ({history}) => {
    const tab = history?.location?.state?.tab || 0;
    return(
        <>
            <MainDashboard showUserSetting={true} settingsTab={tab}/>
        </>
    );
}


export default UserSetting;