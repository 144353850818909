import React, {useState} from 'react';
import {history} from "../../helpers";
import {Dropdown, RenameDialog} from './Component';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConfirmationDialog from '../Common/ConfirmationPrompt';
import { useDispatch, useSelector } from 'react-redux';
// import {workspaceActions } from '../../actions';
import {getUser} from '../Common/Utils';
import {SURVEY_BG_THEME} from '../Common/Constants';
// import Preview from './SurveyPreview';

const TemplateGrid = ({templates, category, setTemplateDialog})=>{
    const [previewStatus, setPreview] = useState({show:false});
    const onClick = (e, survey, tab) => {
        e && e.stopPropagation();
        if(survey.id && tab === "Preview"){
            setPreview({show: true, id:survey.id});
            return;
        }
        const args = {pathname:'/scratch/survey/'+survey.id, state:{tab}};
        survey.id && history.push(args);
    };
    const handleKebab = (pos, action) => {
        if(action === 'Copy'){
            setTemplateDialog({dailog:true, title: templates[pos].survey_title, category:category.name, pos});
        }
    };
    return  templates.length ?
        <div className={"productFeedbackWrap"}>
            {
                templates.map((item,index)=>{
                    const hasTheme = (item.survey_bg_theme && item.survey_bg_theme !== "");
                    const thm = SURVEY_BG_THEME['small'][item.survey_bg_theme];
                    const imgUrl = item?.survey_bg_theme?.includes('base64') ? item.survey_bg_theme : (origin + '/images/themes/'+thm||"");
                    const style = {backgroundImage :`url(${imgUrl})`};
                    return(
                        <div className="itemFeedBack" key={index}>
                            <div className={`surveyTheme ${hasTheme ? 'hasTheme':''}`} style={style}>
                                <h3 style={hasTheme ? {}:{color:"#181818"}}>{item.survey_title}</h3>
                                <button className="btnPrv" onClick={(e)=>onClick(e, item, 'Preview')}>Preview</button>
                                <div className={`overlay ${hasTheme ? 'active':''}`}></div>
                            </div>
                            <hr style={{margin:0}}/>
                            <div className="surveyDetail" style={{display:'flex'}}>
                                <div style={{width:'100%'}}>
                                    <div className="innerRow">
                                        <p>Questions</p>
                                        <div className="result">
                                            <em>{item.survey_questions_count}</em>
                                            <span className="title">View Questions</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon color={"blue"}/></span>*/}
                                    </div>
                                    <div className="innerRow">
                                        <p>Responses</p>
                                        <div className="result">
                                            <em>{item.responses}</em>
                                            <span className="title">View Responses</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon/></span>*/}
                                    </div>
                                    <div className="innerRow">
                                        <p>Completion</p>
                                        <div className="result">
                                            <em>{item.completion}</em>
                                            <span className="title">View Completion</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon/></span>*/}
                                    </div>
                            </div>
                                <div style={{cursor:'pointer'}}> <Dropdown name="template" pos={index} menus={['Copy']} handleKebab={handleKebab} /> </div>
                            </div>
                            {/* <Preview show={previewStatus.show} id={previewStatus.id} handleClose={()=>setPreview({show:false})}/> */}
                        </div>
                    )
                })
            }
        </div> :
        <div style={{textAlign:'center'}}><p>No templates available, change the category.</p></div>
};

const Surveys = ({data, count, workspaceList, setLoader, selectedWorkspace, detailsLoading})=>{
    const kebabMenus = ['Rename', 'View', 'Copy', 'Results', 'Move To', 'Copy To', 'Delete'];
    workspaceList.length==1 && kebabMenus.splice(4,2);
    const {team_id, user_id} = getUser();
    const [renameForm, setRename] = useState('');
    const [openPrompt, setPrompt] = useState(false);
    const [previewStatus, setPreview] = useState({show:false});
    const [selectedSurvey, setSurvey] = useState({});
    const dispatch = useDispatch();

    const onClick = (e, survey, tab) => {
        e && e.stopPropagation();
        if(survey.id && tab === "Preview"){
            setPreview({show: true, id:survey.id, type:survey.type});
            return;
        }
        const pathName = survey.type =="form"? '/scratch/form/': '/scratch/survey/';
        const args = {pathname:pathName +survey.id, state:{tab}};
        survey.id && history.push(args);
    };

    const handleKebab = (pos, action, newWs) => {
        setSurvey(data[pos]);
        switch (action){
            case 'View' : onClick(null, data[pos]);
                break;
            case 'Results' : onClick(null, data[pos], 'AnalyseResults');
                break;
            case 'Rename' : setRename(data[pos].survey_title);
                break;
            case 'Delete' : setPrompt(true);
                break;
            case 'Move To' : setLoader(true);
                // dispatch(workspaceActions.moveSurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: newWs.id}, user_id)).then((result)=>{
                //     setLoader(false);
                // });
                break;
            case 'Copy To' : setLoader(true);
                // dispatch(workspaceActions.copySurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: newWs.id}, user_id)).then((result)=>{
                //     setLoader(false);
                // });
                break;
            case 'Copy':
                // dispatch(workspaceActions.copySurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: data[pos].campaign_id}, user_id));
                break;
            default: break;
        }
    };

    const renameHandler = (e) => {
        if(e && e !== ""){
            const param = {survey_id:selectedSurvey.id, new_title:e};
            // dispatch(workspaceActions.updateWorkspaceDetail(param, user_id));
        }
        setRename("");
    };

    const promptHandler = (e) => {
        setPrompt(false);
        if(e){
            // dispatch(workspaceActions.deleteWorkspaceDetails(selectedSurvey.id, user_id));
        }
    };

    return data.length ?
        <div className={"productFeedbackWrap"}>
            {
                !detailsLoading && data.map((item,index)=>{
                    const cr_date = new Date(item?.start_date || item?.created).toLocaleDateString();
                    const up_date = (item.updated && item.updated !== "") ? new Date(item.updated).toLocaleDateString() : "";
                    const hasTheme = (item.survey_bg_theme && item.survey_bg_theme.length);
                    const thm = SURVEY_BG_THEME['small'][item.survey_bg_theme];
                    const imgUrl = item?.survey_bg_theme?.includes('base64') ? item.survey_bg_theme : (origin + '/images/themes/'+thm||"");
                    const style = {backgroundImage :`url(${imgUrl})`};
                    const isTypeForm = item.type==="form";

                    return(
                        <div className="itemFeedBack" key={index}>
                            {isTypeForm ? <span style={{background:"orange", padding:"0 10px",}} className="gridViewBadge">{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</span>: null}
                            {item.survey_type==null|| item.survey_type=="internal"?null:<span style={{background:"orange", padding:"0 10px",}} className="gridViewBadge">{item.survey_type.charAt(0).toUpperCase() + item.survey_type.slice(1)}</span>}
                            <div className={`surveyTheme surveyCard ${hasTheme ? 'hasTheme':''}`} style={style} onClick={(e)=>onClick(e,item)}>
                                <div className='surveyTitleWrap'>
                                    <h3 style={hasTheme ? {}:{color:"#181818"}} className="surveyTitle">{item.survey_title}
                                    </h3>
                                    <span className={`surveySubTitle ${hasTheme ? 'textWhite':''}`}>{item?.experience_type}</span>
                                </div>
                                <p style={{marginTop:10}}>Created : {cr_date}</p>
                                <p>Modified : {up_date}</p>
                                <button className="btnPrv" onClick={(e)=>onClick(e, item, 'Preview')}>Preview</button>
                                <div className={`overlay ${hasTheme ? 'active':''}`}></div>
                            </div>
                            <hr style={{margin:0}}/>
                            <div className="surveyDetail" style={{display:'flex'}}>
                                <div style={{width:'100%'}}>
                                    <div className="innerRow">
                                        <p>Questions</p>
                                        <div className="result">
                                            <em>{item.survey_questions_count}</em>
                                            <span className="title" onClick={(e)=>onClick(e, item, isTypeForm?'CreateForm':'DesignSurvey')}>View Questions</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon color={"blue"}/></span>*/}
                                    </div>
                                    <div className="innerRow">
                                        <p>Responses</p>
                                        <div className="result">
                                            <em>{item.responses}</em>
                                            <span className="title" onClick={(e)=>onClick(e, item, isTypeForm?'AnalyseSubmission':'AnalyseResults')}>View Responses</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon/></span>*/}
                                    </div>
                                    <div className="innerRow">
                                        <p>Completion</p>
                                        <div className="result">
                                            <em>{item.completion}%</em>
                                            <span className="title" onClick={(e)=>onClick(e, item, 'Summary')}>View Completion</span>
                                        </div>
                                        {/*<span className="copyRow"><ContentCopyIcon/></span>*/}
                                    </div>
                                </div>
                                <div style={{cursor:'pointer'}}> <Dropdown name="survey" pos={index} menus={kebabMenus} handleKebab={handleKebab} selectedWorkspace={selectedWorkspace} workspaces={workspaceList}/> </div>
                            </div>
                        </div>
                    )
                })
            }
            <RenameDialog title="Rename your survey" open={renameForm!==""} value={renameForm} name="survey" wsHandler={renameHandler} />
            <ConfirmationDialog show={openPrompt} handler={promptHandler}/>
            {/* <Preview show={previewStatus.show} id={previewStatus.id} handleClose={()=>setPreview({show:false})} type={previewStatus.type}/> */}
        </div>:
        <div style={{textAlign:'center'}}><p>No record found, Create your survey from scratch.</p></div>
};

const GridView = (props)=>{
    return(
        <Surveys {...props}/>
    )
}
export default GridView;
export {TemplateGrid};
