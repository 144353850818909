import { respConstants } from '../constants';

export function respondent(state={}, action) {
    switch (action.type) {
        case respConstants.GET_RESP_REQUEST:
            return {
                loading: true
            };
        case respConstants.GET_RESP_SUCCESS:
            return {};
        case respConstants.GET_RESP_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}