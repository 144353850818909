export function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));

  return {
    Authorization: "Bearer " + user.access_token,
    "Content-Type": "application/json",
  };
}

export function getLocalRefreshToken() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refresh_token;
}

export function getLocalAccessToken() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.access_token;
}

export function updateLocalAccessToken(token) {
  let user = JSON.parse(localStorage.getItem("user"));
  user.access_token = token;
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function setUser(user) {
  console.log(JSON.stringify(user));
  localStorage.setItem("user", JSON.stringify(user));
}

export function removeUser() {
  localStorage.removeItem("user");
}
