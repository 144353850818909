import api from "./api";

async function getPlanData(planType) {
  const result = await api.get(`/getPlanData?period=${planType}`);
  return result;
}

async function getFeaturesData(planType) {
  const result = await api.get(`/planFeatures?period=${planType}`);
  return result;
}

export const subscriptionService = {
  getPlanData,
  getFeaturesData,
};
