import { queryBuilderConstants } from "../constants";

export function queryBuilder(state = {}, action) {
  switch (action.type) {
    case queryBuilderConstants.REQUEST:
      return {
        loading: true,
      };
    case queryBuilderConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        items: action?.message?.data?.data,
        type: "success",
        message: action.message,
      };
    case queryBuilderConstants.ERROR:
      return {
        loading: false,
        message: action.message,
        type: "error",
      };

    case queryBuilderConstants.SAVE_PIVOTSTATE_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case queryBuilderConstants.SAVE_PIVOTSTATE_SUCCESS:
      console.log("SAVED PIVOTSTATE SUCCESS");
      return {
        ...state,
        pivots: [action.pivState],
      };
    case queryBuilderConstants.SAVE_PIVOTSTATE_FAILURE:
      return {
        ...state,

        error: action.error,
      };

    case queryBuilderConstants.GET_QUERIES_BY_ID_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case queryBuilderConstants.GET_QUERIES_BY_ID_SUCCESS:
      console.log("get queries by id SUCCESS", action);
      return {
        ...state,

        queries: action.detail_id.data.queries,
      };

    case queryBuilderConstants.GET_QUERIES_BY_ID_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    case queryBuilderConstants.DELETE_QUERY_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case queryBuilderConstants.DELETE_QUERY_SUCCESS:
      console.log("DELETE QUERIES SUCCESS", action);
      return {
        ...state,

        deleteQuery: action,
      };

    case queryBuilderConstants.DELETE_QUERY_FAILURE:
      return {
        ...state,

        error: action.error,
      };
    default:
      return state;
  }
}
