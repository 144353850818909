import React from 'react';
import {
    //BrowserRouter as Router,
    Link
} from "react-router-dom";
import 'antd/dist/antd.css';
import { Layout, Menu } from 'antd';
import './Navbar.css';
import {
    ShoppingOutlined,
    DashboardOutlined,
    FileTextOutlined,
    ControlOutlined,
    MenuOutlined,
    UserOutlined,
    //VideoCameraOutlined,
    //UploadOutlined,
    SearchOutlined,
    FileSearchOutlined,
    QuestionOutlined,
    CommentOutlined
} from '@ant-design/icons';

import { useEffect, useState } from 'react';

const { Sider } = Layout;

function NavBar() {
    const [expanded, setExpanded] = useState(false)

    // Mimics componentDidMount
    useEffect(() => {
        var path = window.location.pathname;
        if (path === "/") {
            document.getElementById("navbar-dashboard").click();
        } else if (path === "/decode") {
            document.getElementById("navbar-decode").click();
        } else if (path === "/resumes") {
            document.getElementById("navbar-resumes").click();
        } else if (path === "/jobs") {
            document.getElementById("navbar-jobs").click();
        } else if (path === "/reports") {
            document.getElementById("navbar-reports").click();
        } else if (path === "/search") {
            document.getElementById("navbar-search").click();
        } else if (path === "/account") {
            document.getElementById("navbar-account").click();
        } else if (path === "/help") {
            document.getElementById("navbar-help").click();
        }
        else if (path === "/faqs") {
            document.getElementById("navbar-faqs").click();
        } else if (path === "/contactUs") {
            document.getElementById("navbar-contactUs").click();
        }

        setExpanded(expanded => false);
    },[]);

    // Mimics toggle
    function handleClick() {
        setExpanded(expanded => !expanded);

        if(expanded) {
            // tell it to render something new
        }
        else {
            // tell it to render something
        }
    }

    return (

            <Sider collapsed={!expanded} id="nav-bar"  className="ant-layout-sider "
                style={{breakpoint:"lg",
                        collapsedWidth:"0",
                        overflow: 'auto'
                    //height: '100vh',
                    //position: 'absolute',
                    //left: 0,
                  }}
             >

                <div id="logo-container">

                    {React.createElement(!expanded ? MenuOutlined : MenuOutlined, {
                        className: 'trigger',
                        onClick: handleClick,
                    })}

                    {expanded &&
                        <div className="logo">
                            <img src="articence_logosmall.png" className="logoImg components-layout-demo-responsive" />
                        </div>
                    }

                </div>

                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item id="navbar-dashboard" key='1' icon={<DashboardOutlined />}><Link to="/">Dashboard</Link></Menu.Item>
                    <Menu.Item id="navbar-resumes" key='2' style={{display:'none'}} icon={<FileTextOutlined />}><Link to="/resumes" >Resumes</Link></Menu.Item>
                    <Menu.Item id="navbar-jobs" key='3'  style={{display:'none'}} icon={<ShoppingOutlined />}><Link to="/jobs">Jobs</Link></Menu.Item>
                    <Menu.Item id="navbar-decode" key='4' style={{display:'none'}} icon={<ControlOutlined />}><Link to="/decode">Decode</Link></Menu.Item>
                    <Menu.Item id="navbar-search" key='5' style={{display:'none'}} icon={<SearchOutlined />}><Link to="/search">Search</Link></Menu.Item>
                    <Menu.Item id="navbar-reports" key='6' style={{display:'none'}} icon={<FileSearchOutlined />}><Link to="/reports">Reports</Link></Menu.Item>
                    <Menu.Item id="navbar-account" key='7' icon={<UserOutlined />}><Link to="/account">Account</Link></Menu.Item>
                    <Menu.Item id="navbar-help" key='10' icon={<QuestionOutlined />}><Link to="/help">Help</Link></Menu.Item>
                    {/*<Menu.Item id="navbar-faqs" key='8' icon={<ControlOutlined />}><Link to="/faqs">Faqs</Link></Menu.Item>*/}
                    <Menu.Item id="navbar-contactUs" key='9' icon={<CommentOutlined />}><Link to='/contactUs'>Contact Us</Link></Menu.Item>
                </Menu>
            </Sider>

        )
}
export { NavBar };