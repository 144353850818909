import React from "react";
import { Modal } from "react-bootstrap";
import predefinedHeaders from "./CSVHeaderConstant";
const HeaderListModal = ({ isVisible, onClose }) => {
  return (
    <Modal size="lg" show={isVisible} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          CSV File header should match the following values -{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gap: "4px",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          {predefinedHeaders.map((header) => (
            <div
              style={{
                border: "1px solid lightGray",
                padding: "4px",
              }}
            >
              {header}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HeaderListModal;
