import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import profileImage from "../../../assets/images/profile.png";
import { useDispatch, useSelector } from "react-redux";
import { crmActions } from "../../../actions";

const InfoModal = ({
  show,
  handleClose,
  customer,
  position = { x: 0, y: 0 },
}) => {
  const [selectedDetail, setSelectedDetail] = useState(null);
  const dispatch = useDispatch();

  // Calculate if the modal is out of the viewport and adjust its position if needed
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const modalWidth = 400; // Width of the modal
  const modalHeight = 450; // Approximate height of the modal
  let adjustedX = position.x;
  let adjustedY = position.y;

  if (position.x + modalWidth > viewportWidth) {
    adjustedX = viewportWidth - modalWidth - 10; // 10px padding from the edge
  }
  if (position.y + modalHeight > viewportHeight) {
    adjustedY = viewportHeight - modalHeight - 10; // 10px padding from the edge
  }

  const handleDetailClick = (detail) => {
    setSelectedDetail(detail);
  };

  const handleSelect = (key) => {
    if (key === "statistics") {
      dispatch(crmActions.getEmailDetails(5381));
    }
  };

  const getAllEmailDetails = useSelector(
    (state) => state?.crmLogic?.message?.data
  );
  console.log(getAllEmailDetails, "<===getAllEmailDetails from API");

  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: `${adjustedY}px`,
        left: `${adjustedX}px`,
        backgroundColor: "white",
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0,0,0,0.25)",
        zIndex: 1000,
        width: "400px",
        height: "450px",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #ccc",
          paddingBottom: "10px",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ fontSize: "16px", margin: 0 }}>Customer Information</h2>
        <button
          onClick={handleClose}
          style={{
            cursor: "pointer",
            background: "none",
            border: "none",
            fontSize: "16px",
          }}
        >
          &times;
        </button>
      </div>
      <Tabs
        defaultActiveKey="profile"
        id="info-modal-tabs"
        onSelect={handleSelect}
      >
        <Tab eventKey="profile" title="Profile">
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <img
              src={profileImage}
              alt="Profile"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                border: "1px solid #000",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #ccc",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            <p style={{ margin: "5px 0" }}>
              <strong>Name:</strong> {customer.first_name} {customer.last_name}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Title:</strong> {customer.title}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Company:</strong> {customer.company}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Email:</strong> {customer.email}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Phone:</strong> {customer.phone}
            </p>
          </div>
        </Tab>
        <Tab eventKey="engagement" title="Engagement">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ margin: "5px 0" }}>
              <strong>Summary Response:</strong> {customer.summary_response}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Summary Engagement:</strong> {customer.summary_engagement}
            </p>
            <p style={{ margin: "5px 0" }}>
              <strong>Suggested Action Item:</strong>{" "}
              {customer.suggested_action_item}
            </p>
          </div>
        </Tab>
        <Tab eventKey="statistics" title="Statistics">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              onClick={() => handleDetailClick("emailSent")}
              style={{ cursor: "pointer", margin: "5px 0" }}
            >
              <span>Email Sent: </span>
              <span
                style={{
                  background: "#ccc",
                  borderRadius: "30px",
                  width: "40px",
                  height: "30px",
                  padding: "5px",
                }}
              >
                10
              </span>
            </div>

            <div
              onClick={() => handleDetailClick("emailOpenedCount")}
              style={{ cursor: "pointer", margin: "5px 0" }}
            >
              <span>Email Opened Count: </span>
              <span
                style={{
                  background: "#ccc",
                  borderRadius: "30px",
                  width: "40px",
                  height: "30px",
                  padding: "5px",
                }}
              >
              helloe
                {/* {getAllEmailDetails?.audience[0]?.email_opened_count} */}
              </span>
            </div>

            <div
              onClick={() => handleDetailClick("emailReplied")}
              style={{ cursor: "pointer", margin: "5px 0" }}
            >
              <span>Email Replied: </span>
              <span
                style={{
                  background: "#ccc",
                  borderRadius: "30px",
                  width: "40px",
                  height: "30px",
                  padding: "5px",
                }}
              >
                5
              </span>
            </div>
            <div
              onClick={() => handleDetailClick("surveySent")}
              style={{ cursor: "pointer", margin: "5px 0" }}
            >
              <span>Survey Sent: </span>
              <span
                style={{
                  background: "#ccc",
                  borderRadius: "30px",
                  width: "40px",
                  height: "30px",
                  padding: "5px",
                }}
              >
                8
              </span>
            </div>
            <div
              onClick={() => handleDetailClick("surveyReplied")}
              style={{ cursor: "pointer", margin: "5px 0" }}
            >
              <span>Survey Replied: </span>
              <span
                style={{
                  background: "#ccc",
                  borderRadius: "30px",
                  width: "40px",
                  height: "30px",
                  padding: "5px",
                }}
              >
                3
              </span>
            </div>
          </div>
          {selectedDetail && (
            <div style={{ marginTop: "20px" }}>
              <h4>Details for {selectedDetail.replace(/([A-Z])/g, " $1")}</h4>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead style={{ background: "#8FBC8F" }}>
                  <tr>
                    <th style={{ border: "1px solid #000", padding: "5px" }}>
                      Date of Call
                    </th>
                    <th style={{ border: "1px solid #000", padding: "5px" }}>
                      Call Duration
                    </th>
                    <th style={{ border: "1px solid #000", padding: "5px" }}>
                      Call Summary
                    </th>
                    <th style={{ border: "1px solid #000", padding: "5px" }}>
                      Call Text
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      2024-05-23
                    </td>
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      15 min
                    </td>
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      Positive
                    </td>
                    <td style={{ border: "1px solid #000", padding: "5px" }}>
                      Lorem ipsum dolor sit amet.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default InfoModal;
