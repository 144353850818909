import { appointmentConstants } from "../constants/appointmentConstants";
import { appointmentService } from "../services/AppointmentService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addAppointments = (addAppointementsData, Id) => {
    const request = () => ({
      type: appointmentConstants.ADD_APPOINTMENT_REQUEST, 
    });
  
    const success = (appointments) => ({
      type: appointmentConstants.ADD_APPOINTMENT_SUCCESS,
      appointments,
    });
  
    const failure = (error) => ({ 
      type: appointmentConstants.ADD_APPOINTMENT_FAILURE,
      error,
    });
  
    return (dispatch) => {
      dispatch(request());
      appointmentService.addAppointments(addAppointementsData, Id).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Appointment added successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };

  export const appointmentActions = {
    addAppointments
  };