import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Header } from "../../Common/Header";
import { MainMenu } from "../../Common/Header";
import { crmActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import "../../../CSS/AddCustomer.css";
import { history } from "../../../helpers";
import { dropDownActions } from "../../../actions/dropDownActions";
import { crmConstants } from "../../../constants/crmConstants";
const AddCustomer = () => {
  //states
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    title: "",
    mobile_phone: "",
    first_phone: "",
    work_direct_phone: "",
    city: "",
    state: "",
    country: "",
    company_address: "",
    company_city: "",
    company_state: "",
    company_country: "",
    company_phone: "",
    annual_revenue: "",
    industry: "",
    number_of_employees: 0,
    technologies: "",
    linkedin_url: "",
    twitter_url: "",
    facebook_url: "",
    account_owner: "",
    departments: "",
    account_team: "",
    keywords: "",
    timezone: "",
  });

  //hooks
  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.dropDown?.country?.data);
  const states = useSelector((state) => state?.dropDown?.states?.data?.states);
  const type = useSelector((state) => state?.crmLogic?.type);
  // console.log(type, "!!!!!!!!!!!!!!!!!!!!   type");
  // console.log(states, " ******************** states");
  // console.log(countryCode, " ********** countrycode");

  //functions
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.mobile_phone.length !== 11 ||
      formData.work_direct_phone.length !== 11 ||
      (formData.company_phone && formData.company_phone.length !== 11)
    ) {
      alert("Mobile number must have 11 digits.");
      return;
    }

    console.log("Form data:", formData);
    dispatch(crmActions.addCustomer(formData));
  };

  //effects
  useEffect(() => {
    dispatch(dropDownActions.fetchCountriesOptions());
  }, [dispatch]);

  useEffect(() => {
    if (countryCode) dispatch(dropDownActions.fetchStatesOptions(countryCode));
  }, [dispatch, countryCode]);

  useEffect(() => {
    if (type === crmConstants.ADD_CUSTOMER_SUCCESS) {
      history.push("/customerTable");
    }
  }, [type]);

  useEffect(() => {
    const stateObj = states?.find((state) => state.state_code === stateCode);
    if (stateObj?.timezones) {
      setTimeZone(stateObj.timezones[0]);
      setFormData((prevState) => ({
        ...prevState,
        timezone: stateObj.timezones[0],
      }));
    }
  }, [stateCode, states]);
  
  return (
    <>
      <div>
        <Header />
        <MainMenu />
        <Container style={{ marginTop: "50px" }}>
          <h1>Add Customer </h1> 
          <Form onSubmit={handleSubmit}>
            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Basic Info:
            </Form.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                First Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="first_name"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Last Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="last_name"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="company"
                  required
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Title
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                First Phone
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="first_phone"
                  onChange={handleChange}
                  placeholder="Enter phone number +1(999) 999-5555"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Mobile Phone <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="mobile_phone"
                  onChange={handleChange}
                  required
                  placeholder="Enter phone number +1(999) 999-5555"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Work Direct Phone <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="work_direct_phone"
                  onChange={handleChange}
                  required
                  placeholder="Enter phone number +1(999) 999-5555"
                />
              </Col>
            </Form.Group>

            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Profile Info:
            </Form.Text>

            

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  name="country"
                  required
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                    setStateCode("");
                    setTimeZone("");
                    handleChange(e);
                  }}
                >
                  <option value="">Select a country</option>
                  {countries?.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                State <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  name="state"
                  required
                  onChange={(e) => {
                    setStateCode(e.target.value);
                    handleChange(e);
                  }}
                  value={stateCode}
                >
                  <option value="">Select a state</option>
                  {states ? (
                    states?.length > 0 ? (
                      states?.map((state) => (
                        <option key={state.state_code} value={state.state_code}>
                          {state.name}
                        </option>
                      ))
                    ) : (
                      <option key={"no-state"} value={"no-state"}>
                        No state present
                      </option>
                    )
                  ) : (
                    <option>Loading...</option>
                  )}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                City <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  reqired
                  type="text"
                  name="city"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Timezone
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  disabled={true}
                  type="text"
                  name="timezone"
                  value={timeZone}
                />
              </Col>
            </Form.Group>

            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Contact Info:
            </Form.Text>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company Address
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="company_address"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company City
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="company_city"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company State
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="company_state"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company Country
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="company_country"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Company Phone
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="company_phone"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Company Info:
            </Form.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Annual Revenue <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="annual_revenue"
                  required
                  placeholder="100000"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Industry
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="industry"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Number of Employees
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="number_of_employees"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Technologies
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="technologies"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Social Info:
            </Form.Text>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                LinkedIn URL
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="url"
                  name="linkedin_url"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Twitter URL
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="url"
                  name="twitter_url"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Facebook URL
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="url"
                  name="facebook_url"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Text
              style={{
                fontSize: "18px",
                margin: "20px 0px",
                fontWeight: "bold",
              }}
            >
              Other Info:
            </Form.Text>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Account Owner
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="account_owner"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Departments
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="departments"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Account Team
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="account_team"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Keywords
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="keywords"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddCustomer;
