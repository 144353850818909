export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    CHECK_REQUEST: "USERS_CHECK_TOKEN_REQUEST",
    CHECK_SUCCESS: "USERS_CHECK_TOKEN_SUCCESS",
    CHECK_FAILURE: "USERS_CHECK_TOKEN_FAILURE",

    RESET_REQUEST: "RESET_REQUEST",
    RESET_SUCCESS: "RESET_SUCCESS",
    RESET_FAILURE: "RESET_FAILURE",

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    FORGOT_REQUEST: 'USERS_FORGOT_REQUEST',
    FORGOT_SUCCESS: 'USERS_FORGOT_SUCCESS',
    FORGOT_FAILURE: 'USERS_FORGOT_FAILURE',

    RESET_REQUEST: 'USERS_RESET_REQUEST',
    RESET_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_FAILURE: 'USERS_RESET_FAILURE',

    TEAM_REGISTER_REQUEST: 'TEAM_REGISTER_REQUEST',
    TEAM_REGISTER_SUCCESS: 'TEAM_REGISTER_SUCCESS',
    TEAM_REGISTER_FAILURE: 'TEAM_REGISTER_FAILURE',

    TEAM_UPDATE_REQUEST: 'TEAM_UPDATE_REQUEST',
    TEAM_UPDATE_SUCCESS: 'TEAM_UPDATE_SUCCESS',
    TEAM_UPDATE_FAILURE: 'TEAM_UPDATE_FAILURE',

    GET_ORG_REQUEST: 'GET_ORG_REQUEST',
    GET_ORG_SUCCESS: 'GET_ORG_SUCCESS',
    GET_ORG_FAILURE: 'GET_ORG_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    USER_VERIFY_REQUEST: 'USER_VERIFY_REQUEST',
    USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
    USER_VERIFY_FAILURE: 'USER_VERIFY_FAILURE',

    RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

    PROFILE_PIC_REQUEST: 'PROFILE_PIC_REQUEST',
    PROFILE_PIC_SUCCESS: 'PROFILE_PIC_SUCCESS',
    PROFILE_PIC_FAILURE: 'PROFILE_PIC_FAILURE',

};