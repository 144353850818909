export const agentsConstants = {
    // get all agents
    GET_ALL_AGENTS_REQUEST: "GET_ALL_AGENTS_REQUEST",
    GET_ALL_AGENTS_SUCCESS: "GET_ALL_AGENTS_SUCCESS",
    GET_ALL_AGENTS_FAILURE: "GET_ALL_AGENTS_FAILURE",
  
    // add agents
    ADD_AGENTS_REQUEST: "ADD_AGENTS_REQUEST",
    ADD_AGENTS_SUCCESS: "ADD_AGENTS_SUCCESS",
    ADD_AGENTS_FAILURE: "ADD_AGENTS_FAILURE",
    RESET_AGENT_ADDED: "RESET_AGENT_ADDED",
  
    // edit agents
    EDIT_AGENTS_REQUEST: "EDIT_AGENTS_REQUEST",
    EDIT_AGENTS_SUCCESS: "EDIT_AGENTS_SUCCESS",
    EDIT_AGENTS_FAILURE: "EDIT_AGENTS_FAILURE",
  
    // delete agents
    DELETE_AGENTS_REQUEST: "DELETE_AGENTS_REQUEST",
    DELETE_AGENTS_SUCCESS: "DELETE_AGENTS_SUCCESS",
    DELETE_AGENTS_FAILURE: "DELETE_AGENTS_FAILURE",
  };
  