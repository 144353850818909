export const crmConstants = {
  //get customer
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_ERROR: "GET_CUSTOMER_ERROR",
  GET_CUSTOMER_CLEAR: "CRM_CLEAR",

  //add customer
  ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",

  // edit customer
  EDIT_CUSTOMER_REQUEST: "EDIT_CUSTOMER_REQUEST",
  EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
  EDIT_CUSTOMER_FAILURE: "EDIT_CUSTOMER_FAILURE",

  // delete customer
  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",

  // get all notes
  GET_ALL_NOTES_REQUEST: "GET_ALL_NOTES_REQUEST",
  GET_ALL_NOTES_SUCCESS: "GET_ALL_NOTES_SUCCESS",
  GET_ALL_NOTES_FAILURE: "GET_ALL_NOTES_FAILURE",

  // add notes
  ADD_NOTES_REQUEST: "ADD_NOTES_REQUEST",
  ADD_NOTES_SUCCESS: "ADD_NOTES_SUCCESS",
  ADD_NOTES_FAILURE: "ADD_NOTES_FAILURE",

  // edit notes
  EDIT_NOTES_REQUEST: "EDIT_NOTES_REQUEST",
  EDIT_NOTES_SUCCESS: "EDIT_NOTES_SUCCESS",
  EDIT_NOTES_FAILURE: "EDIT_NOTES_FAILURE",

  // delete notes
  DELETE_NOTES_REQUEST: "DELETE_NOTES_REQUEST",
  DELETE_NOTES_SUCCESS: "DELETE_NOTES_SUCCESS",
  DELETE_NOTES_FAILURE: "DELETE_NOTES_FAILURE",

  // Email send
  SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",

};
