import {dropDownConstants} from "../constants/dropDownConstants";

const initialState = {
  country:null,
  states:null,
  loading: false,
  error: null,
};

const dropDown = (state = initialState, action) => {
  switch (action.type) {
    // countries
    case dropDownConstants.FETCH_COUNTRIES_REQUEST:
      return { ...state, loading: true };
    case dropDownConstants.FETCH_COUNTRIES_SUCCESS:
       
      return { ...state, loading: false, country: action.countries };
    case dropDownConstants.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // states
    case dropDownConstants.FETCH_STATES_REQUEST:
      return { ...state, loading: true };
      
    case dropDownConstants.FETCH_STATES_SUCCESS:
       
      return { ...state, loading: false, states: action.states };
      
    case dropDownConstants.FETCH_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.error,
        type: "error",
      };

    // cities

    // timezone

    default:
      return state;
  }
};

export default dropDown;
