import { pageLogicConstants } from "../constants";

export function pageLogic(state = {}, action) {
  switch (action.type) {
    case pageLogicConstants.REQUEST:
      return {
        loading: true,
      };
    case pageLogicConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        items: action?.message?.data?.data,
        type: "success",
        message: action.message,
      };
    case pageLogicConstants.ERROR:
      return {
        loading: false,
        message: action.message,
        type: "error",
      };

    default:
      return state;
  }
}
