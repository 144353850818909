import React, { useState, useEffect } from "react";
import Input from "../../common/controls/inputs/Input";
import { checkEmail } from "../Common/Utils";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions";
import { history } from "../../helpers";
import { WrapperComp } from "../Common/CommonWrapper";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

import "./../teamRegister/teamRegisterStyle.scss";

const RightForm = ({ onSubmitHandle, email, setEmail, isError }) => {
  const [isValid, setValidation] = useState(true);
  const onBlur = (e) => {
    if (email?.length) {
      let valid = checkEmail(email);
      setValidation(valid);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const onSubmit = (e) => {
      if (isValid) {
        onSubmitHandle(e);
      }
  };

  const onKeyPress = (e) => {
    if(e && e.key === "Enter"){
      let valid = checkEmail(email);
        if (email?.length) {
          valid && onSubmitHandle(e);
          setValidation(valid);
        }
        setValidation(valid);
    }
  };

  return (
    <div className={"rightRegister"}>
      <div className={"registerHeader"}>
        <div className="logoRegister">
          {" "}
          <img src="images/logo.png" />
        </div>
        <h1>Forgot Password?</h1>
      </div>
      <div className="signUplink">
        <p>Don’t have an account? </p>
        <button
          className="btn btnDefault"
          onClick={() => {
            history.push("/signup");
          }}
        >
          Sign up
        </button>
      </div>
      <div className="formWrap">
        <Input
          config={{
            label: "Please Enter your Registered email",
            name: "email",
            type: "text",
            className: "registerField",
            labelClass: "registerFieldLabel",
            placeholder: "Enter your registered email",
            required:true
          }}
          value={email}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          isError={isError || !isValid}
          error={email ? !isValid ? "Invalid email address" :"" : "Email is required"}
        />
        <button className="btn btnDefault" onClick={onSubmit}>
          Submit
        </button>
        <div className="link__container">
          <a onClick={()=>history.push("/login")} className="blue__link">Back to Sign In</a>
        </div>
      </div>
    </div>
  );
};
const SuccessfullyRegister = ({ email, onResend, showEmail }) => {
  const resend = () => {
    showEmail(false);
  };
  return (
    <div className="rightRegister finshiedWrapper">
      <div
        className="registerFinishWrap"
        style={{ backgroundImage: "url(images/SVG/submitForm-icon.svg)" }}
      >
        <h1>Password Change token</h1>
        <h4>
          We've sent an email to {email}, Get the token to change your password.
        </h4>
        <h5>if you did not receive the email,</h5>
        <Link onClick={resend}>Resend another email</Link>
      </div>
    </div>
  );
};

const ForgetPassword = () => {
  const [successfullyRegister, setSuccessfullyRegister] = React.useState(false);
  const [email, setEmail] = useState("");
  const [isError, showErr] = useState(false);
  const { forgotPwdSuccess = false, forgotPwdLoading = false } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();

  useEffect(() => {
    forgotPwdSuccess && setSuccessfullyRegister(true);
  }, [forgotPwdSuccess, forgotPwdLoading]);

  const onSubmitHandle = () => {
    if (email !== "") {
      dispatch(
        userActions.forgot({
          email: email,
        })
      );
    } else {
      showErr(true);
    }
  };

  return (
    <WrapperComp isLoader={forgotPwdLoading}>
      <div className={"teamRegister forgetPassword"}>
        <div className="leftRegister">
        <div className="bannerWrapper">
            <div className="bannerWrapper--left">
                <h1>Start your free trial today</h1>
                {/* <h2>No credit card required</h2> */}
                <p>The trial includes:</p>
                <ul>
                <li>
                    <DoneRoundedIcon />
                    Customized data based on your information
                </li>
                <li>
                    <DoneRoundedIcon />
                    Pre-configured dashboards and reports
                </li>
                <li>
                    <DoneRoundedIcon />
                    Customized templates and question banks
                </li>
                <li>
                    <DoneRoundedIcon />
                    Easy help and reference
                </li>
                <li>
                    <DoneRoundedIcon />
                    AI Analysis
                </li>
                </ul>
            </div>
            
            <div className="bannerWrapper--right">
                <img src="images/mainBannerFull.png" />
            </div>
            
          </div>
          {/* <img src="images/loginBanner.png"/> */}
        </div>
        {!successfullyRegister ? (
          <RightForm
            onSubmitHandle={onSubmitHandle}
            email={email}
            setEmail={setEmail}
            isError={isError}
          />
        ) : (
          <SuccessfullyRegister
            email={email}
            onResend={onSubmitHandle}
            showEmail={setSuccessfullyRegister}
          />
        )}
      </div>
    </WrapperComp>
  );
};

export default ForgetPassword;
