import React from "react";

const INPUT_ERRORS = {
  required: "This field is required.",
  username: "Username is required",
  password: "Password is required",
  email: "Email is required",
  invalidEmail: "Invalid email address",
  invalidPhone: "Invalid phone number",
  //invalidPassword: 'Must have 8 to 15 characters, at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.',
  invalidPassword: (
    <p>
      Must have 8 to 15 characters, at least one lowercase letter, one uppercase
      letter, one numeric digit, and one special character.
    </p>
  ),
  unmatchPwd: "Password not matched.",
};

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PWD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const PHONE_REGEX = /^\d{10}$/;

const NEW_SURVEY_HELP_TEXT =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore";

// if add question type in ANS_Type, then add indexing in ANS_TYPE_INDEXING as well
const ANS_TYPE = [
  {
    id: "LongText",
    value: "LongText",
    label: "Long Text",
    labelClass: "sortAnswerLabel sort1",
    icon: "/images/SVG/longtext.svg",
    iconClass: "sortAnswerIcon sort1",
    wrapperClass: "longText",
  },
  {
    id: "Rating",
    value: "Rating",
    label: "Rating",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/rating.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "rating",
  },
  {
    id: "Email",
    value: "Email",
    label: "Email",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/email.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "email",
  },
  {
    id: "ShortText",
    value: "ShortText",
    label: "Short Text",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/short-text.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "shortText",
  },
  {
    id: "Multiple",
    value: "Multiple",
    label: "Multiple choice",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/multiple.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "multiline",
  },
  {
    id: "Yes/No",
    value: "Yes/No",
    label: "Yes/No",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/yesNo.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "yesNo",
  },
  {
    id: "Picture",
    value: "Picture",
    label: "Picture Choice",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/picture.svg",
    iconClass: "sortAnswerIcon pictureIcon",
    wrapperClass: "picture",
  },
  {
    id: "Contact",
    value: "Contact",
    label: "Contact",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/contact.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "contact",
  },
  {
    id: "DropDown",
    value: "DropDown",
    label: "Drop Down",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/dropdown.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "dropdownIcon",
  },
  {
    id: "FileUpload",
    value: "FileUpload",
    label: "File Upload",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/file.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "file",
  },
  {
    id: "help",
    value: "Help",
    label: "Help",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/help.svg",
    iconClass: "sortAnswerIcon sortAnswerHelpIcon",
    wrapperClass: "helpIcon",
  },
  {
    id: "Card",
    value: "Card",
    label: "Card",
     labelClass: "sortAnswerLabel",
    icon: "/images/SVG/file.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "file",
  },
  {
    id: "Decorator",
    value: "Decorator",
    label: "Decorator",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/file.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "file",
  },
  {
    id: "Payment",
    value: "Payment",
    label: "Payment",
    labelClass: "sortAnswerLabel",
    icon: "/images/SVG/file.svg",
    iconClass: "sortAnswerIcon",
    wrapperClass: "file",
  },
];

const ANS_TYPE_INDEXING = {
  LongText: 0,
  Rating: 1,
  Email: 2,
  ShortText: 3,
  Multiple: 4,
  "Yes/No": 5,
  Picture: 6,
  Contact: 7,
  DropDown: 8,
  FileUpload: 9,
  Help: 10,
  Card:11,
  Decorator:12,
  Payment:13
};

const SURVEY_SUB_CATEGORIES = [
  "All categories",
  "community",
  "demographics",
  "recommended",
  "research",
];

const SURVEY_SETTINGS_MENU = {
  quesBank: { label: "QuestionBank", id: "QUESTION_BANK" },
  appearance: { label: "Appearance", id: "APPEARANCE" },
  format: { label: "Format", id: "FORMAT" },
  options: { label: "Option", id: "Option" },
  pageLogic: { label: "pageLogic", id: "PAGE_LOGIC" },
  tags: { label: "tags", id: "tags" },

  default: "appearance",
};

const APPEARANCE_SETTINGS_BY_QUES_TYPE = {
  ShortText: [{ name: "isMaxChar", label: "Max Characters" }],
  LongText: [{ name: "isMaxChar", label: "Max Characters" }],
  Picture: [
    { name: "show_label", label: "Show labels" },
    { name: "supersize", label: "Supersize" },
    { name: "multiple_selection", label: "Multiple Selection" },
    { name: "randomize", label: "Randomize" },
    { name: "horizontal", label: "Horizontal" },
  ],
  Rating: [
    {
      name: "rating",
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      types: ["Star", "Smily", "Heart", "Thumsup"],
      defaultValue: 5,
      defaultType: "Star",
    },
  ],
  Multiple: [
    { name: "multiple_selection", label: "Multiple Selection" },
    { name: "randomize", label: "Randomize" },
    { name: "horizontal", label: "Horizontal" },
  ],
  Contact: [{ name: "country_code" }],
  DropDown: [{ name: "sorting", label: "Alphabetical Order" }],
  Card: [
    // { name: "show_label", label: "Show labels" },
    // { name: "supersize", label: "Supersize" },
    {name: "removepic", label: "Remove Picture"}
  ],
};

const QUES_BANK_CAT = [
  { name: "All Categories", label: "All Categories" },
  { name: "Community", label: "Community" },
  { name: "Customer Feedback", label: "Customer Feedback" },
  { name: "Customer Survey", label: "Customer Survey" },
  { name: "Demographics", label: "Demographics" },
  { name: "Human Resources", label: "Human Resources" },
  { name: "Market Research", label: "Market Research" },
  { name: "Previously Questions", label: "Previously Questions" },
  { name: "Recommended Questions", label: "Recommended Questions" },
  { name: "Research", label: "Research" },
];

const SURVEY_BG_THEME = {
  small: {
    cubes1: "small_cubes1.jpg",
    cubes2: "small_cubes2.jpg",
    cubes3: "small_cubes3.jpg",
    cubes4: "small_cubes4.jpg",
    cubes5: "small_cubes5.jpg",
    cubes6: "small_cubes6.jpg",
    cubes7: "small_cubes7.jpg",
    leaf1: "small_Leaf1.jpg",
  },
  tablet: {
    cubes1: "tablet_cubes1.jpg",
    cubes2: "tablet_cubes2.jpg",
    cubes3: "tablet_cubes3.jpg",
    cubes4: "tablet_cubes4.jpg",
    cubes5: "tablet_cubes5.jpg",
    cubes6: "tablet_cubes6.jpg",
    cubes7: "tablet_cubes7.jpg",
    leaf1: "tablet_Leaf1.jpg",
  },
  web: {
    cubes1: "web_cubes1.jpg",
    cubes2: "web_cubes2.jpg",
    cubes3: "web_cubes3.jpg",
    cubes4: "web_cubes4.jpg",
    cubes5: "web_cubes5.jpg",
    cubes6: "web_cubes6.jpg",
    cubes7: "web_cubes7.jpg",
    leaf1: "web_Leaf1.jpg",
  },
};

const DECORATOR_BG_SVG = {
  small: {
    decorator1: "decorator1.svg",
    decorator2: "decorator2.svg",
    decorator3: "decorator3.svg",
  },
};

const ANALYSE_RESULTS_MENU = [
  { name: "AnswerAnalysis", label: "Answer Analysis" },
  { name: "AudienceAnalysis", label: "Audience Analysis" },
  { name: "DataRaw", label: "Data Raw" },
  { name: "SurveyEffectiveness", label: "Survey Effectiveness" },
  { name: "Tags", label: "Tags" },
  { name: "Querybuilder", label: "Query Builder" },
  { name: "AlertsDashboard", label: "Alerts Dashboard" },
  { name: "LexicalDataAnalysis", label: "Wordy Data Analysis" },
  { name: "LexicalAudienceAnalysis", label: "Wordy Audience Analysis" },
  { name: "LexicalEffectiveness", label: "Wordy Effectiveness" },
];

//const CLIENT_ID = "1097012772449-r7kehg9md4v6m9abibgg93gigpl0b8vr.apps.googleusercontent.com";  //Ata's google cloud client id
const CLIENT_ID =
  "612675255354-0qkd9nsdae2le75snqhb9k8bhd5bbttv.apps.googleusercontent.com"; //voc google cloud client id
const API_KEY = "AIzaSyDat1v722l5bOA8-pYUCAG7gBNCD1C4vbw";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
  "https://people.googleapis.com/$discovery/rest?version=v1",
];
const SCOPES =
  "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid";
const URL_VAL = "ydkof244mjfhkih933klk30hgmn44$oj";

const UPLOADED_SURVEY_TABS = [
  "Home",
  "Summary",
  "AnalyseResults",
  "SurveyName",
];
const FORM_MENUS = [
  "Home",
  "Summary",
  "CreateForm",
  "Preview",
  "CollectResponses",
  "AnalyseSubmission",
  "PageLogic",
  "FormName",
];

const LINKS = {
  contact: "/form_ans/4425&ydkof244mjfhkih933klk30hgmn44$oj",
  help_design_survey:"/form_ans/4419&ydkof244mjfhkih933klk30hgmn44$oj",
  help_design_form:"/form_ans/4430&ydkof244mjfhkih933klk30hgmn44$oj",
  help_360_view: "/survey_ans/4424&ydkof244mjfhkih933klk30hgmn44$oj",
  ai_models: "/form_ans/4415&ydkof244mjfhkih933klk30hgmn44$oj",
  help_main_dashboard:"https://voc.articence.com/form_ans/4433&ydkof244mjfhkih933klk30hgmn44$oj",
  contact_main_dashboard:"https://voc.articence.com/form_ans/4425&ydkof244mjfhkih933klk30hgmn44$oj",
  help_conversational_AI:"/form_ans/4432&ydkof244mjfhkih933klk30hgmn44$oj"
};


export {
  INPUT_ERRORS,
  EMAIL_REGEX,
  PWD_REGEX,
  NEW_SURVEY_HELP_TEXT,
  ANS_TYPE,
  SURVEY_SUB_CATEGORIES,
  PHONE_REGEX,
  SURVEY_SETTINGS_MENU,
  APPEARANCE_SETTINGS_BY_QUES_TYPE,
  ANS_TYPE_INDEXING,
  QUES_BANK_CAT,
  SURVEY_BG_THEME,
  DECORATOR_BG_SVG,
  ANALYSE_RESULTS_MENU,
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
  URL_VAL,
  UPLOADED_SURVEY_TABS,
  FORM_MENUS,
  LINKS
};
