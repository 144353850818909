import React, {useState, useEffect} from 'react';
import { getUser,checkPhone } from '../Common/Utils';
import Backdrop from '../Common/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from "../../services/api";
import './Settings.scss';

const UpdateUser = async (data) => {
    const result = await api.put(
        `/updateUser`,
        data
    );  
    return result;
};

const SMTPInfo = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [booleanList, setBooleanList] = React.useState([]);

    useEffect(()=> {
        const usr = getUser();
        if(usr){
           setData({
               smtp_server: usr.smtp_server,
               smtp_username: usr.smtp_username,
               smtp_sender_email: usr.smtp_sender_email,
               smtp_port: usr.smtp_port,
               smtp_password: usr.smtp_password,
           })
        }
        setBooleanList([
            {label: "Enabled", value: "true"},
            {label: "Disabled", value: "false"}
        ]);
    }, []);


    const onChange = (e) => {
        const {name, value} = e && e.target || {};
        setData(data => ({...data, [name]: value }));
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && update(data);
    };

    const phoneValidation = (e) => {
        const {value} = e?.target || {};
        if(value && value.length){
            const isValid = checkPhone(value);
            setErr(isValid ? "" : "Enter a valid telephone number");
        }
    };

    const update = () => {
        console.log("data123",data);
        
        if(data.smtp_username.length && data.smtp_server.length){
            updateBasicInfo();
        }else {
            setErr("Fill the all required fields")
        }
    };

    const updateBasicInfo = async () => {
        if(err && err.length){
            return;
        }
        setLoading(true);
        UpdateUser(data).then( (res) =>{
                if(res && res.status === 200 && res.data.data){
                    let user = localStorage.getItem("user");
                    user = user && JSON.parse(user);
                    localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data}));
                }
                setLoading(false);
            },
            (error) => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e?.length ? e : "Something went wrong");
                setLoading(false);
        });
    }; 

    return <div className='profile__form'>
        <div className="form-group">
            <label htmlFor="smtp_server" className="form-label">SMTP Server<p className="star">*</p></label>
            <input type="text" id="smtp_server" className="form-input" name="smtp_server"
                value={data.smtp_server ||''} 
                placeholder="Enter Your Server" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="smtp_username" className="form-label">Username<p className="star">*</p></label>
            <input type="text" id="smtp_username" className="form-input" name="smtp_username"
                   value={data.smtp_username ||''} 
                   placeholder="Enter Username" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="smtp_sender_email" className="form-label">Sender Email<p className="star">*</p></label>
            <input type="text" id="smtp_sender_email" className="form-input" name="smtp_sender_email"
                   value={data.smtp_sender_email ||''} 
                   placeholder="Enter Sender Email" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="smtp_port" className="form-label">SMTP Port<p className="star">*</p></label>
            <input type="text" id="smtp_port" className="form-input" name="smtp_port"
                   value={data.smtp_port ||''} 
                   placeholder="Enter SMTP Port" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="smtp_password" className="form-label">Password<p className="star">*</p></label>
            <input type="text" id="smtp_password" className="form-input" name="smtp_password"
                   value={data.smtp_password ||''} 
                   placeholder="Enter Password" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>

        { err.length ? <span style={{color:'red'}}>{err}</span> : null }
        <button className="form-submit" onClick={update}>Update</button>
        <Backdrop show={loading}/>
    </div>

};

export default SMTPInfo;
