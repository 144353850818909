import React, {useState} from 'react';
import api from "../../services/api";
import {checkPassword} from '../Common/Utils';
import Backdrop from '../Common/Backdrop';
import './Settings.scss';


const UpdatePassword = async (data) => {
    const result = await api.post(
        `/changePassword`,
        data
    );
    return result;
};

const Password = () => {
    const [data, setData] = useState({old_password:'', new_password:'', rePassword:'' });
    const [err, setErr] = useState("");
    const [inValid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
        const {name, value} = e && e.target || {};
        setData(data => ({...data, [name]: value }));
    };

    const onUpdate = () => {
        if(inValid){
            return;
        }
        const {new_password, rePassword, old_password} = data;
        if(new_password.length && old_password.length && rePassword.length){
            if(new_password === rePassword){
                setErr("");
                setLoading(true);
                UpdatePassword({ old_password, new_password }).then( (res) => {
                    setLoading(false);
                }, (err) => {
                    const  error = err?.response?.data?.message?.toString() || "";
                    setErr(error?.length ? error : 'Something went wrong');
                    setLoading(false);
                }
            );
            }else{
                setErr("Password not matched");
            }
        }else {
            setErr("Fill the all required fields");
        }
    };

    const PwdValidation = (e) => {
        const {value} = e.target;
        if(value && value.length){
            let validPass = checkPassword(value);
            setValid(!validPass);
            return;
        }
        if(data.new_password === "" && data.rePassword === ""){
            setValid(false);
        }
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && onUpdate();
    };


    return <div className='profile__form'>
        <div className="form-group">
            <label htmlFor="cpwd" className="form-label">Current Password <p className="star">*</p></label>
            <input type="password" id="cpwd" name="old_password" className="form-input"
                   placeholder="Current Password" value={data.old_password} onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="npassword" className="form-label">New Password <p className="star">*</p></label>
            <div className="input-group">
                <input type="password" id="npassword" name="new_password" className="form-input"
                       placeholder="New Password" value={data.new_password} onChange={onChange} onBlur={PwdValidation} onKeyPressCapture={onKeyPress}/>
                <p className="input-description" style={inValid ? {color:'red'}:{}}>Password is minimum 8 characters long with at least 1 number,<br /> 1 uppercase and 1 special character.</p>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="repwd" className="form-label">Re-Type Password <p className="star">*</p></label>
            <input type="password" id="repwd" name="rePassword" className="form-input"
                   placeholder="Re-Type Password" value={data.rePassword} onChange={onChange} onBlur={PwdValidation} onKeyPressCapture={onKeyPress}/>
        </div>
        { err.length ? <span style={{color:'red'}}>{err}</span> : null }
        <button className="form-submit" onClick={onUpdate}>Update</button>
        <Backdrop show={loading}/>
    </div>
};

export default Password;