import api from "./api";

async function uploadDetail(detail, id, heading) {
  var params = { ...detail, id: id, heading: heading };
  console.log("upload");
  console.log(detail);
  const result = await api.post(`/uploadDetail`, params);

  return result;
}

async function createCampaign(campaign) {
  const result = await api.post(`/createCampaign`, campaign);

  return result;
}

async function getCampaigns(team_id) {
  const result = await api.get(`/campaigns`, {
    params: {
      team_id: team_id,
    },
  });

  return result;
}

async function getCampaignExperience(team_id, experience_type) {
  const result = await api.get(`/getCampaignExperience`, {
    params: {
      team_id: team_id,
      experience_type: experience_type,
    },
  });

  return result;
}

async function updateCampaign(campaign) {
  const result = await api.put(`/updateCampaign`, campaign);

  return result;
}

async function getCampaign_by_id(id) {
  const result = await api.get(`/campaign`, {
    params: {
      id: id,
    },
  });

  return result;
}

async function createCampaignDetails(
  detail,
  campaign,
  team_id,
  drag,
  heading,
  active
) {
  var params = {
    ...detail,
    team_id: team_id,
    campaign: campaign,
    drag: drag,
    heading: heading,
    active: active,
  };

  const result = await api.post(`/createCampaignDetails`, params);

  return result;
}

async function updateSummary(campaign, team_id) {
  console.log("update services");
  var params = { team_id: team_id, campaign: campaign };

  const result = await api.put(`/updateSummary`, params);

  return result;
}

async function updateComboCategories(catGroups, detail_id) {
  const result = await api.post(`/updateComboCategories`, {
    catGroups: catGroups,
    detail_id: detail_id,
  });
  return result;
}

async function updateCategories(catGroups, detail_id, redo) {
  const result = await api.post(`/updateCategories`, {
    catGroups: catGroups,
    detail_id: detail_id,
    redo: redo,
  });
  return result;
}

async function getCampaignDetails(campaign_id) {
  const result = await api.get(`/campaignDetails`, {
    params: {
      campaign_id: campaign_id,
    },
  });

  return result;
}

async function getCampaignDetailById(details_id) {
  const result = await api.get(`/campaignDetail`, {
    params: {
      details_id: details_id,
    },
  });

  return result;
}

async function getExperience(id) {
  console.log(id);
  const result = await api.get(`/getExperience`, {
    params: {
      id: id,
    },
  });

  return result;
}

async function getExperienceByTypeAndRoute(experience_type, route) {
  const result = await api.get(`/getExperienceByTypeAndRoute`, {
    params: {
      experience_type: experience_type,
      route: route,
    },
  });

  return result;
}

async function getQuestDesc(detail_id) {
  const result = await api.get(`/getQuestDesc`, {
    params: {
      detail_id: detail_id,
    },
  });
  console.log("result");
  console.log(result);
  return result;
}

async function getSummary(team_id) {
  const result = await api.get(`/getSummary`, {
    params: {
      team_id: team_id,
    },
  });
  return result;
}

async function deleteCampaignDetails(details_id) {
  const result = await api.delete(`/deleteCampaignDetails`, {
    details_id: details_id,
  });

  return result;
}

async function updateCampaignDetails(detail, toggle) {
  var params = { ...detail, toggle: toggle };

  const result = await api.put(`/updateCampaignDetails`, params);

  return result;
}

async function updateQuestDesc(questDesc, detail_id) {
  const result = await api.put(`/updateQuestDesc`, questDesc);

  return result;
}

async function CampaignDetails(detail) {
  const result = await api.put(`/updateCampaignDetails`, detail);

  return result;
}

async function createChannel(channel) {
  const result = await api.post(`/createChannel`, channel);
  return result;
}

async function updateChannel(channel) {
  const result = await api.put(`/updateChannel`, channel);
  return result;
}

async function createWebsiteDetails(websiteDetails) {
  const result = await api.post(`/createWebsiteDetails`, websiteDetails);
  return result;
}

async function createScheduleToPublish(scheduleToPublish) {
  const result = await api.post(`/createScheduleToPublish`, scheduleToPublish);
  return result;
}

async function createChannelsByTeam(channelsByTeam) {
  const result = await api.post(`/createChannelsByTeam`, channelsByTeam);
  return result;
}

async function createQuestDesc(
  desc,
  userInfo,
  imageUrl,
  links,
  detail_id,
  styleData
) {
  console.log(userInfo);
  const params = JSON.stringify({
    detail_id: detail_id,
    imageUrl: imageUrl,
    questDesc: desc,
    links: links,
    userInfo: userInfo,
    styleData: styleData,
  });

  console.log(params);

  const result = await api.post(`/createQuestDesc`, params);
  console.log("In campaign Service");
  return result;
}

async function addData(detail_id, answerData, infoData, workflowData) {
  const params = {
    detail_id: detail_id,
    answerData: answerData,
    infoData: infoData,
    workflowData: workflowData,
  };
  console.log("PARAMS IN ADD DATA", params);

  const result = await api.post(`/addData`, params);
  console.log("In campaign Service addData");
  console.log(params);
  return result;
}

async function getDataByDetailId(detail_id) {
  const result = await api.get(`/getAnswerData`, {
    params: {
      detail_id: detail_id,
    },
  });

  return result;
}

async function reCategorize(catGroups, detail_id, redo, byQuest) {
  const result = await api.post(`/reCategorize`, {
    catGroups: catGroups,
    detail_id: detail_id,
    redo: redo,
    by_quest: byQuest,
  });
  return result;
}

async function addCategories(categories, excluded, detail_id) {
  const result = await api.post(`/addCategories`, {
    categories: categories,
    detail_id: detail_id,
    excluded: excluded,
  });
  return result;
}

async function getCategories(detail_id, question = null) {
  const result = await api.get(`/getCategories`, {
    params: {
      detail_id: detail_id,
      question: question,
    },
  });
  return result;
}

async function getDownload(detail_id) {
  const result = await api.get(`/getDownload`, {
    params: {
      detail_id: detail_id,
    },
  });
  return result;
}

async function getRecommended(
  detail_id,
  redo,
  question = null,
  answers,
  relations,
  cats,
  custom,
  customlist,
  experience
) {
  console.log("campaignService.answers:");
  console.log(
    detail_id,
    redo,
    question,
    answers,
    relations,
    cats,
    custom,
    customlist,
    experience
  );
  const result = await api.post(`/getRecommended`, {
    detail_id: detail_id,
    redo: redo,
    question: question,
    answers: answers,
    relations: relations,
    cats: cats,
    custom: custom,
    customlist: customlist,
    experience: experience,
  });
  return result;
}

async function getRecommendedCat(
  detail_id,
  redo,
  question = null,
  answers,
  api_id
) {
  console.log("answers:");
  console.log(answers);
  const result = await api.get(`/getRecommendedCat`, {
    params: {
      detail_id: detail_id,
      redo: redo,
      question: question,
      answers: answers,
      api_id: api_id,
    },
  });
  return result;
}

async function getAllRecommendedCat(detail_id, question) {
  console.log("services");
  console.log(question);
  const result = await api.get(`/getAllRecommendedCat`, {
    params: {
      detail_id: detail_id,
      question: question,
    },
  });
  return result;
}

async function getDataByCampaignId(campaign_id) {
  const result = await api.get(`/getHomeAnswerData`, {
    params: {
      campaign_id: campaign_id,
    },
  });

  return result;
}

async function getCustomCategories(detail_id, question) {
  const result = await api.get(`/getCustomCategories`, {
    params: {
      detail_id: detail_id,
      question: question,
    },
  });
  return result;
}

async function updateCustomCategories(detail_id, question) {
  const result = await api.put(`/updateCustomCategories`, {
    detail_id: detail_id,
    question: question,
  });

  return result;
}

async function addCustomCategories(
  detail_id,
  category_one,
  category_two,
  category_three,
  category_four,
  question
) {
  const params = {
    detail_id: detail_id,
    category_one: category_one,
    category_two: category_two,
    category_three: category_three,
    category_four: category_four,
    question: question,
  };

  const result = await api.post(`/createCustomizeCategories`, params);
  console.log("In custom categories addCustomCategories");
  console.log(params);
  return result;
}

async function copySurvey(detail_id) {
  const result = await api.get(`/copySurvey`, {
    params: {
      detail_id: detail_id,
    },
  });

  return result;
}

async function savePivotState(
  detail_id,
  query_name,
  query_description,
  questions,
  pivotState
) {
  const params = {
    detail_id: detail_id,
    query_name: query_name,
    query_description: query_description,
    questions: questions,
    pivot_state: JSON.stringify(pivotState),
  };

  const result = await api.post(`/createSavedQuery`, params);
  console.log("In savepivotstate");
  console.log(params);
  return result;

  // try {
  //     const params = {
  //         'detail_id': detail_id,
  //         'question': question,
  //         'pivot_state': pivotState

  //     };
  //     return params;

  //     // const result =  axios.post(`${apiUrl}/createCustomizeCategories`, {
  //     //     headers: authHeader(),
  //     //     params: params
  //     // });
  //     // console.log('In custom categories addCustomCategories');
  //     // console.log(params);
  //     // return result;
  // }
  // catch (err) {
  //     console.log(err);
  // }
}

async function getQueriesById(detail_id) {
  const result = await api.get(`/getQueriesById`, {
    params: {
      detail_id: detail_id,
    },
  });
  console.log("GETQQ RES", result);

  return result;
}

async function deleteQuery(detail_id, query_name) {
  const result = await api.post(`/deleteQuery`, {
    detail_id: detail_id,
    query_name: query_name,
  });
  console.log("GETQQ RES", result);

  return result;
}

export const campaignService = {
  createCampaign,
  getCampaigns,
  updateCampaign,
  getCampaign_by_id,
  updateQuestDesc,
  createCampaignDetails,
  getCampaignDetails,
  getCampaignDetailById,
  deleteCampaignDetails,
  updateCampaignDetails,
  createChannel,
  updateChannel,
  createWebsiteDetails,
  createScheduleToPublish,
  createChannelsByTeam,
  createQuestDesc,
  getQuestDesc,
  addData,
  getDataByDetailId,
  uploadDetail,
  reCategorize,
  addCategories,
  getCategories,
  getRecommended,
  getRecommendedCat,
  getAllRecommendedCat,
  getDataByCampaignId,
  getSummary,
  updateSummary,
  updateCategories,
  updateComboCategories,
  getDownload,
  getExperience,
  getCampaignExperience,
  getExperienceByTypeAndRoute,
  addCustomCategories,
  getCustomCategories,
  copySurvey,
  savePivotState,
  getQueriesById,
  deleteQuery,
};
