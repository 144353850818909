import { similarCasesConstants } from '../constants'

export function similarCases(state = {}, action) {
    switch (action.type) {
        case similarCasesConstants.SIMILAR_CASES_REQUEST:
            return {
                loading: true
            };
        case similarCasesConstants.SIMILAR_CASES_SUCCESS:
            return {
                cases: action.cases
            };
        case similarCasesConstants.SIMILAR_CASES_ERROR:
            return { error: action.error };
        default:
            return state
    }
}