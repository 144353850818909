import React from 'react';
import cn from 'classnames';
import '../inputsFields.css';

const Label = (props) => {
    const {config: { label, className, onClick, style={}, required=false } } = props,
        labelText = label ? (<div className={ cn('labelField', className) } style={style} onClick={onClick}>{label}
            {required ? <span style={{color:'red', marginLeft:2}}>*</span> : null}
        </div>) : null;
    return labelText;
};

export default Label;