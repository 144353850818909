import api from "./api";

async function callAi(customerId, data) {
  try {
    const result = await api.post(
      `/voc_crm/customers/${customerId}/call`,
      data
    );
    console.log("result", result);
    return result;
  } catch (error) {
    console.log("Error", error);
  }
}; 

const getAiResponse = async (customerId, params) => {
  const result = await api.get(
    `/voc_crm/customers/${customerId}/call/ai_response`,
    {
      params: params,
    }
  );
  return result;
};

export const CallService = {
  callAi,
  getAiResponse,
};
