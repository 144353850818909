import { teamConstants } from '../constants';

export function team(state = {}, action) {
    switch (action.type) {
        case teamConstants.GET_TEAM_REQUEST:
            return {
                loading: true
            };
        case teamConstants.GET_TEAM_SUCCESS:
            return {
                name: action.team.data.name,
                users: action.team.data.users
            };
        case teamConstants.GET_TEAM_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}