export const workspaceConstants = {
  CREATE_WS_DETAILS_REQUEST: "CREATE_WS_DETAILS_REQUEST",
  CREATE_WS_DETAILS_SUCCESS: "CREATE_WS_DETAILS_SUCCESS",
  CREATE_WS_DETAILS_FAILURE: "CREATE_WS_DETAILS_FAILURE",

  CREATE_QUEST_DESC_REQUEST: "CREATE_QUEST_DESC_REQUEST",
  CREATE_QUEST_DESC_SUCCESS: "CREATE_QUEST_DESC_SUCCESS",
  CREATE_QUEST_DESC_FAILURE: "CREATE_QUEST_DESC_FAILURE",

  GET_QUESTION_BANK_REQUEST: "GET_QUESTION_BANK_REQUEST",
  GET_QUESTION_BANK_SUCCESS: "GET_QUESTION_BANK_SUCCESS",
  GET_QUESTION_BANK_FAILURE: "GET_QUESTION_BANK_FAILURE",

  GET_WS_DETAIL_REQUEST: "GET_WS_DETAIL_REQUEST",
  GET_WS_DETAIL_SUCCESS: "GET_WS_DETAIL_SUCCESS",
  GET_WS_DETAIL_FAILURE: "GET_WS_DETAIL_FAILURE",

  GET_WS_DETAIL_WITH_AUTH_REQUEST: "GET_WS_DETAIL_WITH_AUTH_REQUEST",
  GET_WS_DETAIL_WITH_AUTH_SUCCESS: "GET_WS_DETAIL_WITH_AUTH_SUCCESS",
  GET_WS_DETAIL_WITH_AUTH_FAILURE: "GET_WS_DETAIL_WITH_AUTH_FAILURE",

  GET_QS_DESC_REQUEST: "GET_QS_DESC_REQUEST",
  GET_QS_DESC_SUCCESS: "GET_QS_DESC_SUCCESS",
  GET_QS_DESC_FAILURE: "GET_QS_DESC_FAILURE",

  ADD_DATA_REQUEST: "ADD_DATA_REQUEST",
  ADD_DATA_SUCCESS: "ADD_DATA_SUCCESS",
  ADD_DATA_FAILURE: "ADD_DATA_FAILURE",

  BULK_CREATE_EMAIL_LINKS_REQUEST: "BULK_CREATE_EMAIL_LINKS_REQUEST",
  BULK_CREATE_EMAIL_LINKS_SUCCESS: "BULK_CREATE_EMAIL_LINKS_SUCCESS",
  BULK_CREATE_EMAIL_LINKS_FAILURE: "BULK_CREATE_EMAIL_LINKS_FAILURE",

  DELETE_EMAIL_LINKS_REQUEST: "DELETE_EMAIL_LINKS_REQUEST",
  DELETE_EMAIL_LINKS_SUCCESS: "DELETE_EMAIL_LINKS_SUCCESS",
  DELETE_EMAIL_LINKS_FAILURE: "DELETE_EMAIL_LINKS_FAILURE",

  SHARE_SURVEY_EMAIL_REQUEST: "SHARE_SURVEY_EMAIL_REQUEST",
  SHARE_SURVEY_EMAIL_SUCCESS: "SHARE_SURVEY_EMAIL_SUCCESS",
  SHARE_SURVEY_EMAIL_FAILURE: "SHARE_SURVEY_EMAIL_FAILURE",

  WS_BY_TEAM_REQUEST: "WS_BY_TEAM_REQUEST",
  WS_BY_TEAM_SUCCESS: "WS_BY_TEAM_SUCCESS",
  WS_BY_TEAM_FAILURE: "WS_BY_TEAM_FAILURE",

  ALL_WORKSPACE_DETAILS_REQUEST: "ALL_WORKSPACE_DETAILS_REQUEST",
  ALL_WORKSPACE_DETAILS_SUCCESS: "ALL_WORKSPACE_DETAILS_SUCCESS",
  ALL_WORKSPACE_DETAILS_FAILURE: "ALL_WORKSPACE_DETAILS_FAILURE",

  CREATE_WORKSPACE_REQUEST: "CREATE_WORKSPACE_REQUEST",
  CREATE_WORKSPACE_SUCCESS: "CREATE_WORKSPACE_SUCCESS",
  CREATE_WORKSPACE_FAILURE: "CREATE_WORKSPACE_FAILURE",

  GET_WS_BY_USER_REQUEST: "GET_WS_BY_USER_REQUEST",
  GET_WS_BY_USER_SUCCESS: "GET_WS_BY_USER_SUCCESS",
  GET_WS_BY_USER_FAILURE: "GET_WS_BY_USER_FAILURE",

  TEMPLATE_TO_SURVEY_REQUEST: "TEMPLATE_TO_SURVEY_REQUEST",
  TEMPLATE_TO_SURVEY_SUCCESS: "TEMPLATE_TO_SURVEY_SUCCESS",
  TEMPLATE_TO_SURVEY_FAILURE: "TEMPLATE_TO_SURVEY_FAILURE",
};
