import React, {useState} from 'react';
import {history} from "../../helpers";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import ConfirmationDialog from '../Common/ConfirmationPrompt';
import {Dropdown, RenameDialog} from './Component';
import {getUser} from '../Common/Utils';
import { useDispatch, useSelector } from 'react-redux';
// import {workspaceActions } from '../../actions';
import {SURVEY_BG_THEME} from '../Common/Constants';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Ponds Product', 3, 4, '20%', '18 Jun 2022'),
    createData('Ice cream sandwich', 3, 4, '20%', '18 Jun 2022'),
    createData('Eclair', 3, 4, '20%', '18 Jun 2022'),
    createData('Cupcake', 3, 4, '20%', '18 Jun 2022'),
    createData('Gingerbread', 3, 4, '20%', '18 Jun 2022'),
];

const TemplateList = ()=> {
    return (
        <div className={"ListViewWrap"}>
            <TableContainer component={Paper} className={"tableWrap"}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Artsurvey</TableCell>
                            <TableCell align="center">Questions</TableCell>
                            <TableCell align="center">Responses</TableCell>
                            <TableCell align="center">Completion</TableCell>
                            <TableCell align="right">Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            const hasTheme = row?.survey_bg_theme?.length;
                            const thm = SURVEY_BG_THEME['small'][row.survey_bg_theme];
                            let style =  {background : `url(${origin + '/images/themes/'+thm})`};
                            style = hasTheme ? style : {...style, border: '1px solid #ccc'};
                            return <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <div className="themeIcon" style={style}></div>
                                    <span className="titleDate">
                                        <span className="title">{row.name}</span>
                                         <span className={"date"}>Created: 20 Jun 2022,</span>
                                    </span>
                                </TableCell>
                                <TableCell align="center"> <Tooltip title="View questions"><span className="inner">{row.calories}</span></Tooltip></TableCell>
                                <TableCell align="center"> <Tooltip title="View response"><span className="inner">{row.fat}</span></Tooltip></TableCell>
                                <TableCell align="center"> <Tooltip title="View insights"><span className="inner">{row.carbs}</span></Tooltip></TableCell>
                                <TableCell align="right">
                                    <span className="innerDate">
                                        {row.protein}
                                        <Dropdown/>
                                    </span>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

const TableView = ({data, count, workspaceList, setLoader, selectedWorkspace, detailsLoading})=> {
    const defaultTheme = "#fff";
    const kebabMenus = ['Rename', 'View', 'Copy', 'Results', 'Move To', 'Copy To', 'Delete'];
    const {team_id, user_id} = getUser();
    const [renameForm, setRename] = useState('');
    const [openPrompt, setPrompt] = useState(false);
    const [selectedSurvey, setSurvey] = useState({});
    const dispatch = useDispatch();

    const onClick = (e, survey, tab) => {
        e && e.stopPropagation();
        const pathName = survey.type=="form"? "/scratch/form/": "/scratch/survey/";
        const args = {pathname:pathName +survey.id, state:{tab}};
        survey.id && history.push(args);
    };

    const handleKebab = (pos, action, newWs) => {
        setSurvey(data[pos]);
        switch (action){
            case 'View' : onClick(null, data[pos]);
                break;
            case 'Results' : onClick(null, data[pos], 'AnalyseResults');
                break;
            case 'Rename' : setRename(data[pos].survey_title);
                break;
            case 'Delete' : setPrompt(true);
                break;
            case 'Move To' : setLoader(true);
                // dispatch(workspaceActions.moveSurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: newWs.id}, user_id)).then((result)=>{
                //     setLoader(false);
                // });
                break;
            case 'Copy To' : setLoader(true);
                // dispatch(workspaceActions.copySurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: newWs.id}, user_id)).then((result)=>{
                //     setLoader(false);
                // });
                break;
            case 'Copy':
                // dispatch(workspaceActions.copySurveyToWorkspace({survey_id:data[pos].id, new_workspace_id: data[pos].campaign_id}, user_id));
                break;
            default: break;
        }
    };

    const renameHandler = (e) => {
        if(e && e !== ""){
            const param = {survey_id:selectedSurvey.id, new_title:e};
            // dispatch(workspaceActions.updateWorkspaceDetail(param, user_id));
        }
        setRename("");
    };

    const promptHandler = (e) => {
        setPrompt(false);
        if(e){
            // dispatch(workspaceActions.deleteWorkspaceDetails(selectedSurvey.id, user_id));
        }
    };

    return data.length ?
        <div>
        <TableContainer component={Paper} className={"tableWrap"}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Artsurvey</TableCell>
                        <TableCell align="center">Questions</TableCell>
                        <TableCell align="center">Responses</TableCell>
                        <TableCell align="center">Completion</TableCell>
                        <TableCell align="right"> <span style={{marginRight:90}}>Update</span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!detailsLoading && data.map((row, index) => {
                        const cr_date = new Date(row?.start_date || row?.created).toLocaleDateString();
                        const up_date = (row.updated && row.updated !== "") ? new Date(row.updated).toLocaleDateString() : "";
                        const hasTheme = row?.survey_bg_theme?.length;
                        const thm = SURVEY_BG_THEME['small'][row.survey_bg_theme];
                        const isTypeForm = row.type==="form";
                        let style =  {background : `url(${origin + '/images/themes/'+thm})`};
                        style = hasTheme ? style : {...style, border: '1px solid #ccc'};
                        return <TableRow
                            key={row.name+'-'+index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            onDoubleClick={()=>onClick(null, data[index])}
                        >
                            <TableCell component="th" scope="row" style={{position:"relative"}}>
                            {isTypeForm?<span style={{background:"orange", padding:"0 10px", position:"absolute", fontSize:"10px",transform:"translate(-1px, -15px"}} className="listViewBadge">{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</span>:null}
                            {row.survey_type==null||row.survey_type === "internal"?null:<span style={{background:"orange", padding:"0 10px", position:"absolute", fontSize:"10px",transform:"translate(-7px, -15px"}} className="listViewBadge">{row.survey_type.charAt(0).toUpperCase() + row.survey_type.slice(1)}</span>}
                                <div className="themeIcon" style={style} onClick={(e)=>onClick(e, row)}></div>
                                <span className="titleDate">
                                    <span className="title">{row.survey_title}</span>
                                    <span className={`surveySubTitle ${hasTheme ? 'textWhite':''}`}>{row?.experience_type}</span>
                                    <span className={"date"}>Created: {cr_date}</span>
                                </span>
                            </TableCell>
                            <TableCell align="center"> <Tooltip title="View questions"><span className="inner" onClick={(e)=>onClick(e, row, isTypeForm?'CreateForm':'DesignSurvey')}>{row.survey_questions_count}</span></Tooltip></TableCell>
                            <TableCell align="center"> <Tooltip title="View response"><span className="inner" onClick={(e)=>onClick(e, row, isTypeForm?'AnalyseSubmission':'AnalyseResults')}>{row.responses}</span></Tooltip></TableCell>
                            <TableCell align="center"> <Tooltip title="View insights"><span className="inner" onClick={(e)=>onClick(e, row, 'Summary')}>{row.completion}</span></Tooltip></TableCell>
                            <TableCell align="right">
                                <span className="innerDate">
                                    {up_date}
                                    <Dropdown name="survey" pos={index} menus={kebabMenus} handleKebab={handleKebab} selectedWorkspace={selectedWorkspace} workspaces={workspaceList}/>
                                </span>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
            <RenameDialog title="Rename your survey" open={renameForm!==""} name="survey" value={renameForm} wsHandler={renameHandler} />
            <ConfirmationDialog show={openPrompt} handler={promptHandler}/>
        </div>:
        <div style={{textAlign:'center'}}><p>No record found, Create your survey from scratch.</p></div>
};

const ListView = (props)=>{
    return(
        <div className={"ListViewWrap"}>
           <TableView {...props}/>
        </div>
    )
}


export default ListView;
export {TemplateList};
