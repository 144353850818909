import { callAIConstant } from "../constants/callAIConstant";

export function callAi(state = {}, action) {
  console.log("action.type", action.type);
  switch (action.type) {
    //get customer
    case callAIConstant.GET_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case callAIConstant.GET_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action?.message?.data?.data,
        type: "success",
        message: action.message,
      };
    case callAIConstant.GET_CALL_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    // add customer
    case callAIConstant.ADD_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case callAIConstant.ADD_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        type: "ADD_CALL_SUCCESS",
      };
    case callAIConstant.ADD_CALL_FAILUER:
      return {
        ...state,
        loading: false,
        message: action.message,
        type: "error",
      };

    default:
      return state;
  }
}
