export const campaignConstants = {
    CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
    CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
    CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',

    GET_DOWNLOAD_REQUEST: 'GET_DOWNLOAD_REQUEST',
    GET_DOWNLOAD_SUCCESS: 'GET_DOWNLOAD_SUCCESS',
    GET_DOWNLOAD_FAILURE: 'GET_DOWNLOAD_FAILURE',

    GET_EXPERIENCE_REQUEST: 'GET_EXPERIENCE_REQUEST',
    GET_EXPERIENCE_SUCCESS: 'GET_EXPERIENCE_SUCCESS',
    GET_EXPERIENCE_FAILURE: 'GET_EXPERIENCE_FAILURE',

    UPDATE_CAMPAIGN_REQUEST: 'UPDATE_CAMPAIGN_REQUEST',
    UPDATE_CAMPAIGN_SUCCESS: 'UPDATE_CAMPAIGN_SUCCESS',
    UPDATE_CAMPAIGN_FAILURE: 'UPDATE_CAMPAIGN_FAILURE',

    UPLOAD_DETAILS_REQUEST: 'UPLOAD_DETAILS_REQUEST',
    UPLOAD_DETAILS_SUCCESS: 'UPLOAD_DETAILS_SUCCESS',
    UPLOAD_DETAILS_FAILURE: 'UPLOAD_DETAILS_FAILURE',

    GET_CAMPAIGNS_REQUEST: 'GET_CAMPAIGNS_REQUEST',
    GET_CAMPAIGNS_SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
    GET_CAMPAIGNS_FAILURE: 'GET_CAMPAIGNS_FAILURE',

    GET_SUMMARY_REQUEST: 'GET_SUMMARY_REQUEST',
    GET_SUMMARY_SUCCESS: 'GET_SUMMARY_SUCCESS',
    GET_SUMMARY_FAILURE: 'GET_SUMMARY_FAILURE',

    GET_CAMPAIGN_BY_ID_REQUEST: 'GET_CAMPAIGN_BY_ID_REQUEST',
    GET_CAMPAIGN_BY_ID_SUCCESS: 'GET_CAMPAIGN_BY_ID_SUCCESS',
    GET_CAMPAIGN_BY_ID_FAILURE: 'GET_CAMPAIGN_BY_ID_FAILURE',

    CREATE_DETAILS_REQUEST: 'CREATE_DETAILS_REQUEST',
    CREATE_DETAILS_SUCCESS: 'CREATE_DETAILS_SUCCESS',
    CREATE_DETAILS_FAILURE: 'CREATE_DETAILS_FAILURE',

    GET_DETAILS_REQUEST: 'GET_DETAILS_REQUEST',
    GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_DETAILS_FAILURE',

    GET_DETAIL_BY_ID_REQUEST: 'GET_DETAIL_BY_ID_REQUEST',
    GET_DETAIL_BY_ID_SUCCESS: 'GET_DETAIL_BY_ID_SUCCESS',
    GET_DETAIL_BY_ID_FAILURE: 'GET_DETAIL_BY_ID_FAILURE',

    UPDATE_DETAILS_REQUEST: 'UPDATE_DETAILS_REQUEST',
    UPDATE_DETAILS_SUCCESS: 'UPDATE_DETAILS_SUCCESS',
    UPDATE_DETAILS_FAILURE: 'UPDATE_DETAILS_FAILURE',

    CREATE_CHANNEL_REQUEST: 'CREATE_CHANNEL_REQUEST',
    CREATE_CHANNEL_SUCCESS: 'CREATE_CHANNEL_SUCCESS',
    CREATE_CHANNEL_FAILURE: 'CREATE_CHANNEL_FAILURE',

    UPDATE_CHANNEL_REQUEST: 'CREATE_CHANNEL_REQUEST',
    UPDATE_CHANNEL_SUCCESS: 'CREATE_CHANNEL_SUCCESS',
    UPDATE_CHANNEL_FAILURE: 'CREATE_CHANNEL_FAILURE',

    CREATE_WEBSITE_DETAILS_REQUEST: 'CREATE_WEBSITE_DETAILS_REQUEST',
    CREATE_WEBSITE_DETAILS_SUCCESS: 'CREATE_WEBSITE_DETAILS_SUCCESS',
    CREATE_WEBSITE_DETAILS_FAILURE: 'CREATE_WEBSITE_DETAILS_FAILURE',

    CREATE_SCHEDULE_TO_PUBLISH_REQUEST: 'CREATE_SCHEDULE_TO_PUBLISH_REQUEST',
    CREATE_SCHEDULE_TO_PUBLISH_SUCCESS: 'CREATE_SCHEDULE_TO_PUBLISH_SUCCESS',
    CREATE_SCHEDULE_TO_PUBLISH_FAILURE: 'CREATE_SCHEDULE_TO_PUBLISH_FAILURE',

    CREATE_CHANNELS_BY_TEAM_REQUEST: 'CREATE_CHANNELS_BY_TEAM_REQUEST',
    CREATE_CHANNELS_BY_TEAM_SUCCESS: 'CREATE_CHANNELS_BY_TEAM_SUCCESS',
    CREATE_CHANNELS_BY_TEAM_FAILURE: 'CREATE_CHANNELS_BY_TEAM_FAILURE',

    CREATE_QUEST_DESC_REQUEST: 'CREATE_QUEST_DESC_REQUEST',
    CREATE_QUEST_DESC_SUCCESS: 'CREATE_QUEST_DESC_SUCCESS',
    CREATE_QUEST_DESC_FAILURE: 'CREATE_QUEST_DESC_FAILURE',

    GET_QUEST_DESC_REQUEST: 'GET_QUEST_DESC_REQUEST',
    GET_QUEST_DESC_SUCCESS: 'GET_QUEST_DESC_SUCCESS',
    GET_QUEST_DESC_FAILURE: 'GET_QUEST_DESC_FAILURE',

    ADD_DATA_REQUEST: 'ADD_DATA_REQUEST',
    ADD_DATA_SUCCESS: 'ADD_DATA_SUCCESS',
    ADD_DATA_FAILURE: 'ADD_DATA_FAILURE',

    GET_DATA_REQUEST: 'GET_DATA_REQUEST',
    GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',
    GET_DATA_FAILURE: 'GET_DATA_FAILURE',

    REDO_CATEGORIES_REQUEST: 'REDO_CATEGORIES_REQUEST',
    REDO_CATEGORIES_SUCCESS: 'REDO_CATEGORIES_SUCCESS',
    REDO_CATEGORIES_FAILURE: 'REDO_CATEGORIES_FAILURE',

    ADD_CATEGORIES_REQUEST: 'ADD_CATEGORIES_REQUEST',
    ADD_CATEGORIES_SUCCESS: 'ADD_CATEGORIES_SUCCESS',
    ADD_CATEGORIES_FAILURE: 'ADD_CATEGORIES_FAILURE',

    GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

    GET_RECOMMENDED_REQUEST: 'GET_RECOMMENDED_REQUEST',
    GET_RECOMMENDED_SUCCESS: 'GET_RECOMMENDED_SUCCESS',
    GET_RECOMMENDED_FAILURE: 'GET_RECOMMENDED_FAILURE',

    GET_API_RECOMMENDED_REQUEST: 'GET_API_RECOMMENDED_REQUEST',
    GET_API_RECOMMENDED_SUCCESS: 'GET_API_RECOMMENDED_SUCCESS',
    GET_API_RECOMMENDED_FAILURE: 'GET_API_RECOMMENDED_FAILURE',

    GET_CUSTOM_CATEGORIES_REQUEST: 'GET_CUSTOM_CATEGORIES_REQUEST',
    GET_CUSTOM_CATEGORIES_SUCCESS: 'GET_CUSTOM_CATEGORIES_SUCCESS',
    GET_CUSTOM_CATEGORIES_FAILURE: 'GET_CUSTOM_CATEGORIES_FAILURE',

    ADD_CUSTOM_CATEGORIES_REQUEST: 'ADD_CUSTOM_CATEGORIES_REQUEST',
    ADD_CUSTOM_CATEGORIES_SUCCESS: 'ADD_CUSTOM_CATEGORIES_SUCCESS',
    ADD_CUSTOM_CATEGORIES_FAILURE: 'ADD_CUSTOM_CATEGORIES_FAILURE',

    UPDATE_CUSTOM_CATEGORIES_REQUEST: 'UPDATE_CUSTOM_CATEGORIES_REQUEST',
    UPDATE_CUSTOM_CATEGORIES_SUCCESS: 'UPDATE_CUSTOM_CATEGORIES_SUCCESS',
    UPDATE_CUSTOM_CATEGORIES_FAILURE: 'UPDATE_CUSTOM_CATEGORIES_FAILURE',

    COPY_SURVEY_REQUEST: 'COPY_SURVEY_REQUEST',
    COPY_SURVEY_SUCCESS: 'COPY_SURVEY_SUCCESS',
    COPY_SURVEY_FAILURE: 'COPY_SURVEY_FAILURE',

    SAVE_PIVOTSTATE_REQUEST: 'SAVE_PIVOTSTATE_REQUEST',
    SAVE_PIVOTSTATE_SUCCESS: 'SAVE_PIVOTSTATE_SUCCESS',
    SAVE_PIVOTSTATE_FAILURE: 'SAVE_PIVOTSTATE_FAILURE',

    GET_QUERIES_BY_ID_REQUEST: 'GET_QUERIES_BY_ID_REQUEST',
    GET_QUERIES_BY_ID_SUCCESS: 'GET_QUERIES_BY_ID_SUCCESS',
    GET_QUERIES_BY_ID_FAILURE: 'GET_QUERIES_BY_ID_FAILURE',

    DELETE_QUERY_REQUEST: 'DELETE_QUERY_REQUEST',
    DELETE_QUERY_SUCCESS: 'DELETE_QUERY_SUCCESS',
    DELETE_QUERY_FAILURE: 'DELETE_QUERY_FAILURE',


}