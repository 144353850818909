import Swal from "sweetalert2";

function CallswalAlert({
  position = "center",
  icon = "success",
  title = "Something went wrong",
  showConfirmButton = false,
}) {
  return Swal.fire({ position, icon, title, showConfirmButton });
}

export { CallswalAlert };
