import { subscriptionConstants } from "../constants";

export function subscription(state = {}, action) {
  switch (action.type) {
    case subscriptionConstants.GETPLANDATAREQUEST:
      return {
        loading: true,
      };
    case subscriptionConstants.GETPLANDATASUCCESS:
      return {
        ...state,
        loading: false,
        items: action?.message?.data,
        type: "success",
        message: action.message,
      };
    case subscriptionConstants.GETPLANDATAERROR:
      return {
        loading: false,
        message: action.message,
        type: "error",
      };

    case subscriptionConstants.GETFEATURESREQUEST:
      return {
        loading: true,
      };
    case subscriptionConstants.GETFEATURESSUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.message?.data,
        type: "success",
        message: action.message,
      };
    case subscriptionConstants.GETFEATURESERROR:
      return {
        loading: false,
        message: action.message,
        type: "error",
      };

    default:
      return state;
  }
}
