import React, { useEffect, useRef, useState } from "react";

const SpeechToText = () => {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  useEffect(() => {
    // Check if the browser supports SpeechRecognition
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Speech recognition is not supported in this browser.");
      return;
    }

    // Initialize the SpeechRecognition instance
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true; // Keeps listening until stopped
    recognitionRef.current.interimResults = true; // Returns partial results while speaking
    recognitionRef.current.lang = "en-US"; // Set language to English (change if needed)

    // Event handler for capturing the result
    recognitionRef.current.onresult = (event) => {
      let finalTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const result = event.results[i];
        finalTranscript += result[0].transcript;
      }
      setTranscript(finalTranscript);
    };

    recognitionRef.current.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
    };

    return () => {
      // Clean up the SpeechRecognition instance when the component is unmounted
      recognitionRef.current && recognitionRef.current.stop();
    };
  }, []);

  const startListening = () => {
    setIsListening(true);
    recognitionRef.current.start();
  };

  const stopListening = () => {
    setIsListening(false);
    recognitionRef.current.stop();
  };

  return (
    <div>
      <h2>Speech to Text</h2>
      <button onClick={isListening ? stopListening : startListening}>
        {isListening ? "Stop Listening" : "Start Listening"}
      </button>
      <p>Transcript:</p>
      <textarea value={transcript} readOnly rows={10} cols={50} />
    </div>
  );
};

export default SpeechToText;
