import { rulesConstants } from "../constants/rulesConstants";
import { RulesService } from "../services/RulesService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addRules = (addRulesData, Id) => {
    const request = () => ({
      type: rulesConstants.ADD_RULES_REQUEST,
    });
  
    const success = (rules) => ({
      type: rulesConstants.ADD_RULES_SUCCESS,
      rules,
    });
  
    const failure = (error) => ({
      type: rulesConstants.ADD_RULES_FAILURE,
      error,
    });
  
    return (dispatch) => {
      dispatch(request());
      RulesService.addRules(addRulesData, Id).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Rules added successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };

  const editRule = (addRulesData, ruleId) => {
  
    const request = () => ({
      type: rulesConstants.EDIT_RULES_REQUEST,
    });
  
    const success = (alerts) => ({
      type: rulesConstants.ADD_RULES_SUCCESS,
      alerts,
    });
  
    const failure = (error) => ({
      type: rulesConstants.EDIT_RULES_FAILURE,
      error,
    });
    return (dispatch) => {
      dispatch(request());
      RulesService.editRules(addRulesData, ruleId).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Rules updated successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };
  

  const deleteRule = (ruleId) => { 
   
    const request = () => ({
      type: rulesConstants.DELETE_RULES_REQUEST,
    });
    
    const success = (ruleId) => ({
      type: rulesConstants.DELETE_RULES_SUCCESS,
      ruleId,
    });
    
    const failure = (error) => ({
      type: rulesConstants.DELETE_RULES_FAILURE,
      error,
    });
    
    return (dispatch) => {
      dispatch(request());
      RulesService.deleteRule(ruleId).then(
        (alerts) => {
          dispatch(success(alerts));
          swalAlert({
            icon: "success",
            title: "Rule deleted successfully!",
            showConfirmButton: true,
          });
        },
        (error) => {
          dispatch(failure(error?.response?.data?.message?.toString()));
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
      );
    };
  };

  export const rulesActions = {
    addRules,
    editRule,
    deleteRule
  }
