import { CallswalAlert } from "../voc/Common/CallSwalAlert";
import { callAIConstant } from "../constants/callAIConstant";
import { CallService } from "../services";

const request = (massage) => ({
  type: callAIConstant.GET_CALL_REQUEST,
  massage,
});

const success = (massage) => ({
  type: callAIConstant.GET_CALL_SUCCESS,
  massage,
});

const error = (massage) => ({
  type: callAIConstant.GET_CALL_ERROR,
  massage,
});

const clear = () => ({
  type: callAIConstant.GET_CALL_CLEAR,
});

const callAi = (customerId, data) => {
  const request = () => ({
    type: callAIConstant.ADD_CALL_REQUEST,
  });
  const success = (alerts) => ({
    type: callAIConstant.ADD_CALL_SUCCESS,
    alerts,
  });
  const failure = (error) => ({
    type: callAIConstant.ADD_CALL_FAILUER,
    error,
  });

  return (dispatch) => {
    dispatch(request());

    CallService.callAi(customerId, data).then(
      (alerts) => {
        console.log("alerts", alerts);
        dispatch(success(alerts));
        // CallswalAlert({
        //   icon: "success",
        //   title: "Customer added successfully",
        //   showConfirmButton: true,
        // });
      },
      (error) => {
        console.log("Fail api calling ai", error);
        dispatch(failure(error?.response?.data?.message?.toString()));
        CallswalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAiResponse = (customerId, transcriptions) => {
  const request = (transcriptions) => ({
    type: callAIConstant.GET_CALL_REQUEST,
    transcriptions,
  });
  const success = (transcriptions) => ({
    type: callAIConstant.GET_CALL_SUCCESS,
    result: transcriptions,
  });
  const failure = (transcriptions) => ({
    type: callAIConstant.GET_CALL_ERROR,
    transcriptions,
  });

  console.log(transcriptions, "transcriptions")

  return (dispatch) => {
    dispatch(request(customerId, transcriptions));
    CallService.getWorkspace({ customerId: customerId, transcriptions: transcriptions }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(customerId, transcriptions, error?.response?.data?.message?.toString()));
      }
    );
  };
};

export const callAction = {
  request,
  success,
  error,
  clear,
  callAi,
  getAiResponse,
};
