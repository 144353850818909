import { workspaceConstants } from "../constants";
import { workspaceService } from "../services";
import { alertActions, userActions, emailActions } from "./";
import { history } from "../helpers";
import { swalAlert } from "../voc/Common/SwalAlert";

const createWorkspaceDetails = (details) => {
  const request = (data) => ({
    type: workspaceConstants.CREATE_WS_DETAILS_REQUEST,
    data,
  });
  const success = (data) => ({
    type: workspaceConstants.CREATE_WS_DETAILS_SUCCESS,
    data,
  });
  const failure = (data) => ({
    type: workspaceConstants.CREATE_WS_DETAILS_FAILURE,
    data,
  });

  return (dispatch) => {
    dispatch(request(details));

    workspaceService.createWorkspaceDetails(details).then(
      (details) => {
        const { id, type } = details.detail;
        const t = type === 'form' ? 'form' : 'survey';
        history.push(`/scratch/${t}/${id}`);
        dispatch(success(details));
      },
      (error) => {
        dispatch(failure(details, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createQuestDesc = (desc) => {
  const request = (desc) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_REQUEST,
  });
  const success = (desc) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_SUCCESS,
    result: desc,
  });
  const failure = (error) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_FAILURE,
    error,
  });
  const { detail_id = 0 } = desc;

  return (dispatch) => {
    dispatch(request(desc));

    workspaceService.createQuestDesc(desc).then(
      (desc) => {
        dispatch(success(desc));
        detail_id && dispatch(getWsDetailWithAuth(detail_id));
        //swalAlert({icon: 'success', title: `Details question descriptions posted.`, showConfirmButton: true });
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions posted.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createQuestDescription = (desc) => {
  const request = (desc) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_REQUEST,
  });
  const success = (desc) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_SUCCESS,
    result: desc,
  });
  const failure = (error) => ({
    type: workspaceConstants.CREATE_QUEST_DESC_FAILURE,
    error,
  });
  const { detail_id = 0 } = desc;

  return (dispatch) => {
    dispatch(request(desc));

    workspaceService.createQuestDesc(desc).then(
      (desc) => {
        dispatch(success(desc));
        detail_id && dispatch(getWsDetailWithAuth(detail_id));
        //swalAlert({icon: 'success', title: `Details question descriptions posted.`, showConfirmButton: true });
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions posted.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getQuestionBank = (
  experience,
  category = "",
  tags = "",
  keywords = ""
) => {
  const request = (detail) => ({
    type: workspaceConstants.GET_QUESTION_BANK_REQUEST,
    detail,
  });
  const success = (detail) => ({
    type: workspaceConstants.GET_QUESTION_BANK_SUCCESS,
    detail,
  });
  const failure = (error) => ({
    type: workspaceConstants.GET_QUESTION_BANK_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(experience));

    workspaceService.getQuestionBank(experience, category, tags, keywords).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getWsDetail = (detail_id) => {
  const request = (detail) => ({
    type: workspaceConstants.GET_WS_DETAIL_REQUEST,
    detail,
  });
  const success = (detail) => ({
    type: workspaceConstants.GET_WS_DETAIL_SUCCESS,
    result: detail,
  });
  const failure = (error) => ({
    type: workspaceConstants.GET_WS_DETAIL_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(detail_id));

    workspaceService.getWsDetail(detail_id).then(
      (res) => {
        const { data = {} } = res || {};
        dispatch(success(data.detail || {}));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getWsDetailWithAuth = (detail_id) => {
  const request = (detail) => ({
    type: workspaceConstants.GET_WS_DETAIL_WITH_AUTH_REQUEST,
    detail,
  });
  const success = (detail) => ({
    type: workspaceConstants.GET_WS_DETAIL_WITH_AUTH_SUCCESS,
    result: detail,
  });
  const failure = (error) => ({
    type: workspaceConstants.GET_WS_DETAIL_WITH_AUTH_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(detail_id));

    workspaceService.getWsDetailAuth(detail_id).then(
      (res) => {
        const { data = {} } = res || {};
        dispatch(success(data.detail || {}));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message.toString(),
          showConfirmButton: true,
        }).then(() => {
          window.location.href = "/";
        });
      }
    );
  };
};

const getQsDescByDetailID = (detail_id) => {
  const request = (detail) => ({
    type: workspaceConstants.GET_QS_DESC_REQUEST,
    detail,
  });
  const success = (detail) => ({
    type: workspaceConstants.GET_QS_DESC_SUCCESS,
    detail,
  });
  const failure = (error) => ({
    type: workspaceConstants.GET_QS_DESC_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(detail_id));

    workspaceService.getQsDescByDetailID(detail_id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const addData = (data) => {
  const request = (data) => ({ type: workspaceConstants.ADD_DATA_REQUEST });
  const success = (data) => ({
    type: workspaceConstants.ADD_DATA_SUCCESS,
    res: data,
  });
  const failure = (error) => ({
    type: workspaceConstants.ADD_DATA_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(data));
    workspaceService.addData(data).then(
      (answerData) => {
        dispatch(success(answerData));
        //swalAlert({icon: 'success', title: `Survey successfully submitted.`, showConfirmButton: true });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const bulkCreateEmailLinks = (audience, surveyID) => {
  const request = (audience) => ({
    type: workspaceConstants.BULK_CREATE_EMAIL_LINKS_REQUEST,
    audience,
  });
  const success = (audience) => ({
    type: workspaceConstants.BULK_CREATE_EMAIL_LINKS_SUCCESS,
    audience,
  });
  const failure = (audience) => ({
    type: workspaceConstants.BULK_CREATE_EMAIL_LINKS_FAILURE,
    audience,
  });

  return (dispatch) => {
    dispatch(request(audience));
    workspaceService.bulkCreateEmailLinks(audience).then(
      (audience) => {
        surveyID && dispatch(emailActions.getAudience(surveyID));
        dispatch(success(audience));
      },
      (error) => {
        dispatch(failure(audience, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteEmailLinks = (emailLinkIds) => {
  const request = (emailLinkIds) => ({
    type: workspaceConstants.DELETE_EMAIL_LINKS_REQUEST,
    emailLinkIds,
  });
  const success = (emailLinkIds) => ({
    type: workspaceConstants.DELETE_EMAIL_LINKS_SUCCESS,
    emailLinkIds,
  });
  const failure = (emailLinkIds) => ({
    type: workspaceConstants.DELETE_EMAIL_LINKS_FAILURE,
    emailLinkIds,
  });

  return (dispatch) => {
    dispatch(request(emailLinkIds));
    workspaceService.deleteEmailLinks(emailLinkIds).then(
      (emailLinkIds) => {
        dispatch(success(emailLinkIds));
      },
      (error) => {
        dispatch(
          failure(emailLinkIds, error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const shareSurveyByEmail = (details) => {
  const { survey_id } = details;
  const request = (details) => ({
    type: workspaceConstants.SHARE_SURVEY_EMAIL_REQUEST,
    details,
  });
  const success = (details) => ({
    type: workspaceConstants.SHARE_SURVEY_EMAIL_SUCCESS,
    details,
  });
  const failure = (details) => ({
    type: workspaceConstants.SHARE_SURVEY_EMAIL_FAILURE,
    details,
  });
  return (dispatch) => {
    dispatch(request(details));
    workspaceService.shareSurveyByEmail(details).then(
      (details) => {
        dispatch(success(details));
        dispatch(emailActions.getAudience(survey_id));
        //swalAlert({icon: 'success', title: `Email successfully sent.`, showConfirmButton: true });
      },
      (error) => {
        dispatch(failure(details, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};



const oauth2TokenExchange = (detail) => {
  return () => {
    return workspaceService.oauth2TokenExchange(detail).then((res) => {
      return res;
    });
  };
};

const getWorkspaceByTeamID = (teamId) => {
  const request = (teamId) => ({
    type: workspaceConstants.WS_BY_TEAM_REQUEST,
    teamId,
  });
  const success = (teamId) => ({
    type: workspaceConstants.WS_BY_TEAM_SUCCESS,
    result: teamId,
  });
  const failure = (teamId) => ({
    type: workspaceConstants.WS_BY_TEAM_FAILURE,
    teamId,
  });
  return (dispatch) => {
    dispatch(request(teamId));
    workspaceService.getWorkspace({ team_id: teamId, user_id: "" }).then(
      (teamId) => {
        dispatch(success(teamId));
      },
      (error) => {
        dispatch(failure(teamId, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getWorkspaceByUser = (userId) => {
  const request = (userId) => ({
    type: workspaceConstants.GET_WS_BY_USER_REQUEST,
    userId,
  });
  const success = (userId) => ({
    type: workspaceConstants.GET_WS_BY_USER_SUCCESS,
    result: userId,
  });
  const failure = (userId) => ({
    type: workspaceConstants.GET_WS_BY_USER_FAILURE,
    userId,
  });
  return (dispatch) => {
    dispatch(request(userId));
    workspaceService.getWorkspace({ team_id: "", user_id: userId }).then(
      (userId) => {
        dispatch(success(userId));
      },
      (error) => {
        dispatch(failure(userId, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAllWSDetails = (detailID, order_by = null) => {
  const request = (detailID) => ({
    type: workspaceConstants.ALL_WORKSPACE_DETAILS_REQUEST,
    detailID,
  });
  const success = (detailID) => ({
    type: workspaceConstants.ALL_WORKSPACE_DETAILS_SUCCESS,
    result: detailID,
  });
  const failure = (detailID) => ({
    type: workspaceConstants.ALL_WORKSPACE_DETAILS_FAILURE,
    detailID,
  });
  return (dispatch) => {
    dispatch(request(detailID));
    workspaceService.getAllWsDetails({ campaign_id: detailID, order_by }).then(
      (detailID) => {
        // console.log("detailid", detailID);
        if (detailID) dispatch(success(detailID));
      },
      (error) => {
        dispatch(failure(detailID, error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createWorkspace = (data) => {
  const request = (data) => ({
    type: workspaceConstants.CREATE_WORKSPACE_REQUEST,
  });
  const success = (data) => ({
    type: workspaceConstants.CREATE_WORKSPACE_SUCCESS,
    res: data,
  });
  const failure = (error) => ({
    type: workspaceConstants.CREATE_WORKSPACE_FAILURE,
    error,
  });
  const { user_id } = data;
  return (dispatch) => {
    dispatch(request(data));
    return workspaceService.createWorkspace(data).then(
      (answerData) => {
        dispatch(success(answerData));
        dispatch(getWorkspaceByUser(user_id));
        return answerData;
        //swalAlert({icon: 'success', title: `Survey successfully submitted.`, showConfirmButton: true });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteWorkspace = (id, user_id) => {
  return (dispatch) => {
    return workspaceService.deleteWorkspace({ workspace_id: id }).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateWorkspace = (data, user_id) => {
  return (dispatch) => {
    return workspaceService.updateWorkspace(data).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const duplicateWorkspace = (id, user_id) => {
  return (dispatch) => {
    workspaceService.duplicateWorkspace({ workspace_id: id }).then(
      (res) => {
        //getAllWSDetails
        dispatch(getWorkspaceByUser(user_id));
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteWorkspaceDetails = (id, user_id) => {
  return (dispatch) => {
    workspaceService.deleteWorkspaceDetails({ details_id: id }).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateWorkspaceDetail = (data, user_id) => {
  return (dispatch) => {
    workspaceService.updateWorkspaceDetail(data).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const setWsPriority = (data, user_id) => {
  return (dispatch) => {
    workspaceService.setWsPriority(data).then(
      (res) => {
        //dispatch(getWorkspaceByUser(user_id));
        // console.log("set priority : - ", res);
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const moveSurveyToWorkspace = (data, user_id) => {
  return (dispatch) => {
    return workspaceService.moveSurveyToWorkspace(data).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const copySurveyToWorkspace = (data, user_id) => {
  return (dispatch) => {
    return workspaceService.copySurveyToWorkspace(data).then(
      (res) => {
        dispatch(getWorkspaceByUser(user_id));
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createSurveyFromTemplate = (data, user_id) => {
  const request = (data) => ({
    type: workspaceConstants.TEMPLATE_TO_SURVEY_REQUEST,
  });
  const success = (data) => ({
    type: workspaceConstants.TEMPLATE_TO_SURVEY_SUCCESS,
    res: data,
  });
  const failure = (error) => ({
    type: workspaceConstants.TEMPLATE_TO_SURVEY_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request(data));
    workspaceService.createSurveyFromTemplate(data).then(
      (res) => {
        dispatch(success(res));
        // dispatch(getWorkspaceByUser(user_id));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getSummary = (detail) => {
  return () => {
    return workspaceService.getSummary(detail).then((res) => {
      let result = {};
      if (res?.data) {
        result = res.data;
      }
      return result;
    });
  };
};
const surveyStop = (details) => {
  return () => {
    return workspaceService.surveyStop(details).then(
      (res) => {
        return res;
      },
      (error) => {
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const workspaceActions = {
  createWorkspaceDetails,
  createQuestDesc,
  createQuestDescription,
  getQuestionBank,
  getWsDetail,
  getQsDescByDetailID,
  addData,
  bulkCreateEmailLinks,
  deleteEmailLinks,
  shareSurveyByEmail,
  oauth2TokenExchange,
  getWorkspaceByTeamID,
  getAllWSDetails,
  createWorkspace,
  getWorkspaceByUser,
  deleteWorkspace,
  updateWorkspace,
  duplicateWorkspace,
  deleteWorkspaceDetails,
  updateWorkspaceDetail,
  setWsPriority,
  moveSurveyToWorkspace,
  copySurveyToWorkspace,
  createSurveyFromTemplate,
  getWsDetailWithAuth,
  getSummary,
  surveyStop,
};
