import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "./Settings.scss";
import Backdrop from "../Common/Backdrop";
import api from "../../../src/services/api";
import { Button } from "@mui/material";
import { swalAlert } from "../Common/SwalAlert";

const SetPlanDetails = () => {
  const [data, setData] = React.useState({});
  const [err, setErr] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [provider, setProvider] = React.useState("");
  const [credentials, setCredentials] = React.useState({});
  const [savedData, setSavedData] = React.useState(null);
  const [providersData, setProvidersData] = React.useState({});
  const [validationError, setValidationError] = React.useState("");
  const [providersList, setProvidersList] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState("");
  const [paypalKey1, setPaypalKey1] = React.useState("");
  const [paypalKey2, setPaypalKey2] = React.useState("");
  const [stripeKey1, setStripeKey1] = React.useState("");
  const [stripeKey2, setStripeKey2] = React.useState("");
  const [bamboraKey1, setBamboraKey1] = React.useState("");
  const [bamboraKey2, setBamboraKey2] = React.useState("");
  const [bamboraKey3, setBamboraKey3] = React.useState(""); // Add the third key
  const [bamboraKey3ValidationError, setBamboraKey3ValidationError] = React.useState(""); // Validation for the third key
  const [serviceCharges, setServiceCharges] = React.useState({});

  useEffect(() => {
    getProviderKeys();
  }, []);

  const getProviderKeys = async () => {
    try {
      const response = await api.get("/getProviderKeys", {
        params: {
          jwt_authentication: localStorage.getItem("token"),
        },
      });
  
      const { data } = response;
      console.log(response, "RESPONSE<>IN<>SERVICECHARGE");
  
      if (data && data?.data && data?.data.providers) {
      // Filter out Bambora from the list of providers
      const filteredProviders = Object.keys(data.data.providers)
        .filter(provider => provider !== "Bambora")
        .reduce((obj, key) => {
          obj[key] = data.data.providers[key];
          return obj;
        }, {});

      setProvidersData(filteredProviders);
      const list = Object.keys(filteredProviders);
      setProvidersList(list);
        // Handle serviceCharge for each provider
        const serviceCharges = {};
        list.forEach((provider) => {
          if (data.data.providers[provider]?.serviceCharge !== undefined) {
            serviceCharges[provider] = data.data.providers[provider].serviceCharge || null;
          }
        });
  
        setServiceCharges(serviceCharges);
  
        if (data.data.providers["Paypal"]?.key1) {
          setPaypalKey1(data.data.providers["Paypal"].key1);
        }
        if (data.data.providers["Paypal"]?.key2) {
          setPaypalKey2(data.data.providers["Paypal"].key2);
        }
        if (data.data.providers["Stripe"]?.key1) {
          setStripeKey1(data.data.providers["Stripe"].key1);
        }
        if (data.data.providers["Stripe"]?.key2) {
          setStripeKey2(data.data.providers["Paypal"].key2);
        }
        if (data.data.providers["Bambora"]?.key1) {
          setBamboraKey1(data.data.providers["Bambora"].key1);
        }
        if (data.data.providers["Bambora"]?.key2) {
          setBamboraKey2(data.data.providers["Bambora"].key2);
        }
        // Set the third Bambora key if available
        if (data.data.providers["Bambora"]?.key3) {
          setBamboraKey3(data.data.providers["Bambora"].key3);
        }
      }
    } catch (error) {
      console.error("Error fetching provider keys:", error);
    }
  };

  const handleProviderChange = (e) => {
    const provider = e.target.value;
    setSelectedProvider(provider);
  };

  const handleCredentialChange = (e, provider, keyName) => {
    const value = e.target.value;
    if (keyName === 'serviceCharge') {
      const newValue = parseFloat(value);
      if (!isNaN(newValue)) {
        // Only set the service charge if the input is a valid number
        setServiceCharges((prevCharges) => ({
          ...prevCharges,
          [provider]: newValue,
        }));
      } else {
        // Handle the case where the input is not a valid number
        setServiceCharges((prevCharges) => ({
          ...prevCharges,
          [provider]: null, // Set serviceCharge to null if it's not a valid number
        }));
      }
    }
    else if (provider === "Stripe") {
      if (keyName === "key1") {
        setStripeKey1(value);
      } else if (keyName === "key2") {
        setStripeKey2(value);
      }
    }
   else if (provider === "Paypal") {
      if (keyName === "key1") {
        setPaypalKey1(value);
      } else if (keyName === "key2") {
        setPaypalKey2(value);
      }
    } else if (provider === "Bambora") {
      if (keyName === "key1") {
        setBamboraKey1(value);
      } else if (keyName === "key2") {
        setBamboraKey2(value);
      } else if (keyName === "key3") { 
        setBamboraKey3(value);
      }
    } 
    else {
      setProvidersData((prevData) => ({
        ...prevData,
        [provider]: {
          [keyName]: value,
        },
      }));
    }

    // Clear the validation error when typing credentials
    setValidationError("");
  };

  const handleAddProvider = () => {
    if (selectedProvider) {
      if (!providersList.includes(selectedProvider)) {
        setProvidersList([...providersList, selectedProvider]);
        setSelectedProvider("");
        setValidationError("");
        setServiceCharges((prevCharges) => ({
          ...prevCharges,
          [selectedProvider]: null, // Initialize serviceCharge with 0
        })); // Step 1
      } else {
        setValidationError("Provider is already added.");
      }
    } else {
      setValidationError("Please select a provider.");
    }
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = providersList.filter((p) => p !== provider);
    setProvidersList(updatedProviders);

    const updatedProvidersData = { ...providersData };
    delete updatedProvidersData[provider];
    setProvidersData(updatedProvidersData);
    if (provider === "Paypal") {
      setPaypalKey1("");
      setPaypalKey2("");
    }
    if (provider === "Bambora") {
      setBamboraKey1("");
      setBamboraKey2("");
      setBamboraKey3(""); // Clear the third key when removing Bambora
    }
  };


  const updatePlan = async () => {
    // Check if providers list is empty
    if (providersList.length === 0) {
      setValidationError("Please select at least one provider.");
      return;
    }

    // Check if credentials are filled for all selected providers
    const hasEmptyCredential = providersList.some((provider) => {
      if (provider === "Paypal") {
        return !paypalKey1 || !paypalKey2;
      }
      else if (provider === "Bambora") {
        return !bamboraKey1 || !bamboraKey2 || !bamboraKey3; // Check the third key
      }
      else if (provider === "Stripe") {
         return !stripeKey1 || !stripeKey2;
      }
      else {
        return !providersData[provider]?.key1 || providersData[provider]?.key1.trim() === "";
      }
    });

    
    if (hasEmptyCredential) {
      setValidationError("Please fill in the credentials for all selected providers.");
      return;
    }

    // Create an object to store all provider data
    const formattedData = {
      providers: {},
    };

    // Add all providers and their keys to the formattedData object
    providersList.forEach((provider) => {
      if (provider === "Paypal") {
        formattedData.providers[provider] = {
          key1: paypalKey1,
          key2: paypalKey2,
          serviceCharge: serviceCharges[provider] === null ? null : parseFloat(serviceCharges[provider]),
        };
      }else if (provider === "Bambora"){
        formattedData.providers[provider] = {
          key1: bamboraKey1,
          key2: bamboraKey2,
          key3: bamboraKey3, // Add the third key
          serviceCharge: serviceCharges[provider] === null ? null : parseFloat(serviceCharges[provider]),
        }
      }
     else if (provider === "Stripe") {
        formattedData.providers[provider] = {
          key1: stripeKey1,
          key2: stripeKey2,
          serviceCharge: serviceCharges[provider] === null ? null : parseFloat(serviceCharges[provider]),
        };
      }
       else {
        formattedData.providers[provider] = {
          key1: providersData[provider]?.key1 || "",
          serviceCharge: serviceCharges[provider] === null ? null : parseFloat(serviceCharges[provider]),
        };
      }
    });

    console.log("Formatted Data:", formattedData);
    try {
      const response = await api.put(`/updateUser`, formattedData);
      if (response.status === 200) {
        getProviderKeys();
        swalAlert({
          icon: "success",
          title: response.data.message,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      } else if (error.request) {
        console.error('No response received');
      } else {
        console.error('Error setting up the request', error.message);
      }
    }    
  };

  return (
    <div className="profile__form">
      <div className="profile__form">
        <div className="form-group">
          <label className="form-label">Payment Provider</label>
          <div className="">
            <div className="form-select-group">
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                  onChange={handleProviderChange}
                  value={selectedProvider}
                  sx={{ maxHeight: 200 }}
                  MenuProps={{ classes: { paper: "h-max-200" } }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Choose Provider
                  </MenuItem>
                  {/* <MenuItem value={"Google pay"}>Google pay</MenuItem> */}
                  <MenuItem value={"Paypal"}>Paypal</MenuItem>
                  {/* <MenuItem value={"Apple pay"}>Apple pay</MenuItem> */}
                  <MenuItem value={"Stripe"}>Stripe</MenuItem>
                  {/* <MenuItem value={"Bambora"}>Bambora</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            {validationError.length ? (
              <span style={{ color: "red" }}>{validationError}</span>
            ) : null}
            <div className="input-group mb-4">
              <p className="input-description">
                Log into your provider and get the api credentials for the plans
                you want to make active.
              </p>
            </div>
            <Button variant="outlined" onClick={handleAddProvider} disabled={!selectedProvider}>
              Add Provider
            </Button>
          </div>
        </div>

        <div className="form__flex">
          {providersList.map((provider) => (
            <div key={provider} className="form-group">
              <label className="form-label">{provider}</label>
              <input
              type="text"
              placeholder="Enter Service Charge In Percentage"
              value={serviceCharges[provider]}
              onChange={(e) => handleCredentialChange(e, provider, "serviceCharge")}
              className="form-input"
            />
            {provider == "Stripe" && (
              <div>
                <input
      type="text"
      placeholder="Enter Stripe Key 1"
      value={stripeKey1}
      onChange={(e) => handleCredentialChange(e, "Stripe", "key1")}
      className="form-input"
    />
    <input
      type="text"
      placeholder="Enter Stripe Key 2"
      value={stripeKey2}
      onChange={(e) => handleCredentialChange(e, "Stripe", "key2")}
      className="form-input"
    />
                </div>
            )}
              {provider === "Paypal" && (
                <div>
                  <input
                    type="text"
                    placeholder="Enter Paypal Client Id"
                    value={paypalKey1}
                    onChange={(e) => handleCredentialChange(e, "Paypal", "key1")}
                    className="form-input"
                  />
                  <input
                    type="text"
                    placeholder="Enter Paypal Client Secret key"
                    value={paypalKey2}
                    onChange={(e) => handleCredentialChange(e, "Paypal", "key2")}
                    className="form-input"
                  />
                </div>
              )}
              {provider === "Bambora" && (
                <div>
                  <input
                    type="text"
                    placeholder="Enter Bambora Merchant id"
                    value={bamboraKey1}
                    onChange={(e) => handleCredentialChange(e, "Bambora", "key1")}
                    className="form-input"
                  />
                  <input
                    type="text"
                    placeholder="Enter Bambora Api passcode"
                    value={bamboraKey2}
                    onChange={(e) => handleCredentialChange(e, "Bambora", "key2")}
                    className="form-input"
                  />
                  <input // Add the third key input field
                    type="text"
                    placeholder="Enter Bambora Hash key"
                    value={bamboraKey3}
                    onChange={(e) => handleCredentialChange(e, "Bambora", "key3")}
                    className="form-input"
                  />
                </div>
              )}
              {provider !== "Paypal" && provider !== "Bambora" && provider !== "Stripe" && (
                <input
                  type="text"
                  placeholder={`Enter Credentials for ${provider}`}
                  value={providersData[provider]?.key1 || ""}
                  onChange={(e) => handleCredentialChange(e, provider, "key1")}
                  className="form-input"
                />
              )}
              <button onClick={() => handleRemoveProvider(provider)} className="btn-delete">
                Remove
              </button>
            </div>
          ))}
        </div>
        <button type="submit" onClick={updatePlan} className="form-submit">
          Update
        </button>
        <Backdrop show={loading} />
      </div>
    </div>
  );
};

export default SetPlanDetails;
