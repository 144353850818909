import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, Chip, Checkbox, ListItemText } from "@mui/material";
import moment from "moment";
import { CrmService } from "../../../services";



const peopleOptions = ["John", "Jane", "Alice", "Bob", "Mary"];

const CalendarModal = ({ open, onClose, onSave, onDelete, formValues, setFormValues, selectedAppointment }) => {

  const [customerData, setCustomerData] = useState(null)

  // const handlePeopleChange = (event) => {
  //   setFormValues({ ...formValues, people: event.target.value });
  // };




  const handleSaveClick = () => {
    const newAppointment = {
      title: formValues.title,
      organizer: formValues.organizerId,
      location: formValues.location,
      start_date_time: new Date(formValues.start),
      end_date_time: new Date(formValues.end),
      attendee: formValues.peopleIds,
    };
    onSave(newAppointment);
  };
  useEffect(() => {
    (async () => {
      const data = await CrmService.getAllCustomers();
      setCustomerData(data?.data?.customers);
    })();
  }, []);


  const handlePeopleChange = (event) => {
    const selectedNames = event.target.value;
    const selectedPeople = customerData.filter((customer) => selectedNames.includes(customer.first_name));
    const selectedIds = selectedPeople.map((person) => person.id);

    setFormValues({
      ...formValues,
      people: selectedNames, // Display selected names
      peopleIds: selectedIds, // Store IDs for `to_name` in `newAppointment`
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>{selectedAppointment ? "Edit Appointment" : "Add Appointment"}</Typography>
        <TextField fullWidth label="Title" margin="normal" value={formValues.title} onChange={(e) => setFormValues({ ...formValues, title: e.target.value })} />
        <TextField
          fullWidth
          select
          label="Organizer"
          margin="normal"
          name="Organizer"
          value={formValues.Organizer}
          onChange={(e) => {
            const selectedCustomer = customerData.find(customer => customer.first_name === e.target.value);
            setFormValues({
              ...formValues,
              Organizer: e.target.value, // Store the selected name for display
              organizerId: selectedCustomer.id // Store the selected customer ID
            });
          }}
        >
          {customerData?.map((customer) => (
            <MenuItem key={customer.id} value={customer.first_name}>
              {customer.first_name}
            </MenuItem>
          ))}
        </TextField>


        <TextField fullWidth label="Location" margin="normal" value={formValues.location} onChange={(e) => setFormValues({ ...formValues, location: e.target.value })} />
        <TextField
          fullWidth type="datetime-local" label="Start Date & Time" margin="normal"
          value={moment(formValues.start).format("YYYY-MM-DDTHH:mm")}
          onChange={(e) => setFormValues({ ...formValues, start: new Date(e.target.value) })}
        />
        <TextField
          fullWidth type="datetime-local" label="End Date & Time" margin="normal"
          value={moment(formValues.end).format("YYYY-MM-DDTHH:mm")}
          onChange={(e) => setFormValues({ ...formValues, end: new Date(e.target.value) })}
        />
        <FormControl fullWidth margin="normal">
          <div id="people-select-label">People</div>
          <Select
            labelId="people-select-label"
            multiple
            value={formValues.people}
            onChange={handlePeopleChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
          >
            {customerData?.map((customer) => (
              <MenuItem key={customer.id} value={customer.first_name}>
                <Checkbox checked={formValues.people.indexOf(customer.first_name) > -1} />
                <ListItemText primary={customer.first_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" onClick={handleSaveClick}>Save</Button>
          {selectedAppointment && <Button variant="outlined" color="error" onClick={onDelete}>Delete</Button>}
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
