export const surveyConstants = {
  GETSURVEY_REQUEST: "SURVEY_GETSURVEY_REQUEST",
  GETSURVEY_RESET: "SURVEY_GETSURVEY_RESET",

  GETSURVEY_SUCCESS: "SURVEY_GETSURVEY_SUCCESS",
  GETSURVEY_FAILURE: "SURVEY_GETSURVEY_FAILURE",

  GETLEXICALSURVEY_REQUEST: "LEXICALSURVEY_GETLEXICALSURVEY_REQUEST",

  GETLEXICALSURVEY_SUCCESS: "LEXICALSURVEY_GETLEXICALSURVEY_SUCCESS",
  GETLEXICALSURVEY_FAILURE: "LEXICALSURVEY_GETLEXICALSURVEY_FAILURE",
  GETANSWERDATA_REQUEST: "RECOMMENDATIONS_GETANSWERDATA_REQUEST",
  GETANSWERDATA_SUCCESS: "RECOMMENDATIONS_GETANSWERDATA_SUCCESS",
  GETANSWERDATA_FAILURE: "RECOMMENDATIONS_GETANSWERDATA_FAILURE",
  CATEGORYINSIGHTS_REQUEST: "Tags_CATEGORYINSIGHTS_REQUEST",
  CATEGORYINSIGHTS_SUCCESS: "Tags_CATEGORYINSIGHTS_SUCCESS",
  CATEGORYINSIGHTS_FAILURE: "Tags_CATEGORYINSIGHTS_FAILURE",
  AIRECOMMENDATIONS_REQUEST: "Tags_AIRECOMMENDATIONS_REQUEST",
  AIRECOMMENDATIONS_SUCCESS: "Tags_AIRECOMMENDATIONS_SUCCESS",
  AIRECOMMENDATIONS_FAILURE: "Tags_AIRECOMMENDATIONS_FAILURE",
  AIRECOMMENDATIONSSTATUS_REQUEST: "Tags_AIRECOMMENDATIONSSTATUS_REQUEST",
  AIRECOMMENDATIONSPROCESSINGSTATUS: "Tags_AIRECOMMENDATIONSPROCESSINGSTATUS",
  AIRECOMMENDATIONSQUESTIONID: "Tags_AIRECOMMENDATIONSQUESTIONID",
  AIRECOMMENDATIONSSTATUS_SUCCESS: "Tags_AIRECOMMENDATIONSSTATUS_SUCCESS",
  AIRECOMMENDATIONSSTATUS_FAILURE: "Tags_AIRECOMMENDATIONSSTATUS_FAILURE",
  AIRECOMMENDATIONSPOST_REQUEST: "Tags_AIRECOMMENDATIONSPOST_REQUEST",
  AIRECOMMENDATIONSPOST_SUCCESS: "Tags_AIRECOMMENDATIONSPOST_SUCCESS",
  AIRECOMMENDATIONSPOST_FAILURE: "Tags_AIRECOMMENDATIONSPOST_FAILURE",

  CATEGORYINSIGHTSBYQUESTION_REQUEST: "Tags_CATEGORYINSIGHTSBYQUESTION_REQUEST",
  CATEGORYINSIGHTSBYQUESTION_SUCCESS: "Tags_CATEGORYINSIGHTSBYQUESTION_SUCCESS",
  CATEGORYINSIGHTSBYQUESTION_FAILURE: "Tags_CATEGORYINSIGHTSBYQUESTION_FAILURE",
  GETLONGTEXT_REQUEST: "SURVEY_GETLONGTEXT_REQUEST",
  GETLONGTEXT_SUCCESS: "SURVEY_GETLONGTEXT_SUCCESS",
  GETLONGTEXT_FAILURE: "SURVEY_GETLONGTEXT_FAILURE",

  GETCATEGORY_REQUEST: "Tags_GETCATEGORY_REQUEST",
  GETCATEGORY_SUCCESS: "Tags_GETCATEGORY_SUCCESS",
  GETCATEGORY_FAILURE: "Tags_ GETCATEGORY_FAILURE",

  GETSHAREALERT_REQUEST: "SURVEY_GETSHAREALERT_REQUEST",
  GETSHAREALERT_SUCCESS: "SURVEY_GETSHAREALERT_SUCCESS",
  GETSHAREALERT_FAILURE: "SURVEY_ GETSHAREALERT_FAILURE",

  GETHELPTYPETRIGGER: "SURVEY_GETHELPTYPETRIGGER",

  GETRESPONDALERT_REQUEST: "SURVEY_GETRESPONDALERT_REQUEST",
  GETRESPONDALERT_SUCCESS: "SURVEY_GETRESPONDALERT_SUCCESS",
  GETRESPONDALERT_FAILURE: "SURVEY_ GETRESPONDALERT_FAILURE",

  UPDATECOMBOCATEGORY_REQUEST: "Tags_UPDATECOMBOCATEGORY_REQUEST",
  UPDATECOMBOCATEGORY_SUCCESS: "Tags_UPDATECOMBOCATEGORY_SUCCESS",
  UPDATECOMBOCATEGORY_FAILURE: "Tags_ UPDATECOMBOCATEGORY_FAILURE",

  ADDCATEGORY_REQUEST: "Tags_ADDCATEGORY_REQUEST",
  ADDCATEGORY_SUCCESS: "Tags_ADDCATEGORY_SUCCESS",
  ADDCATEGORY_FAILURE: "Tags_ ADDCATEGORY_FAILURE",

  UPDATECATEGORY_REQUEST: "Tags_UPDATECATEGORY_REQUEST",
  UPDATECATEGORY_SUCCESS: "Tags_UPDATECATEGORY_SUCCESS",
  UPDATECATEGORY_FAILURE: "Tags_ UPDATECATEGORY_FAILURE",

  GETLONGTEXTAUDIENCE_REQUEST: "SURVEY_GETLONGTEXTAUDIENCE_REQUEST",
  GETLONGTEXTAUDIENCE_SUCCESS: "SURVEY_GETLONGTEXTAUDIENCE_SUCCESS",
  GETLONGTEXTAUDIENCE_FAILURE: "SURVEY_GETLONGTEXTAUDIENCE_FAILURE",
  GETLONGSURVEY_REQUEST: "SURVEY_GETLONGSURVEY_REQUEST",
  GETLONGSURVEY_SUCCESS: "SURVEY_GETLONGSURVEY_SUCCESS",
  GETLONGSURVEY_FAILURE: "SURVEY_GETLONGSURVEY_FAILURE",
  GETQUESTIONANALYSIS_REQUEST: "QUESTIONANALYSIS_GETQUESTIONANALYSIS_REQUEST",
  GETQUESTIONANALYSIS_SUCCESS: "QUESTIONANALYSIS_GETQUESTIONANALYSIS_SUCCESS",
  GETQUESTIONANALYSIS_FAILURE: "QUESTIONANALYSIS_GETQUESTIONANALYSIS_FAILURE",

  GETLEXICALQUESTIONANALYSIS_REQUEST:
    "LEXICALQUESTIONANALYSIS_GETLEXICALQUESTIONANALYSIS_REQUEST",
  GETLEXICALQUESTIONANALYSIS_SUCCESS:
    "LEXICALQUESTIONANALYSIS_GETLEXICALQUESTIONANALYSIS_SUCCESS",
  GETLEXICALQUESTIONANALYSIS_FAILURE:
    "LEXICALQUESTIONANALYSIS_GETLEXICALQUESTIONANALYSIS_FAILURE",

  GETQUESTIONALERTS_REQUEST: "QUESTIONALERTS_GETQUESTIONALERTS_REQUEST",
  GETQUESTIONALERTS_SUCCESS: "QUESTIONALERTS_GETQUESTIONALERTS_SUCCESS",
  GETQUESTIONALERTS_FAILURE: "QUESTIONALERTS_GETQUESTIONALERTS_FAILURE",

  GETRAWDATA_REQUEST: "RAWDATAANALYSIS_GETRAWDATA_REQUEST",
  GETRAWDATA_SUCCESS: "RAWDATAANALYSIS_GETRAWDATA_SUCCESS",
  GETRAWDATA_FAILURE: "RAWDATAANALYSIS_GETRAWDATA_FAILURE",

  GETALERTDATA_REQUEST: "ALERTDATAANALYSIS_GETALERTDATA_REQUEST",
  GETALERTDATA_SUCCESS: "ALERTDATAANALYSIS_GETALERTDATA_SUCCESS",
  GETALERTDATA_FAILURE: "ALERTDATAANALYSIS_GETALERTDATA_FAILURE",

  UPDATECUSTOMCATEGORIES_REQUEST: "Tags_UPDATECUSTOMCATEGORIES_REQUEST",
  UPDATECUSTOMCATEGORIES_SUCCESS: "Tags_UPDATECUSTOMCATEGORIES_SUCCESS",
  UPDATECUSTOMCATEGORIES_FAILURE: "Tags_UPDATECUSTOMCATEGORIES_FAILURE",

  GETCUSTOMCATEGORIES_REQUEST: "Tags_GETCUSTOMCATEGORIES_REQUEST",
  GETCUSTOMCATEGORIES_SUCCESS: "Tags_GETCUSTOMCATEGORIES_SUCCESS",
  GETCUSTOMCATEGORIES_FAILURE: "Tags_GETCUSTOMCATEGORIES_FAILURE",
  ACTIVE_MENU: "SURVEY_ACTIVE_MENU",
  Tags_QUESTIONID: "SURVEY_Tags_QUESTIONID",

  GETSURVEY_YEAR: "SURVEY_GETSURVEY_YEAR",
  GETSURVEY_MONTH: "SURVEY_GETSURVEY_MONTH",

  GETLEXICALSURVEY_YEAR: "LEXICALSURVEY_GETLEXICALSURVEY_YEAR",
  GETLEXICALSURVEY_MONTH: "LEXICALSURVEY_GETLEXICALSURVEY_MONTH",

  GETSURVEY_AIFILTER: "SURVEY_GETSURVEY_AIFILTER",
  GETAUDIENCE_AIFILTER: "SURVEY_GETAUDIENCE_AIFILTER",
  GETAUDIENCE_STATEFILTER: "SURVEY_GETAUDIENCE_STATEFILTER",

  GETLEXICALAUDIENCE_STATEFILTER: "SURVEY_GETLEXICALAUDIENCE_STATEFILTER",

  GETRAWDATAPAGE_FILTER: "SURVEY_GETRAWDATAPAGE_FILTER",
  GETALERTPAGE_FILTER: "SURVEY_GETALERTPAGE_FILTER",
  GETALERT_STATUS: "SURVEY_GETALERT_STATUS",

  GETLONGSURVEY_YEAR: "SURVEY_GETLONGSURVEY_YEAR",
  GETLONGSURVEY_MONTH: "SURVEY_GETLONGSURVEY_MONTH",

  GETSURVEYEFFECTIVENESS_YEAR: "SURVEY_GETSURVEYEFFECTIVENESS_YEAR",
  GETSURVEYEFFECTIVENESS_MONTH: "SURVEY_GETSURVEYEFFECTIVENESS_MONTH",

  GETSENTIMENTFILTER: "Tags_GETSENTIMENTFILTER",
  GETPROFANITYFILTER: "Tags_GETPROFANITYFILTER",
  GETSHORTANSWERFILTER: "Tags_GETSHORTANSWERFILTER",
  GETALLFILTER: "Tags_GETALLFILTER",

  RAWDATAQUESTION_ID: "SURVEY_GETRAWDATAQUESTION_ID",
  RAWDATAPROFANITY: "SURVEY_GETRAWDATAPROFANITY",
  RAWDATASENTIMENT: "SURVEY_GETRAWDATASENTIMENT",
  RAWDATASORTING: "SURVEY_GETRAWDATASORTING",
  RAWDATASEARCHING: "SURVEY_GETRAWDATASEARCHING",

  ALERTQUESTION_ID: "SURVEY_GETALERTQUESTION_ID",
  ALERTPROFANITY: "SURVEY_GETALERTPROFANITY",
  ALERTSENTIMENT: "SURVEY_GETALERTSENTIMENT",
  ALERTSORTING: "SURVEY_GETALERTSORTING",
  ALERTSEARCHING: "SURVEY_GETALERTSEARCHING",
  GETLEXICALQUESTION_ID: "QUESTIONANALYSIS_GETLEXICALQUESTION_ID",

  GETLONGQUESTION_ID: "QUESTIONANALYSIS_GETLONGQUESTION_ID",
  GETOTHERQUESTION_ID: "QUESTIONANALYSIS_GETOTHERQUESTION_ID",

  GETSURVEYEFFECTIVENESS_COUNTRYFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESS_COUNTRYFILTER",
  GETSURVEYEFFECTIVENESS_CHANNELFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESS_CHANNELFILTER",
  GETSURVEYEFFECTIVENESS_SOCIALGROUPFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESS_SOCIALGROUPFILTER",

  GETSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER",
  GETSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER",
  GETSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER:
    "SURVEY_GETSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER",

  GETLEXICALSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER:
    "LEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_COUNTRYFILTER",
  GETLEXICALSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER:
    "LEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_CHANNELFILTER",
  GETLEXICALSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER:
    "LEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_SOCIALGROUPFILTER",

  GETSURVEYEFFECTIVENESS_REQUEST: "GETSURVEY_GETSURVEYEFFECTIVENESS_REQUEST",
  GETSURVEYEFFECTIVENESS_SUCCESS: "GETSURVEY_GETSURVEYEFFECTIVENESS_SUCCESS",
  GETSURVEYEFFECTIVENESS_FAILURE: "GETSURVEY_GETSURVEYEFFECTIVENESS_FAILURE",

  GETSURVEYEFFECTIVENESSABANDONED_REQUEST:
    "GETSURVEY_GETSURVEYEFFECTIVENESSABANDONED_REQUEST",
  GETSURVEYEFFECTIVENESSABANDONED_SUCCESS:
    "GETSURVEY_GETSURVEYEFFECTIVENESSABANDONED_SUCCESS",
  GETSURVEYEFFECTIVENESSABANDONED_FAILURE:
    "GETSURVEY_GETSURVEYEFFECTIVENESSABANDONED_FAILURE",

  GETLEXICALSURVEYEFFECTIVENESSABANDONED_REQUEST:
    "GETLEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_REQUEST",
  GETLEXICALSURVEYEFFECTIVENESSABANDONED_SUCCESS:
    "GETLEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_SUCCESS",
  GETLEXICALSURVEYEFFECTIVENESSABANDONED_FAILURE:
    "GETLEXICALSURVEY_GETLEXICALSURVEYEFFECTIVENESSABANDONED_FAILURE",
  SHOWPROMPT: "SHOWPROMPT",
};
