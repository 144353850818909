import React, { useState } from 'react'
import Alert from '@mui/material/Alert';

export default function MuiAlert({show, error="something went wrong.", onClose, type="error", position="", style={} }) {
    const _style = {
        position: 'absolute',
        background: '#FFF',
        zIndex:5,
    };
    switch (position){
        case 'bottom-right':
            _style['bottom'] = "10px";
            _style['right'] = "10px";
            break;
        case 'bottom-left':
            _style['bottom'] = "10px";
            _style['left'] = "10px";
            break;
        case 'top-left':
            _style['top'] = "10px";
            _style['left'] = "10px";
            break;
        case 'top-right':
            _style['top'] = "10px";
            _style['right'] = "10px";
            break;
        case 'right':
            _style['right'] = "10px";
            break;
        case 'left':
            _style['left'] = "10px";
            break;
        case 'top':
            _style['top'] = "10px";
            break;
        case 'bottom':
            _style['bottom'] = "10px";
            break;
        default: break;
    }
    return (
        <div className='alertBox'>
            {show ? <Alert variant="outlined" sx={{..._style, ...style}} severity={type} onClose={onClose}>{error}</Alert> : null}
        </div>
    )
}