import { userConstants } from '../constants';

export function organization(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ORG_REQUEST:
            return {
                loading: true
            };

        case userConstants.GET_ORG_SUCCESS:
            return {
                //name: action.orga.data.name,
                users: action.organization.data.users
            };

        case userConstants.GET_ORG_FAILURE:
            return {
                error: action.error
            };

        default:
            return state;
    }
}