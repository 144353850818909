// timezones.js
export const timezones = [
    { value: "-12.0", label: "(UTC-12:00) International Date Line West" },
    { value: "-11.0", label: "(UTC-11:00) Coordinated Universal Time-11" },
    { value: "-10.0", label: "(UTC-10:00) Hawaii" },
    { value: "-9.5", label: "(UTC-09:30) Marquesas Islands" },
    { value: "-9.0", label: "(UTC-09:00) Alaska" },
    { value: "-8.0", label: "(UTC-08:00) Pacific Time (US & Canada)" },
    { value: "-7.0", label: "(UTC-07:00) Mountain Time (US & Canada)" },
    { value: "-6.0", label: "(UTC-06:00) Central Time (US & Canada)" },
    { value: "-5.0", label: "(UTC-05:00) Eastern Time (US & Canada)" },
    { value: "-4.0", label: "(UTC-04:00) Atlantic Time (Canada)" },
    { value: "-3.5", label: "(UTC-03:30) Newfoundland" },
    { value: "-3.0", label: "(UTC-03:00) Greenland" },
    { value: "-2.0", label: "(UTC-02:00) Mid-Atlantic" },
    { value: "-1.0", label: "(UTC-01:00) Azores" },
    { value: "0.0", label: "(UTC+00:00) Coordinated Universal Time" },
    { value: "1.0", label: "(UTC+01:00) Central European Time" },
    { value: "2.0", label: "(UTC+02:00) Eastern European Time" },
    { value: "3.0", label: "(UTC+03:00) Moscow Time" },
    { value: "3.5", label: "(UTC+03:30) Tehran" },
    { value: "4.0", label: "(UTC+04:00) Gulf Standard Time" },
    { value: "4.5", label: "(UTC+04:30) Kabul" },
    { value: "5.0", label: "(UTC+05:00) Pakistan Standard Time" },
    { value: "5.5", label: "(UTC+05:30) India Standard Time" },
    { value: "5.75", label: "(UTC+05:45) Nepal Time" },
    { value: "6.0", label: "(UTC+06:00) Bangladesh Standard Time" },
    { value: "6.5", label: "(UTC+06:30) Cocos Islands" },
    { value: "7.0", label: "(UTC+07:00) Indochina Time" },
    { value: "8.0", label: "(UTC+08:00) China Standard Time" },
    { value: "8.75", label: "(UTC+08:45) Australian Central Western Standard Time" },
    { value: "9.0", label: "(UTC+09:00) Japan Standard Time" },
    { value: "9.5", label: "(UTC+09:30) Australian Central Standard Time" },
    { value: "10.0", label: "(UTC+10:00) Australian Eastern Standard Time" },
    { value: "10.5", label: "(UTC+10:30) Lord Howe Island" },
    { value: "11.0", label: "(UTC+11:00) Solomon Islands" },
    { value: "12.0", label: "(UTC+12:00) New Zealand Standard Time" },
    { value: "12.75", label: "(UTC+12:45) Chatham Islands" },
    { value: "13.0", label: "(UTC+13:00) Tonga" },
    { value: "14.0", label: "(UTC+14:00) Line Islands" }
  ];
  