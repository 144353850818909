import { customCallConstant } from "../constants/customCallConstant";
import { CrmService } from "../services";
import { format } from "date-fns";

let callStartTime;
let callEndTime;

export const customCallActions = {
  registerSIP,
  makeCall,
  getAudioRef,
  setIncommingCall,
  acceptCallRequest,
  holdCallRequest,
  unHoldCallRequest,
  transferCallRequest,
  declineCallRequest,
  endCallRequest,
  muteCallRequest,
  StoreCallLogs,
  unMuteCallRequest,
  startCaptureVoice,
  stopCaptureVoice,
};

function registerSIP(ua) {
  return { type: customCallConstant.REGISTER_SIP, payload: ua };
}

function getAudioRef(audioRef) {
  return { type: customCallConstant.GET_AUDIO_REF, payload: audioRef };
}

function makeCall(number) {
  return (dispatch, getState) => {
    const state = getState();
    const { ua } = state.customCall;

    const options = {
      mediaConstraints: { audio: true, video: false },
    };

    if (ua) {
      ua.call(`sip:${number}@pbx.articence.com`, options);
      dispatch({ type: customCallConstant.MAKE_CALL, payload: number });
    }
  };
}

function setIncommingCall(call) {
  const callerNumber = call.remote_identity.uri.user;
  return {
    type: customCallConstant.SET_INCOMING_CALL,
    payload: { call, callerNumber },
  };
}

function muteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.mute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.MUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function unMuteCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      incomingCall.unmute({
        audio: true,
        video: false,
      });
      dispatch({ type: customCallConstant.UNMUTE_CALL });
    } else {
      console.error("No incoming call to mute");
    }
  };
}

function acceptCallRequest() {
  return { type: customCallConstant.ACCEPT_CALL };
}

function holdCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to hold.");
      return;
    }

    try {
      console.log("Attempting to hold the call...");
      incomingCall.hold({
        useUpdate: false,
      });
      dispatch({ type: customCallConstant.HOLD_CALL });
    } catch (error) {
      console.error("Error while holding the call:", error);
    }
  };
}

function unHoldCallRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const incomingCall = state?.customCall?.incomingCall;

    if (!incomingCall) {
      console.error("No active call to unhold.");
      return;
    }

    try {
      console.log("Attempting to unhold the call...");
      incomingCall.unhold({
        useUpdate: false,
      });

      dispatch({ type: customCallConstant.UNHOLD_CALL });
    } catch (error) {
      console.error("Error while unholding the call:", error);
    }
  };
}

function transferCallRequest(target) {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.refer(target);
        dispatch({ type: customCallConstant.TRANSFER_CALL });
      }
    } catch {
      console.error("Error while transferring the call");
    }
  };
}

function endCallRequest() {
  return { type: customCallConstant.END_CALL };
}

function declineCallRequest() {
  return (dispatch, getState) => {
    const state = getState();

    try {
      const { incomingCall } = state.customCall;
      if (incomingCall) {
        incomingCall.terminate();
        dispatch({ type: customCallConstant.DECLINE_CALL });
      }
    } catch (e){
      dispatch({ type: customCallConstant.DECLINE_CALL });
      console.error("Error while declining the call", e);
    }
  };
}

function startCaptureVoice() {
  console.log("voice capture started")
  return async (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;

    if (incomingCall) {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      const audioTracks = audioStream.getAudioTracks();
      console.log("track:" , audioTracks);

      audioTracks.forEach((track) => {
        console.log("Starting track:", track, audioStream);
        incomingCall.addTrack(track, audioStream);
      });

      dispatch({ type: customCallConstant.START_CAPTURE_VOICE });
    }
  };
}

function stopCaptureVoice() {
  
  return async (dispatch, getState) => {
    const state = getState();
    const { incomingCall } = state.customCall;
    
    if (incomingCall) {
      console.log("voice capture ended", incomingCall)
      // const audioTracks = incomingCall.getRemoteStreams()[0].getAudioTracks();

      // console.log("Stopping track:", audioTracks);
      // audioTracks.forEach((track) => {
      //   track.stop();
      // });

      dispatch({ type: customCallConstant.STOP_CAPTURE_VOICE });
    }
  };
}

function StoreCallLogs(Id) {
  // end timer

  return async (dispatch, getState) => {
    const state = getState();
    const { callerNumber, dialerNum } = state.customCall;

    const callStartTimeInc = new Date();

    const formattedStartTime = format(callStartTime, "yyyy-MM-dd HH:mm:ss");
    const formattedEndTime = format(callEndTime, "yyyy-MM-dd HH:mm:ss");
    const formattedStartTimeInc = format(
      callStartTimeInc,
      "yyyy-MM-dd HH:mm:ss"
    );

    const callDuration = (callEndTime - callStartTime) / 1000;

    const payload = {
      phone_number: dialerNum || callerNumber,
      call_start_time: formattedStartTime || formattedStartTimeInc,
      call_end_time: formattedEndTime,
      call_status: "completed",
      call_duration: callDuration,
      purpose: "Create a short introductory speech for a customer",
      prompt: "The customer wants to develop a Windows application",
      rules:
        "If customer is interested then ask when he wants to start the development.",
      recording: true,
    };

    try {
      const response = await CrmService.createCallLogs(payload, Id);
      return response;
    } catch (e) {
      console.log(e);
    }
  };
}
