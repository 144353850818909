import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import { Provider } from "react-redux";
import { store } from "./helpers";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { GoogleOAuthProvider } from "@react-oauth/google";

import * as serviceWorker from "./serviceWorker";

import {
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
} from "./voc/Common/Constants";
import SipConnector from "./components/SipConnector";

/* (() => {
  console.log('webpack worked')
})() */
if (process.env.NODE_ENV !== "development") console.log = () => {};
require("react-dom");
window.React2 = require("react");
//console.log(window.React1 === window.React2);
// const injectGA = () => {
//   if (typeof window == 'undefined') {
//     return;
//   }
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());
//
//   gtag('config', "UA-101749341-5");
//   console.log("google analytics called")
// };

ReactDOM.render(
  <React.Fragment>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <SipConnector />
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
        {/*Global site tag (gtag.js) - Google Analytics */}
        {/*    <script*/}
        {/*      responsive="true"*/}
        {/*      async*/}
        {/*      src="https://www.googletagmanager.com/gtag/js?id=UA-101749341-5"*/}
        {/*    />*/}
        {/*<script>{injectGA()}</script>*/}
      </Provider>
    </DndProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
