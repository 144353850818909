import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Rating,
  Stack,
} from "@mui/material";
import {
  Call,
  Email,
  Feedback,
  AccountBalanceWallet,
  LocationOn,
  Business,
  Schedule,
  StarRate,
  Timeline,
  ShoppingCart,
  AttachMoney,
  Warning,
  CheckCircle,
  Person,
  CalendarToday,
  MoreVert,
  Notifications,
  KeyboardArrowRight,
  StarBorder,
} from "@mui/icons-material";
import { MessageSquareQuote, StarHalf } from "lucide-react";

const CustomerDashboard = ({ customerInfo, engagementData }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  // Enhanced mock data
  const customer = {
    ...customerInfo,
    status: "Active",
    memberSince: "Jan 2023",
    tier: "Premium",
    location: "New York, USA",
    company: "Tech Corp Inc.",
    lastLogin: "2 hours ago",
    call: 32,
    email: 12,
    feedback: 11,
    payment: "$12,450",
  };

  const metrics = {
    recentTransactions: [
      {
        id: 1,
        date: "2024-11-08",
        type: "Subscription",
        amount: "$299",
        status: "Completed",
      },
      {
        id: 2,
        date: "2024-11-07",
        type: "Service Fee",
        amount: "$150",
        status: "Pending",
      },
      {
        id: 3,
        date: "2024-11-06",
        type: "Product Purchase",
        amount: "$899",
        status: "Completed",
      },
    ],
    supportTickets: [
      {
        id: "T-001",
        issue: "Technical Support",
        priority: "High",
        status: "Open",
      },
      {
        id: "T-002",
        issue: "Billing Query",
        priority: "Medium",
        status: "Resolved",
      },
      {
        id: "T-003",
        issue: "Feature Request",
        priority: "Low",
        status: "In Progress",
      },
    ],
  };

  const getStatusColor = (status) => {
    const colors = {
      Active: "#4caf50",
      Pending: "#ff9800",
      Completed: "#2196f3",
      "In Progress": "#fb8c00",
      Resolved: "#4caf50",
      Open: "#f44336",
    };
    return colors[status] || "#757575";
  };

  return (
    <Box>
      {/* Header Section */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 2,
          background: "linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)",
          color: "white",
        }}
      >
        <Grid container spacing={3} alignItems="start">
          <Grid item>
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "#fff",
                color: "#2196f3",
                fontSize: 36,
              }}
            >
              {customer.name.charAt(0)}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {customer.name}
              </Typography>
              <Chip
                label={customer.status}
                sx={{
                  bgcolor: getStatusColor(customer.status),
                  color: "white",
                }}
              />
              <Chip
                label={customer.tier}
                sx={{ bgcolor: "rgba(255,255,255,0.2)", color: "white" }}
              />
            </Box>
            <Grid container spacing={4}>
              <Grid item>
                <Typography variant="body1">
                  <Business sx={{ mr: 1, verticalAlign: "middle" }} />
                  {customer.company}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <LocationOn sx={{ mr: 1, verticalAlign: "middle" }} />
                  {customer.location}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Schedule sx={{ mr: 1, verticalAlign: "middle" }} />
                  Member since {customer.memberSince}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", gap: 1 }}>
              <IconButton color="inherit">
                <Notifications />
              </IconButton>
              <IconButton color="inherit">
                <MoreVert />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* User Summary */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <MessageSquareQuote />
              <Typography variant="h6" gutterBottom>
                Customer Summary
              </Typography>
            </Box>
            <Typography variant="body1" color="text.secondary">
              John Doe, a potential customer, has shown interest in purchasing a
              new laptop. He’s looking for a high-performance device that he can
              use for both work and casual gaming. His main requirements include
              a fast processor, at least 16GB of RAM, good battery life, and a
              dedicated graphics card. He has a budget range of $1,000 to $1,500
              and is considering a few different models that balance performance
              and price.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Customer Summary
            </Typography>
            <Typography variant="body1" color="text.secondary">
              John Doe, a potential customer, has shown interest in purchasing a
              new laptop. He’s looking for a high-performance device that he can
              use for both work and casual gaming. His main requirements include
              a fast processor, at least 16GB of RAM, good battery life, and a
              dedicated graphics card. He has a budget range of $1,000 to $1,500
              and is considering a few different models that balance performance
              and price.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Key Metrics */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {[
          {
            label: "Call",
            value: customer.call,
            icon: <Call />,
            color: "#2196f3",
          },
          {
            label: "Email",
            value: `${customer.email}`,
            icon: <Email />,
            color: "#f44336",
          },
          {
            label: "Feedback",
            value: `${customer.feedback}`,
            icon: <Feedback />,
            color: "#4caf50",
          },
          {
            label: "Payment",
            value: customer.payment,
            icon: <AttachMoney />,
            color: "#ff9800",
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={2}
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography color="textSecondary">{item.label}</Typography>
                <Box sx={{ color: item.color }}>{item.icon}</Box>
              </Box>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {item.value}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Tabs Section */}
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" />
          <Tab label="Transactions" />
          <Tab label="Support Tickets" />
          {/* <Tab label="Product Usage" /> */}
        </Tabs>

        {/* Overview Tab */}
        {selectedTab === 0 && (
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Recent Survey History
                    </Typography>
                    <List>
                      {[
                        {
                          id: 1,
                          type: "Product Satisfaction",
                          rating: 4.5,
                          date: "2024-11-08",
                          feedback:
                            "Great product, but could use more features",
                          sentiment: "Positive",
                        },
                        {
                          id: 2,
                          type: "Support Experience",
                          rating: 5.0,
                          date: "2024-11-05",
                          feedback: "Excellent customer service",
                          sentiment: "Positive",
                        },
                        {
                          id: 3,
                          type: "Feature Feedback",
                          rating: 3.5,
                          date: "2024-11-01",
                          feedback: "Dashboard needs improvement",
                          sentiment: "Neutral",
                        },
                      ].map((survey) => (
                        <ListItem
                          key={survey.id}
                          sx={{
                            borderLeft: 4,
                            borderColor:
                              survey.sentiment === "Positive"
                                ? "#4caf50"
                                : survey.sentiment === "Neutral"
                                ? "#ff9800"
                                : "#f44336",
                            mb: 1,
                            bgcolor: "rgba(0,0,0,0.02)",
                            borderRadius: 1,
                          }}
                        >
                          <ListItemIcon>
                            {survey.rating >= 4.5 ? (
                              <StarRate sx={{ color: "#4caf50" }} />
                            ) : survey.rating >= 3.5 ? (
                              <StarHalf sx={{ color: "#ff9800" }} />
                            ) : (
                              <StarBorder sx={{ color: "#f44336" }} />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box>
                                <Typography variant="body1">
                                  {survey.type}
                                </Typography>
                                <Rating
                                  value={survey.rating}
                                  precision={0.5}
                                  size="small"
                                  readOnly
                                />
                              </Box>
                            }
                            secondary={
                              <Box>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {survey.feedback}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {survey.date}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Overall Satisfaction Rate: 4.3/5
                      </Typography>
                      <Button
                        size="small"
                        endIcon={<KeyboardArrowRight />}
                        sx={{ textTransform: "none" }}
                      >
                        View All Surveys
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Communication History
                    </Typography>
                    <List>
                      {engagementData.calls.slice(0, 3).map((call, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <Call />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sales Call"
                            secondary="Duration: 15 minutes"
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Transactions Tab */}
        {selectedTab === 1 && (
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics.recentTransactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>{transaction.date}</TableCell>
                      <TableCell>{transaction.type}</TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>
                        <Chip
                          label={transaction.status}
                          size="small"
                          sx={{
                            bgcolor: getStatusColor(transaction.status),
                            color: "white",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Support Tickets Tab */}
        {selectedTab === 2 && (
          <Box sx={{ p: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ticket ID</TableCell>
                    <TableCell>Issue</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics.supportTickets.map((ticket) => (
                    <TableRow key={ticket.id}>
                      <TableCell>{ticket.id}</TableCell>
                      <TableCell>{ticket.issue}</TableCell>
                      <TableCell>
                        <Chip
                          label={ticket.priority}
                          size="small"
                          color={
                            ticket.priority === "High"
                              ? "error"
                              : ticket.priority === "Medium"
                              ? "warning"
                              : "success"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={ticket.status}
                          size="small"
                          sx={{
                            bgcolor: getStatusColor(ticket.status),
                            color: "white",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Product Usage Tab */}
        {selectedTab === 3 && (
          <Box sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Usage Statistics
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <ShoppingCart />
                        </ListItemIcon>
                        <ListItemText
                          primary="Active Products"
                          secondary={metrics.productUsage.activeProducts}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        <ListItemText
                          primary="Total Logins"
                          secondary={metrics.productUsage.totalLogins}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <Schedule />
                        </ListItemIcon>
                        <ListItemText
                          primary="Last Activity"
                          secondary={metrics.productUsage.lastActivity}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Usage Score
                    </Typography>
                    <Box sx={{ mt: 4, mb: 2 }}>
                      <LinearProgress
                        variant="determinate"
                        value={metrics.productUsage.usageScore}
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                    </Box>
                    <Typography variant="h4" align="center">
                      {metrics.productUsage.usageScore}%
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

// Example data
const exampleCustomerInfo = {
  name: "John Doe",
  id: "123456",
  email: "johndoe@example.com",
  phone: "+1 234 567 890",
};

const exampleEngagementData = {
  calls: Array(10).fill({}),
  emails: Array(8).fill({}),
  feedbacks: Array(5).fill({}),
  payments: Array(3).fill({}),
};

export default function CustomerDashboardContainer() {
  return (
    <CustomerDashboard
      customerInfo={exampleCustomerInfo}
      engagementData={exampleEngagementData}
    />
  );
}
