import React, { useState } from "react";
import cn from "classnames";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "../../common/controls/inputs/Input";

export default function DialogBox({
  open,
  handleClose,
  handleOk,
  config = {},
  children,
  dialogContentClass = "",
  rootClass = "",
}) {
  const {
    title,
    okTxt = "Ok",
    cancelTxt = "Cancel",
    showTwoBtn = false,
    dialogInputs = [],
    message,
  } = config || {};
  const [inputs, setInputs] = useState({});

  const onChange = (e) => {
    if (e?.target?.name) {
      const { name, value } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };

  const onOK = () => {
    handleOk(inputs);
    setInputs({});
  };

  const onClose = () => {
    setInputs({});
    handleClose();
  };

  return (
    <div>
      <Dialog disableEnforceFocus open={!!open} onClose={handleClose} className={rootClass}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent
          className={cn(dialogContentClass, "dialog-paper-content")}
        >
          {message && (
            <DialogContentText id="alert-dialog-slide-description">
              {" "}
              {message}
            </DialogContentText>
          )}
          {dialogInputs.map((e, i) => (
            <Input
              key={"input-" + i}
              config={{
                label: e.label,
                name: e.name,
                type: e.type || "text",
                className: "dialog-input input",
                labelClass: "dialog-input-label label",
              }}
              value={inputs[e.name]}
              onChange={onChange}
            />
          ))}
          {children}
        </DialogContent>

        {!children && (
          <DialogActions className="dialog-action">
            {showTwoBtn && (
              <Button
                onClick={handleClose}
                variant="outlined"
                className={"dialog-cancel-btn"}
              >
                {cancelTxt}
              </Button>
            )}
            <Button
              onClick={onOK}
              variant="contained"
              className={"dialog-ok-btn"}
            >
              {okTxt}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
