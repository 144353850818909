import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../common/controls/Button';
import Input from '../../common/controls/inputs/Input';
import { CommonWrapper, Wrapper } from '../Common/CommonWrapper';
import {INPUT_ERRORS} from '../Common/Constants';
import {checkPassword, clone} from '../Common/Utils';
import { history } from '../../helpers';
import { userActions } from '../../actions';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import './Signup.css';

const ProfileContent = ({ otherInfo: { oldInputs, setOldInputs } }) => {
    const location = useLocation(),
        [urlParam, setUrlParam] = useState({}),
        [inputs, setInputs] = useState(oldInputs),
        [submitted, setSubmitted] = useState(false),
        [error, setError] = useState({}),
        [readOnlyConfirmPwd, setReadOnly] = useState(true),
        dispatch = useDispatch();

    useEffect(() => {
        let { search = "" } = location;
        let splittedUrl = search.split("?");
        if (splittedUrl.length > 2) {
            let email = splittedUrl[1].split("=")[1];
            let code = splittedUrl[2].split("=")[1];
            setUrlParam({ email, code });
        }
    }, [location]);

    const handleChange = (e) => {
        let { name = "", value = "" } = e?.target || {};
        if((name === "firstname" || name === "lastname") && value.length === 1 ){
            value = value.toUpperCase();
        }
        setInputs(inputs => ({ ...inputs, [name]: value }));
    };

    const onBlur = (e) => {
        let { name = "", value = "" } = e?.target || {};
        setInputs(inputs => ({ ...inputs, [name]: value.trim() }));
    };

    const comparePwd = (e) => {
        let { name, value } = e?.target || {};
        let errObj = clone(error);
        if (name === 'password' && inputs.password.length) {
            let validPass = checkPassword(inputs.password);
            if(!validPass || !error.repassword){
               errObj[name] =  !validPass;
               setError(errObj);
            }
            //setReadOnly(!validPass);
            return {errObj};
        }

        if (name === 'repassword' && inputs.repassword.length && inputs.password.length) {
            let pwd = "";
            let err = false;
            if (inputs.password !== inputs.repassword) {
                err = true;
            } else {
                pwd = inputs.password;
            }
            setInputs(inputs => ({ ...inputs, ['confirmPwd']: pwd }));
            errObj[name] = err;
            setError(errObj);
            return {errObj, conPwd:pwd};
        }
        if(value.length < 1){
            errObj[name] = false;
            setError(errObj);
            return {errObj};
        }
    };

    const handleSubmit = (errObj, conPwd) => {
        const { firstname, lastname, confirmPwd, password, repassword } = inputs;
        const _err = errObj || error;
        const _confirmPwd = conPwd || confirmPwd;
        if (!_err.password && !_err.repassword && firstname !== "" && lastname !== "" && _confirmPwd !== "" && password !== "" && password === repassword && urlParam.code) {
            setOldInputs(inputs);
            dispatch(userActions.register({ firstname, lastname, email: urlParam.email, password: _confirmPwd, verification_code: urlParam.code }));
        } else {
            setSubmitted(true);
        }
    };

    const onKeyPress = (e) => {
        if (e && e.key === "Enter"){
            const {errObj, conPwd} = comparePwd(e) || {};
            handleSubmit(errObj, conPwd);
        }
    }

    return <div className={"signIntoArticencen"}>
        <div className={'header'}>
            <img src="images/logo.png" className={'profile-logo'} />
            <span className={'profile-heading'}> Sign into Articence </span>
        </div>
        <div className={'content'}>
            <div className="row">
                <div className="col-md-6">
                    <Input config={{ label: 'First Name', required:true, name: 'firstname', type: 'text', className: 'profileInput', labelClass: 'profileLabel', placeholder: 'Enter Your First Name' }} value={inputs.firstname} onChange={handleChange} onBlur={onBlur} onKeyPress={onKeyPress} isError={submitted && inputs.firstname == ""} error={INPUT_ERRORS.required} />
                </div>
                <div className="col-md-6">
                    <Input config={{ label: 'Last Name', required:true, name: 'lastname', type: 'text', className: 'profileInput', labelClass: 'profileLabel', placeholder: 'Enter Your Last Name' }} value={inputs.lastname} onChange={handleChange} onBlur={onBlur} onKeyPress={onKeyPress} isError={submitted && inputs.lastname == ""} error={INPUT_ERRORS.required} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Input config={{ label: 'Email', required:true, name: 'email', type: 'text', className: 'profileInput', labelClass: 'profileLabel', placeholder: 'Enter Your Email', readOnly: true }} value={urlParam.email} onBlur={onBlur} onKeyPress={onKeyPress}isError={submitted && !urlParam.email} error={INPUT_ERRORS.required} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Input config={{ label: 'Password', required:true, name: 'password', type: 'password', className: 'profileInput', labelClass: 'profileLabel', placeholder: 'Enter Your Password' }} value={inputs.password} onChange={handleChange} onBlur={comparePwd} onKeyPress={onKeyPress} isError={(submitted && inputs.password == "") || error.password} error={INPUT_ERRORS[(submitted && inputs.password == "") ? 'required' : 'invalidPassword']} />
                </div>
                <div className="col-md-6">
                <Input config={{ label: 'Re-Password', required:true, name: 'repassword', type: 'password', className: 'profileInput', labelClass: 'profileLabel', placeholder: 'Confirm Passwords' }} readOnly={false} value={inputs.repassword} onChange={handleChange} onBlur={comparePwd} onKeyPress={onKeyPress} isError={(submitted && inputs.repassword == "") || error.repassword} error={INPUT_ERRORS[(submitted && inputs.repassword == "") ? 'required' : 'unmatchPwd']} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Button text={'Sign In'} customCSS={'profileBtn profileSignBtn'} btnTextClass={'profileBtnText'} clickHandler={handleSubmit} />
                    <span className='profileTextContent'>Don’t have an account?&nbsp;<a onClick={()=>{history.push('/signup')}} style={{color: '#18A0FB'}}> Sign up for free!</a></span>
                </div>
            </div>
        </div>
     </div>
};

const Profile = () => {
    const { registering = false } = useSelector(state => state.registration),
        [oldInputs, setOldInputs] = useState({ firstname: '', lastname: '', username: '', password: '', repassword: '', confirmPwd: '' }),

        image = () => {
            return(
                <>  
                    <div className="bannerWrapper">
                        <div className="bannerWrapper--left">
                            <h1>Start your free trial today</h1>
                            {/* <h2>No credit card required</h2> */}
                            <p>The trial includes:</p>
                            <ul>
                            <li>
                                <DoneRoundedIcon />
                                Customized data based on your information
                            </li>
                            <li>
                                <DoneRoundedIcon />
                                Pre-configured dashboards and reports
                            </li>
                            <li>
                                <DoneRoundedIcon />
                                Customized templates and question banks
                            </li>
                            <li>
                                <DoneRoundedIcon />
                                Easy help and reference
                            </li>
                            <li>
                                <DoneRoundedIcon />
                                AI Analysis
                            </li>
                            </ul>
                        </div>
                        
                        <div className="bannerWrapper--right">
                            <img src="images/mainBannerFull.png" />
                        </div>
                        
                    </div>
                    {/* <img src="images/Untitled-2.gif" /> */}
                </>
            );
        },
        LeftWrapper = () => <Wrapper component={image} className={'leftWrapper'} />,
        RightWrapper = ({ oldInputs, setOldInputs }) => <Wrapper otherInfo={{ oldInputs, setOldInputs }} component={ProfileContent} className={'rightWrapper'} />,
        Content = () => <div className={"signInSinUpWrap"}><LeftWrapper /><RightWrapper oldInputs={oldInputs} setOldInputs={setOldInputs} /></div>;

    return <CommonWrapper component={Content} isLoader={registering} />
};

export default Profile;
