import {NavBar} from './NavBar';
import {HorNavBar} from './HorNavBar';
import './Wrapper.css'

import { Layout } from 'antd';

const { Content, Sider } = Layout;

function Wrapper(props) {

    return(
        <Layout className='layout' style={{
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    right: 0,
                  }}>
        {/*<NavBar></NavBar>*/}
        <Layout>
            <HorNavBar></HorNavBar>
            <Layout>
            <div  style={{ overflow: 'auto' }}>
                {props.children}
            </div>
            {/*<Sider>
                <h1>Hey</h1>
            </Sider> */}
            </Layout>
        </Layout>
    </Layout>
    );
}

export { Wrapper };