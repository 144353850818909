import { useEffect, useState, useRef } from "react";
import { crmActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Common/Utils";
import { history } from "../../../helpers";
import { Button } from "react-bootstrap";
import { Header, MainMenu } from "../../Common/Header";
import { parse } from "papaparse";
import { Modal } from "react-bootstrap";
import CommonTable from "../../Table/CommonCustomerTable";
import EmailModal from "./EmailModal";
import predefinedHeaders from "./CSVHeaderConstant";
import Compose from "./EmailSetupCustomerTable/Compose";
import HeaderListModal from "./HeaderListModal";

const CustomerTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const fileInputRef = useRef(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setfilteredCustomers] = useState([]);
  const [showDuplicateDataModal, setShowDuplicateDataModal] = useState(false);
  const [duplicateDataWithDbData, setDuplicateDataWithDbData] = useState([]);
  const [duplicateData, setDuplicateData] = useState([]);
  const [uniqueUploadedData, setuniqueUploadedData] = useState([]);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFileSelect = () => fileInputRef.current.click();

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Add styles for drag enter if needed
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Remove styles for drag leave if needed
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      console.log("Dropped file:", file);
      handleFileChange({ target: { files: event.dataTransfer.files } });
    }
  };

  useEffect(() => {
    const userDetails = getUser();
    dispatch(crmActions.getAllCustomers(userDetails.user_id));
  }, []);

  const customerData = useSelector((state) => state?.crmLogic);

  console.log(customerData, "customer-data")

  useEffect(() => {
    if (customerData) {
      const newTableData = customerData?.message?.data?.customers;
      setAllCustomers(newTableData);
      setfilteredCustomers(newTableData);
    } else {
      console.log("Data not yet available or path incorrect");
    }
  }, [customerData]);

  const handleCustomerAdd = () => {
    history.push("/add-customer");
  };

  const handleScheduledCallsTable = () => {
    history.push("/scheduled-calls");
  };

  function findObjectsWithDuplicateEmails(uploadedData, allCustomersData) {
    const duplicatesWithNewData = [];
    const duplicateData = [];
    const uniqueUploadedRecords = [];

    for (let i = 0; i < uploadedData.length; i++) {
      let obj = uploadedData[i];
      let flag = false;
      if (!obj.first_name || !obj.last_name || !obj.email || !obj.first_phone) {
        continue;
      }
      for (let j = 0; j < allCustomersData.length; j++) {
        let savedObj = allCustomersData[j];
        if (obj.email === savedObj.email) {
          let newObj = {
            email: obj.email,
            new_first_name: obj.first_name,
            new_last_name: obj.last_name,
            first_name: savedObj.first_name,
            last_name: savedObj.last_name,
          };
          duplicatesWithNewData.push(newObj);
          duplicateData.push(obj);
          flag = true;
        }
      }
      if (!flag) {
        uniqueUploadedRecords.push(obj);
      }
    }

    return {
      duplicatesWithNewData,
      duplicateData,
      uniqueUploadedRecords,
    };
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    parse(file, {
      complete: (result) => {
        setData(result.data);

        if (result.data.length > 0) {
          const csvHeaders = Object.keys(result.data[0]);
          console.log("CSV Headings:", csvHeaders);

          const missingHeaders = predefinedHeaders.filter(
            (header) => !csvHeaders.includes(header)
          );
          const extraHeaders = csvHeaders.filter(
            (header) => !predefinedHeaders.includes(header)
          );
          if (extraHeaders.length > 0) {
            alert(
              `CSV File contains mismatching header(s): "${extraHeaders.join(
                ", "
              )}"`
            );
            setShowHeaderModal(true);
            return;
          } else {
            alert(`Success! CSV file header matches well`);
          }
        }

        const duplicates = findObjectsWithDuplicateEmails(
          result.data,
          allCustomers
        );

        setDuplicateDataWithDbData([...duplicates.duplicatesWithNewData]);
        setDuplicateData([...duplicates.duplicateData]);
        setuniqueUploadedData([...duplicates.uniqueUploadedRecords]);

        setShowDuplicateDataModal(true);
      },
      header: true,
      skipEmptyLines: true,
    });
    setShow(false);
  };

  const handleDelete = (email) => {
    const newData = data.filter((obj) => obj.email !== email);
    const duplicates = duplicateDataWithDbData.filter(
      (obj) => obj.email !== email
    );
    setData(newData);
    setDuplicateDataWithDbData(duplicates);
  };

  const handleSubmit = () => {
    const userDetails = getUser();
    if (uniqueUploadedData.length > 0) {
      dispatch(
        crmActions.uploadCustomers(
          [...uniqueUploadedData],
          userDetails?.user_id
        )
      );
    }
    if (duplicateData.length > 0) {
      dispatch(
        crmActions.uploadDuplicateData([...duplicateData], userDetails?.user_id)
      );
    }
    setShowDuplicateDataModal(false);
  };

  const handleCancel = () => {
    setShowDuplicateDataModal(false);
  };

  return (
    <div>
      <Header />
      <MainMenu />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Contact List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            onClick={handleFileSelect}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: "2px dashed #007bff",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <p>Click or drag contact list to this area to upload</p>
            <p style={{ color: "#6c757d" }}>
              File should have headers and data rows in the following format:
            </p>
            <p style={{ color: "#6c757d" }}>
              Email, First Name, Last Name like Abc@demo.com, myfirstname,
              mylastname.
            </p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".csv"
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDuplicateDataModal}
        onHide={() => setShowDuplicateDataModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Uploaded Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: "18px", marginBottom: "8px" }}>
            Duplicate Data
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              overflowY: "scroll",
              maxHeight: "200px",
              minHeight: "100px",
              padding: "6px",
            }}
          >
            {duplicateDataWithDbData.map((data, index) => (
              <div
                style={{
                  border: "1px solid gray",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
                key={`${data.email}-${index}`}
              >
                <div>
                  <div>Email: {data.email}</div>
                  <div>
                    FirstName:{" "}
                    <span
                      style={{
                        color:
                          data.new_first_name !== data.first_name ? "red" : "",
                      }}
                    >
                      {data.new_first_name}
                    </span>
                  </div>
                  <div>
                    LastName:{" "}
                    <span
                      style={{
                        color:
                          data.new_last_name !== data.last_name ? "red" : "",
                      }}
                    >
                      {data.new_last_name}
                    </span>
                  </div>
                </div>
                <div>
                  <div>Email: {data.email}</div>
                  <div>FirstName: {data.first_name}</div>
                  <div>LastName: {data.last_name}</div>
                </div>

                <div>
                  <button
                    style={{
                      border: "1px solid gray",
                      padding: "2px 6px ",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                    onClick={() => handleDelete(data.email)}
                  >
                    🗑️
                  </button>
                </div>
              </div>
            ))}
            {duplicateDataWithDbData?.length === 0 && (
              <div>No duplicate Records are present</div>
            )}
          </div>
          <div style={{ fontSize: "18px", marginBlock: "10px" }}>New Data</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              overflowY: "scroll",
              maxHeight: "200px",
              minHeight: "200px",
              padding: "6px",
            }}
          >
            {uniqueUploadedData.map((data) => (
              <div
                style={{
                  border: "1px solid gray",
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
                key={data.email}
              >
                <div>Email: {data.email}</div>
                <div>FirstName: {data.first_name}</div>
                <div>LastName: {data.last_name}</div>
              </div>
            ))}
            {uniqueUploadedData?.length === 0 && (
              <div>No unique records are present</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              uniqueUploadedData.length === 0 &&
              duplicateDataWithDbData.length === 0
            }
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <HeaderListModal
        isVisible={showHeaderModal}
        onClose={() => setShowHeaderModal(false)}
      />

      <div
        style={{
          margin: "20px auto",
          border: "0px solid red",
        }}
      >
        <div style={{ border: "0px solid red", textAlign: "right" }}>
          <Button
            variant="primary"
            type="submit"
            onClick={handleScheduledCallsTable}
            size="sm"
            style={{ marginLeft: "20px" }}
          >
            Scheduled Calls
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleCustomerAdd}
            size="sm"
            style={{ marginLeft: "20px" }}
          >
            Add Customer
          </Button>
          <Button
            variant="primary"
            type="submit"
            style={{ marginLeft: "20px" }}
            onClick={handleShow}
            size="sm"
          >
            Upload CSV
          </Button>
        </div>
        <CommonTable
          dataSource={searchTerm.length ? filteredCustomers : allCustomers}
        />
      </div>
    </div>
  );
};

export default CustomerTable;
