import axios from "axios";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
  logout,
} from "../helpers";
import { commonService } from "./CommonService";
import { swalAlert } from "../voc/Common/SwalAlert";

const apiUrl = commonService.apiUrl;

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err?.response?.data?.error === "permission_denied") {
      swalAlert({
        icon: "error",
        title:
          err?.response?.data?.message.toString() + " Please Upgrade Your Plan!",
        showConfirmButton: true,
      }).then(() => {
        window.location.href = "/subscription";
      });

      return 0;
    }
    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axios.post(
            `${apiUrl}/token/refresh`,
            {},
            {
              headers: {
                Authorization: "Bearer " + getLocalRefreshToken(),
                "Content-Type": "application/json",
              },
            }
          );
          if (rs.data.msg === "Token has expired") {
            commonService.logout();
          }
          const { access_token } = rs.data;
          updateLocalAccessToken(access_token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
