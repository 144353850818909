import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Button,
  DropdownButton,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import AddModal from "../Common/AddModal";
import { useDispatch, useSelector } from "react-redux";
import { crmActions } from "../../actions";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { crmConstants } from "../../constants/crmConstants";

const ViewNotesTable = () => {
  // console.log(getAllNotesData, "<===getAllNotesData API data*##################");

  //hooks
  const dispatch = useDispatch();
  const { id } = useParams();
  const notesData = useSelector((state) => state?.crmLogic?.notes?.data); // {customerId:int,notes:[]}
  const type = useSelector((state) => state?.crmLogic?.type);
  console.log(type, " **************  type ");

  //states
  const [notes, setNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterQuery, setFilterQuery] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [status, setStatus] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentNoteId, setCurrentNoteId] = useState(null);

  useEffect(() => {
    dispatch(crmActions.getAllNotes(id));
  }, [id]);

  useEffect(() => {
    setNotes(notesData?.notes);
  }, [notesData]);

  useEffect(() => {
    if (
      type === crmConstants.ADD_NOTES_SUCCESS ||
      type === crmConstants.EDIT_NOTES_SUCCESS ||
      type === crmConstants.DELETE_NOTES_SUCCESS
    ) {
      dispatch(crmActions.getAllNotes(id));
    }
  }, [type]);

  //functions
  const sortData = (field) => {
    // setSortField(field);
    const sortedNotes = [...notes].sort((a, b) => {
      if (a[field] < b[field]) return sortOrder === "asc" ? -1 : 1;
      if (a[field] > b[field]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setNotes(sortedNotes);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filterData = (e) => {
    const query = e.target.value.toLowerCase();
    setFilterQuery(query);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const filteredNotes = notes?.filter((note) =>
    note?.content?.toLowerCase()?.includes(filterQuery)
  );

  // Pagination calculations
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = filteredNotes?.slice(firstItemIndex, lastItemIndex);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Create pagination items
  const paginationItems = [];
  for (
    let number = 1;
    number <= Math.ceil(filteredNotes?.length / itemsPerPage);
    number++
  ) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Functions for modal
  // const handleClose = () => setModalShow(false);
  const handleClose = () => {
    setModalShow(false);
    setIsEditMode(false);
    setNoteContent("");
    setStatus("");
    setCurrentNoteId(null);
  };
  
  const handleOpen = (note = null) => {
    console.log(note, "<====note note note");
    if (note) {
      setIsEditMode(true);
      setNoteContent(note.content);
      setStatus(note.status);
      setCurrentNoteId(note.note_id);
    } else {
      setIsEditMode(false);
      setNoteContent("");
      setStatus("");
    }
    setModalShow(true);
  };

  const handleSaveNote = () => {
    // console.log(noteContent, status, "<==gogo handleSaveNote");
    const addNotesData = {
      content: noteContent,
      status: status,
    };
    if (isEditMode && currentNoteId) {
      // console.log(isEditMode, "<==isEditMode");
      // console.log(currentNoteId, "<==currentNoteId");
      // console.log(addNotesData, "<==addNotesData")
      dispatch(crmActions.editNotes(id, currentNoteId, addNotesData));
    } else {
      dispatch(crmActions.addNotes(addNotesData, id));
    }
    // refetchGetAllNotesAPI();
    handleClose();
  };

  const deleteNote = (note) => {
    dispatch(crmActions.deleteNotes(notesData?.customer_id, note?.note_id));
  };

  return (
    <div style={{ margin: "50px auto", border: "0px solid red" }}>
      <Form.Group className="mb-3" controlId="filterQuery">
        {/* <Form.Label>Filter by content:</Form.Label> */}
        <Button
          variant="primary"
          size="sm"
          style={{ marginBottom: "8px" }}
          onClick={() => handleOpen()}
        >
          Add Notes
        </Button>
        <Form.Control
          type="text"
          placeholder="Enter content to filter"
          onChange={filterData}
        />
      </Form.Group>
      <div>
        <DropdownButton
          id="dropdown-basic-button"
          title="Sort By"
          className="mb-3"
        >
          <Dropdown.Item onClick={() => sortData("content")}>
            Content
          </Dropdown.Item>
          <Dropdown.Item onClick={() => sortData("date_posted")}>
            Date Posted
          </Dropdown.Item>
        </DropdownButton>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Note ID</th>
            <th>Content</th>
            <th>Date Posted</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((note) => (
            <tr key={note?.note_id}>
              <td>{note?.note_id}</td>
              <td>{note?.content}</td>
              <td>{note?.date_posted}</td>
              <td>{note?.status}</td>
              <td>
                <Button variant="secondary" onClick={() => handleOpen(note)}>
                  Edit Note
                </Button>
                <Tooltip title="Delete">
                  <IconButton
                    style={{
                      border: "1px solid gray",
                      borderRadius: "4px",
                      padding: "4px 6px",
                      margin: "0px 4px",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteNote(note)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>{paginationItems}</Pagination>
      <AddModal
        modalShow={modalShow}
        handleClose={handleClose}
        noteContent={noteContent}
        setNoteContent={setNoteContent}
        status={status}
        setStatus={setStatus}
        handleSaveNote={handleSaveNote}
        currentNoteId={currentNoteId}
      />
    </div>
  );
};

export default ViewNotesTable;
