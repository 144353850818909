import api from "./api";

async function getCountriesOptions() {
  const result = await api.get(`/voc_crm/countries`);
  return result;
}

async function getStateOptions(countryCode) {
  const result = await api.get(`/voc_crm/states?country_code=${countryCode}`);
  return result;
}

export const dropDownSerivce = {
  getCountriesOptions,
  getStateOptions,
};
