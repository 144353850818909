import React from 'react'
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextEditor from './TextEditor';

const Emailbody = ({ body, onChange, handleClose, sendEmail, setBody,signIn, isSchedule }) => {
  console.log(sendEmail, "<==sendEmail");
  return (
    <div className="form">
      <Grid container spacing={2}>
        {isSchedule && (
          <Grid item xs={12}>
            <label>Schedule at</label>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={body.schedule}
              variant="outlined"
              size="small"
              fullWidth
              name="schedule"
              type="datetime-local"
              onChange={onChange}
            />
             </Grid>
        )}

        {isSchedule && (
          <Grid item xs={12}>
            <label>Interval (in minutes)</label>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={body.interval}
              variant="outlined"
              size="small"
              fullWidth
              name="interval"
              type="number"
              onChange={onChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <label>Subject</label>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            value={body.subject}
            variant="outlined"
            size="small"
            fullWidth
            name="subject"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Content</label>
          {/* <TextField
                 hiddenLabel
                 id="filled-hidden-label-small"
                 value={body.content}
                 variant="outlined"
                 size="small"
                 fullWidth
                 name='content'
                 multiline = {true}
                 inputProps={{style:{height: 60}}}
                 onChange={onChange}
                 /> */}
          <div>
            {console.log("fdfdf", body.content)}
            <TextEditor
              editorValue={body.content}
              onChange={(newContent) => {
                console.log("sddsdsksdk", newContent);
                setBody((body) => ({ ...body, content: newContent }));
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div className="btnGroup">
        <button onClick={handleClose} className="btn btnCancel">
          Cancel
        </button>
        <button onClick={() => sendEmail()} className="btn btnSave">
          Send
        </button>
      </div>
    </div>
  );
}

export default Emailbody
