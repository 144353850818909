import React, { useEffect, useState, } from "react";

import {
  Table,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';

import { Box, Checkbox } from "@mui/material";

const ScheduledCallCustomerTable = ({ dataSource, handleCustomerAdd, handleShow }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataSource) {
      setData(dataSource);
    }
  }, [dataSource]);

  return (
    <div style={{ margin: "20px auto", border: "0px solid red" }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>Prompt</TableCell>
              <TableCell>Purpose</TableCell>
              <TableCell>Rules</TableCell>
              <TableCell>Call time</TableCell>
              <TableCell>Time Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.first_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.phone_number}
                </TableCell>
                <TableCell>{row.purpose}</TableCell>
                <TableCell>{row.prompt}</TableCell>
                <TableCell>{row.rules}</TableCell>
                <TableCell>{format(new Date(row.call_start_time), 'PPpp')}</TableCell>
                <TableCell>{row.timezone_offset}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ScheduledCallCustomerTable;
