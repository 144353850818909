import api from "./api";

async function createWorkspaceDetails(details) {
  const result = await api.post(`/createWorkspaceDetails`, details);

  localStorage.setItem(
    "surveyData",
    JSON.stringify({
      surveyName: result.data.survey_title,
      workspaceID: result.data.id,
      campaign_id: result.data.campaign_id,
    })
  );
  return result.data;
}

async function createQuestDesc(
  desc,
  userInfo,
  imageUrl,
  links,
  detail_id,
  styleData
) {
  const result = await api.post(`/createQuestDesc`, desc);
  return result;
}

async function getQuestionBank(experience_type, category, tags, keywords) {
  const result = await api.get(`/getQuestionByExperienceAndCategory`, {
    params: {
      experience_type: experience_type,
      sub_category: category,
      tags: tags,
      keywords: keywords,
    },
  });
  return result;
}

async function getWsDetail(detail_id) {
  const result = await api.get(`/workspaceDetail`, {
    params: {
      details_id: detail_id,
    },

    // headers: authHeader(),
  });
  // console.log("fdjfdjfd", result.data);
  return result;
}

async function getWsDetailAuth(detail_id) {
  const result = await api.get(`/workspaceDetailUsingAuth`, {
    params: {
      details_id: detail_id,
    },

    // headers: authHeader(),
  });
  // console.log("fdjfdjfd", result.data);
  return result;
}

async function getQsDescByDetailID(detail_id) {
  const result = await api.get(`/getQuestDesc`, {
    params: {
      detail_id: detail_id,
    },
  });
  return result;
}

async function addData(details) {
  const result = await api.post(`/addData`, details);
  return result;
}

const shareSurveyByEmail = async (details) => {
  const result = await api.post(`/shareSurveyViaEmail`, details);
  return result;
};

const oauth2TokenExchange = async (details) => {
  const result = await api.post(`/oauth2TokenExchange`, details);
  return result;
};

const bulkCreateEmailLinks = async (details) => {
  const result = await api.post(`/bulkCreateEmailLinks`, details);
  return result;
};

const deleteEmailLinks = async (details) => {
  const result = await api.post(`/deleteEmailLinks`, details);
  return result;
};

function getIPFromAmazon() {
  api
    .get("https://checkip.amazonaws.com/")
    .then((res) => res.text())
    // .then((data) =>
    //   console.log("\n\n\n----==============-------------->>>> ", data)
    // );
}

const getUserAddress = async () => {
  // to generate api key then singup https://dashboard.ipdata.co/sign-up.html
  const apiKey = "f5f4926549d40a0ce012c7f422cd6945d27c3a11e1c4ec2dc687be3e";
  const result = await api.get(`https://api.ipdata.co?api-key=${apiKey}`);
  return result?.data;
};

const createTrackEvents = async (details) => {
  const result = await api.post(`/createTrackEvents`, { params: details });
  return result;
};

const getTrackEventsLastVisitorID = async (params) => {
  const result = await api.get(`/getTrackEventsLastVisitorID`, {
    params: params,
  });
  return result;
};

const getWorkspace = async (params) => {
  const result = await api.get(
    `/workspace`,

    {
      params: params,
    }
  );
  // console.log("Dfddf", result);
  return result;
};

const getAllWsDetails = async (params) => {
  const result = await api.get(
    `/workspaceDetails`,

    {
      params: params,
    }
  );
  return result;
};

const createWorkspace = async (details) => {
  const result = await api.post(
    `/createWorkspace`,

    details
  );
  return result;
};

const deleteWorkspace = async (details) => {
  const result = await api.post(
    `/deleteWorkspace`,

    details
  );
  return result;
};

const updateWorkspace = async (details) => {
  const result = await api.put(
    `/updateWorkspace`,

    details
  );
  return result;
};

const duplicateWorkspace = async (details) => {
  const result = await api.post(
    `/copyWorkspace`,

    details
  );
  return result;
};

const deleteWorkspaceDetails = async (details) => {
  const result = await api.delete(
    `/deleteWorkspaceDetails`,

    { params: details }
  );
  return result;
};

const updateWorkspaceDetail = async (details) => {
  const result = await api.post(
    `/updateSurvey`,

    details
  );
  return result;
};

const setWsPriority = async (details) => {
  const result = await api.post(
    `/changeWorkspacePriority`,

    details
  );
  return result;
};

const moveSurveyToWorkspace = async (details) => {
  const result = await api.post(
    `/moveSurveyToNewWorkspace`,

    details
  );
  return result;
};

const copySurveyToWorkspace = async (details) => {
  const result = await api.post(
    `/copySurveyToNewWorkspace`,

    details
  );
  return result;
};

const createSurveyFromTemplate = async (details) => {
  const result = await api.post(
    `/createSurveyFromTemplate`,

    details
  );
  return result;
};

const getAllTypes = async (params) => {
  const result = await api.get(`/getAllTypes`, {
    params: params,
  });
  return result;
};

const addUploadedSurveyData = async (details) => {
  const result = await api.post(
    `/addUploadedSurveyData`,

    details
  );
  return result.data;
};

const getSummary = async (params) => {
  const result = await api.get(`/workspaceDetailSummary`, {
    params: params,
  });
  return result;
};

const surveyStop = async (params) => {
  const result = await api.post(`/updateWSDetailsStatus`, params);
  return result;
};

export const workspaceService = {
  createWorkspaceDetails,
  createQuestDesc,
  getQuestionBank,
  getWsDetail,
  getQsDescByDetailID,
  addData,
  shareSurveyByEmail,
  oauth2TokenExchange,
  bulkCreateEmailLinks,
  deleteEmailLinks,
  createTrackEvents,
  getTrackEventsLastVisitorID,
  getUserAddress,
  getWorkspace,
  getAllWsDetails,
  createWorkspace,
  deleteWorkspace,
  updateWorkspace,
  duplicateWorkspace,
  deleteWorkspaceDetails,
  updateWorkspaceDetail,
  setWsPriority,
  getWsDetailAuth,
  moveSurveyToWorkspace,
  copySurveyToWorkspace,
  createSurveyFromTemplate,
  getAllTypes,
  addUploadedSurveyData,
  getSummary,
  surveyStop
};
