import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { base64Image } from '../Common/Utils';
import Avatar from 'react-avatar-edit'
import { userActions } from "../../actions";
import { useDispatch } from "react-redux";
import Backdrop from "../Common/Backdrop";
import './Settings.scss';


const ProfilePic = ({setTrigger}) => {
    const [img, setImg] = useState('');
    const [pic, setPic] = useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onChangeHandle = (e) => {
        if (e?.target?.files?.length) {
            const file = e.target.files[0];
            if (file.type.includes("jpeg") || file.type.includes("png") || file.type.includes("jpg")) {
                setErr(false);
                base64Image(file, (result) => {
                    setImg(result);
                    setPic(result);
                });
            } else {
                setErr("Uploaded file should be jpg or png");
            }
        }
    };

    const save = () => {
        if(pic && pic.length){
            setImg("");
            setLoading(true);
            dispatch(userActions.updateProfilePic({profile_pic: pic})).then(res=>{
                setLoading(false);
                setImg("");
                setPic("");
            }, error => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e?.length ? e : "Something went wrong");
                setLoading(false);
            });
        }else{
            setErr("Choose your profile picture");
        }
    };

    const onClick = (event) => {
        event.currentTarget.value = null;
    };

    return <div className='upload__form'>
        <div className={"cancel"} onClick={()=>setTrigger(false)}><CloseIcon/></div>
        <div className="upload__form--title">Upload your photo</div>
        <div className="fileUploadInner">
            <div className="fileUpload">
                <input type="file" name="profile_pic" onChange={onChangeHandle} accept={'image/x-png, image/jpeg'} onClick={onClick}/>
                <img src={pic.length ? pic : (origin + "/images/SVG/upload.svg")} alt="upload icon"
                     className={pic.length ? 'uploadedImg' : 'staticImg'}/>
                <div className="dotted-line"></div>
            </div>

            <div className="fileText">
                <h4>Choose your file or drag here</h4>
                <span>Size limit: 64px*64px</span>
            </div>

        </div>
        {img.length ? <div className={'avatar'}>
            <Avatar
                height={295}
                src={img}
                onCrop={(e)=> setPic(e)}
                onClose={() => setImg("")}
                exportQuality = {1}
                exportSize = {100}
            />
        </div> : null}

        { err.length ? <p style={{color:'red', marginTop:20}}>{err}</p> : null }
        <button className="form-submit" onClick={save} style={err.length ? {marginTop:10}: {}}>Save</button>

        <Backdrop show={loading} />
    </div>
};

export default ProfilePic;
