import api from "./api";

export const surveyService = {
  getSurveyDetails,
  getAudienceDetails,
  getSurveyQuestions,
  showAllLongTextSummary,
  getQuestionLevelRawData,
  showAudienceLongTextSummary,
  getSurveyEffectivenessData,
  getCategorizationInsights,
  getCategorizationInsightsByQuestion,
  getAnswerData,
  getRecommendedAi,
  postAiRecommended,
  getCategoriesApi,
  addCategoriesApi,
  updateCategoriesApi,
  updateCustomCategoriesApi,
  getCustomCategories,
  updateComboCategoriesApi,
  getAiRecommendationsStatus,
  getSurveyEffectivenessAbandonedData,
  getAlertQuestions,
  getQuestionLevelAlertData,
  getShareAlertApi,
  getLexicalSurveyDetails,
  getLexicalSurveyQuestions,
  getRespondAlertApi,
  getLexicalAudienceDetails,
  getLexicalSurveyEffectivenessAbandonedData,
};

async function getSurveyDetails(questionId, surveyYear, surveyMonth, surveyId) {
  const result = await api.get(
    `/questionLevelAnalysis?qus_id=${questionId}&survey_id=${surveyId}&graph_filter_year=${surveyYear}&graph_filter_month=${surveyMonth}`
  );

  return result;
}

async function getLexicalSurveyDetails(
  questionId,
  surveyYear,
  surveyMonth,
  surveyId
) {
  const result = await api.get(
    `/wordyQuestionDateAnalysis?qus_id=${questionId}&survey_id=${surveyId}&graph_filter_year=${surveyYear}&graph_filter_month=${surveyMonth}`
  );

  return result;
}

async function getCategoriesApi(questionId, surveyId) {
  const result = await api.get(
    `/getCategories?detail_id=${surveyId}&qus_id=${questionId}`
  );

  return result;
}

async function getShareAlertApi(alertId, type) {
  const result = await api.post(`/markAlertAsRead`, {
    alert_ids: alertId,
    type: type,
  });

  return result;
}

async function getRespondAlertApi(to, shareType, alertId, type, title) {
  const result = await api.post(`/shareAlert`, {
    to: to,
    share_via: shareType,
    body: title,
    alert_ids: alertId,
    type: type,
  });

  return result;
}

async function getAiRecommendationsStatus(questionId, surveyId) {
  const result = await api.get(
    `/getRecommended?survey_id=${surveyId}&qus_id=${questionId}`
  );

  return result;
}

async function getCustomCategories(questionId, surveyId) {
  const result = await api.get(
    `/getCustomCategories?detail_id=${surveyId}&qus_id=${questionId}`
  );

  return result;
}

async function updateCategoriesApi(params) {
  const result = await api.post(
    `/updateCategories`,

    params
  );

  return result;
}

async function updateComboCategoriesApi(params) {
  const result = await api.post(`/updateComboCategories`, params);

  return result;
}

async function updateCustomCategoriesApi(params) {
  const result = await api.post(`/createCustomizeCategories`, params);

  return result;
}

async function addCategoriesApi(params) {
  const result = await api.post(`/addCategories`, params);

  return result;
}
async function getAnswerData(
  questionId,
  surveyId,
  page,
  profanity,
  sentiment,
  short_question,
  search,
  categoryFilter
) {
  const result = await api.post(`/getAnswerData`, {
    detail_id: surveyId,
    qus_id: questionId,
    page_num: page,
    page_size: 9,
    profanity: profanity,
    sentiment: sentiment,
    short_answer: short_question,
    search_text: search,
    category_filter: categoryFilter,
  });

  return result;
}

async function getSurveyEffectivenessData(
  surveyId,
  channel,
  country,
  social,
  surveyEffectivenessYear,
  surveyEffectivenessMonth,
  getAbandoned
) {
  const result = await api.get(
    `/surveyEffectivenessAnalysis?survey_id=${surveyId}&channel=${channel}&location=${country}&social_group=${social}&get_abandoned=${getAbandoned}&graph_filter_year=${surveyEffectivenessYear}&graph_filter_month=${surveyEffectivenessMonth}`
  );

  return result;
}

async function getSurveyEffectivenessAbandonedData(
  surveyId,
  channel,
  country,
  social,
  getAbandoned
) {
  const result = await api.get(
    `/surveyEffectivenessAnalysis?survey_id=${surveyId}&channel=${channel}&location=${country}&social_group=${social}&get_abandoned=${getAbandoned}`
  );

  return result;
}

async function getLexicalSurveyEffectivenessAbandonedData(
  surveyId,
  channel,
  country,
  social,
  questionId
) {
  const result = await api.get(
    `/wordyQuestionEffectiveness?survey_id=${surveyId}&channel=${channel}&location=${country}&social_group=${social}&qus_id=${questionId}`
  );

  return result;
}

async function getRecommendedAi(surveyId, questionId, search, pageNumber) {
  const result = await api.get(
    `/getAllRecommendedCat?detail_id=${surveyId}&qus_id=${questionId}&search_text=${search}&page_num=${pageNumber}&page_size=10`
  );

  return result;
}
async function postAiRecommended(
  surveyId,
  questionId,
  refineCategories,
  discoverRelations,
  customList,
  profanity,
  short_question,
  sentiment,
  redo
) {
  const result = await api.post(`/getRecommended`, {
    params: {
      detail_id: surveyId,
      redo: redo,
      qus_id: questionId,
      relations: discoverRelations,
      cats: refineCategories,
      custom: "N",
      customlist: {
        loading: true,
        items: customList,
      },
      experience: {},
    },
    filters: {
      profanity: profanity,
      sentiment: sentiment,
      short_answer: short_question,
    },
  });

  return result;
}
async function showAllLongTextSummary(questionId, aiFilter, surveyId) {
  const result = await api.get(
    `/showAILongTextSummary?survey_id=${surveyId}&qus_id=${questionId}&number_of_days=${aiFilter}`
  );

  return result;
}
async function showAudienceLongTextSummary(
  questionId,
  aiFilter,
  audienceStateFilter,
  surveyId,
  audienceType,
  surveyStatus
) {
  const result = await api.get(
    `/showAILongTextSummary?survey_id=${surveyId}&qus_id=${questionId}&number_of_days=${aiFilter}&state=${audienceStateFilter}&response_by=${audienceType}&survey_status=${surveyStatus}`
  );

  return result;
}

async function getCategorizationInsights(surveyId) {
  const result = await api.get(`/categoryInsights?survey_id=${surveyId}`);

  return result;
}
async function getCategorizationInsightsByQuestion(surveyId, questionId) {
  const result = await api.get(
    `/categoryInsightsByQuestion?survey_id=${surveyId}&qus_id=${questionId}`
  );

  return result;
}
async function getAudienceDetails(
  audienceType,
  questionId,
  surveyStatus,
  surveyId
) {
  const result = await api.get(
    `/questionLevelAudienceAnalysis?qus_id=${questionId}&survey_id=${surveyId}&response_by=${audienceType}&survey_status=${surveyStatus}`
  );

  return result;
}

async function getLexicalAudienceDetails(
  audienceType,
  questionId,
  surveyStatus,
  surveyId
) {
  const result = await api.get(
    `/wordyQuestionAudienceAnalysis?qus_id=${questionId}&survey_id=${surveyId}&response_by=${audienceType}&survey_status=${surveyStatus}`
  );

  return result;
}

async function getSurveyQuestions(surveyId) {
  const result = api.get(`/getAllQuestionsForAnalysis?survey_id=${surveyId}`);

  return result;
}
async function getLexicalSurveyQuestions(surveyId) {
  const result = api.get(
    `/getWordyQuestionForAnalysis?survey_id=${surveyId}`
  );

  return result;
}
async function getAlertQuestions(surveyId) {
  const result = api.get(`/getAllQuestionsForAlerts?survey_id=${surveyId}`);

  return result;
}

async function getQuestionLevelRawData(
  rawDataQuestionId,
  rawDataProfanity,
  rawDataSentiments,
  rawDataSearching,
  rawDataSorting,
  page,
  surveyId
) {
  const result = await api.get(
    `/questionLevelRawDataAnalysis?survey_id=${surveyId}&qus_id=${rawDataQuestionId}&profanity=${rawDataProfanity}&sentiment=${rawDataSentiments}&search_text=${rawDataSearching}&sort_by=${rawDataSorting}&page_number=${page}&page_size=9`
  );

  return result;
}

async function getQuestionLevelAlertData(
  alertQuestionId,
  alertProfanity,
  alertSentiments,
  alertSearching,
  alertSorting,
  page,
  surveyId,
  alertStatus
) {
  const result = await api.get(
    `/showalerts?survey_id=${surveyId}&qus_id=${alertQuestionId}&profanity=${alertProfanity}&sentiment=${alertSentiments}&status=${alertStatus}&search_text=${alertSearching}&sort_by=${alertSorting}&page_number=${page}&page_size=9`
  );

  return result;
}
