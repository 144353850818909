import React, { Component, useState, useEffect } from 'react'
import 'antd/dist/antd.css';
import { Menu, Select, Avatar, Layout, Col, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { userActions } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";


const { Header, Content, Footer, Sider } = Layout;

function HorNavBar() {

    const optConst = {
        NONE: 0,
        ACCOUNT: 1,
        LOGOUT: 2
    };

    const [option, selOpt] = useState(optConst.NONE);
    const user = useSelector(state => state.authentication.user);
    useEffect(() => {
        selOpt(option => optConst.NONE);
    }, []);

    const dispatch = useDispatch();
    //function viewAccount() {
    //window.location.href = '/account';
    //}

    function logout() {
        dispatch(userActions.logout());
        //window.location.href = '/login';
    }

    return (
        <Header id='topBar' className=''
            style={{
                /*backgroundColor: "rgba(0, 21, 41, 0.9)",
                minHeight: 48, */
                //position: "fixed",
                //top: 0,
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                lineHeight: "unset",
                height: "65px"
            }}
        >
            <Col className='col-md-3 col-sm-4' >
                <Row>
                    <img src="/articence_logosmall.png" height={50} ></img>

                    <Menu expandIcon theme="dark" defaultSelectedKeys={['1']} mode="horizontal" style={{ height: '50px', borderRight: 0 }}>
                        <Menu.Item id="navbar-dashboard" key='1' ><Link to="/">Dashboard</Link></Menu.Item>
                        <Menu.Item id="navbar-resumes" key='2' style={{ display: 'none' }} ><Link to="/resumes" >Resumes</Link></Menu.Item>
                        <Menu.Item id="navbar-jobs" key='3' style={{ display: 'none' }} ><Link to="/jobs">Jobs</Link></Menu.Item>
                        <Menu.Item id="navbar-decode" key='4' style={{ display: 'none' }} ><Link to="/decode">Decode</Link></Menu.Item>
                        <Menu.Item id="navbar-search" key='5' style={{ display: 'none' }} ><Link to="/search">Search</Link></Menu.Item>
                        <Menu.Item id="navbar-reports" key='6' style={{ display: 'none' }} ><Link to="/reports">Reports</Link></Menu.Item>
                        <Menu.Item id="navbar-account" key='7' ><Link to="/account">Account</Link></Menu.Item>
                        {/*<Menu.Item id="navbar-help" key='10' ><Link to="/help">Help</Link></Menu.Item>*/}
                        {/*<Menu.Item id="navbar-faqs" key='8' icon={<ControlOutlined />}><Link to="/faqs">Faqs</Link></Menu.Item>*/}
                        <Menu.Item id="navbar-contactUs" key='9' ><Link to='/contactUs'>Contact Us</Link></Menu.Item>
                    </Menu>
                    {/* </Col>
                    <Col  className='col-md-2 col-sm-2' > */}
                </Row>
            </Col>
            <Col className='col-md-4 col-sm-4 d-none d-md-flex align-items-center justify-content-end' >
                <p className="header-title">Analyze your surveys the right way.</p>
            </Col>
            <Col className='col-md-5 col-sm-6 d-flex align-items-center justify-content-end'>
                <div className='d-none d-sm-flex'>
                    {/*<Avatar size={50} href='/account' src={window.sessionStorage.getItem("ImageUrl")} style={{ flex: "0 0 auto", cursor: "pointer" }} />*/}
                    {/*<Avatar size={50} href='/account' src={user} style={{ flex: "0 0 auto", cursor: "pointer" ,background:"white",text:"red"}} />*/}

                    <div className="header-user-welcome"> {user.message}</div>
                </div>
                <div>
                    <a href='/login' className='signout-text' onClick={logout}>Sign Out</a>
                    {/*<a href='/updateUser' style={{ color: "rgba(255, 255, 255, 0.65)", margin: "0 12px" }}>Change User</a>*/}
                </div>
            </Col>


        </Header>
    );
}
export { HorNavBar };