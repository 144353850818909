import React from 'react';
import Dialogbox from "./DialogBox";
import "./commonStyle.scss"
const Confirmation = ({show, handler, message="Are you sure?", singleBtn=false, btnLabel="Yes", cancelBtnLbl="No" }) => {
    return <Dialogbox open={show} rootClass={"confirmationDialog"} handleClose={()=>handler(false)}>
        <div className={"form"}>
            <span style={{fontSize:'18px'}}>{message}</span>
            <div className="btnGroup">
                {!singleBtn && <button onClick={()=>handler(false)} className="btn btnCancel">{cancelBtnLbl}</button>}
                <button onClick={()=>handler(true)} className="btn btnSave">{btnLabel}</button>
            </div>
        </div>
    </Dialogbox>
};

export default Confirmation;
