const predefinedHeaders = [
    "account_owner",
    "account_team",
    "annual_revenue",
    "city",
    "company",
    "company_address",
    "company_city",
    "company_country",
    "company_linkedin_url",
    "company_name_for_emails",
    "company_phone",
    "company_state",
    "contact_owner",
    "corporate_phone",
    "country",
    "demoed",
    "departments",
    "email",
    "email_bounced",
    "email_confidence",
    "email_open",
    "email_sent",
    "email_status",
    "facebook_url",
    "first_name",
    "first_phone",
    "home_phone",
    "industry",
    "keywords",
    "last_contacted",
    "last_name",
    "last_raised_at",
    "latest_funding",
    "latest_funding_amount",
    "lists",
    "mobile_phone",
    "new_or_existing_customer",
    "number_of_employees",
    "number_of_retail_locations",
    "other_phone",
    "person_linkedin_url",
    "replied",
    "seniority",
    "seo_description",
    "source_account_id",
    "source_contact_id",
    "stage",
    "state",
    "team_id",
    "technologies",
    "timezone",
    "title",
    "total_funding",
    "twitter_url",
    "user_id",
    "user_managed",
    "website",
    "work_direct_phone"
  ];
  
  export default predefinedHeaders;
  