import { workspaceConstants } from "../constants";

export function quesDesc(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.CREATE_QUEST_DESC_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.CREATE_QUEST_DESC_SUCCESS:
      return {
        quest_desc_id: action.result.quest_desc_id,
        loading: false,
      };
    case workspaceConstants.CREATE_QUEST_DESC_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function questionBank(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.GET_QUESTION_BANK_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.GET_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        questions: action?.detail?.data?.questions,
      };
    case workspaceConstants.GET_QUESTION_BANK_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function wsDetails(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.GET_WS_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.GET_WS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action?.result,
      };
    case workspaceConstants.GET_QUESTION_BANK_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function wsDetailsAuth(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.GET_WS_DETAIL_WITH_AUTH_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.GET_WS_DETAIL_WITH_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action?.result,
      };
    case workspaceConstants.GET_WS_DETAIL_WITH_AUTH_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function addData(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.ADD_DATA_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.ADD_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        payres : action?.res,
      };
    case workspaceConstants.ADD_DATA_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function bulkEmailCreate(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.BULK_CREATE_EMAIL_LINKS_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.BULK_CREATE_EMAIL_LINKS_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case workspaceConstants.BULK_CREATE_EMAIL_LINKS_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function shareSurveyByEmail(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.SHARE_SURVEY_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.SHARE_SURVEY_EMAIL_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case workspaceConstants.SHARE_SURVEY_EMAIL_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function allWsDetails(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.ALL_WORKSPACE_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.ALL_WORKSPACE_DETAILS_SUCCESS:
      return {
        loading: false,
        res: action?.result?.data,
      };
    case workspaceConstants.ALL_WORKSPACE_DETAILS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function workspaceByTeam(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.WS_BY_TEAM_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.WS_BY_TEAM_SUCCESS:
      return {
        loading: false,
        res: action?.result?.data,
      };
    case workspaceConstants.WS_BY_TEAM_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function workspaceByUser(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.GET_WS_BY_USER_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.GET_WS_BY_USER_SUCCESS:
      return {
        loading: false,
        res: action?.result?.data,
      };
    case workspaceConstants.GET_WS_BY_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function templateToSurvey(state = {}, action) {
  switch (action.type) {
    case workspaceConstants.TEMPLATE_TO_SURVEY_REQUEST:
      return {
        loading: true,
      };
    case workspaceConstants.TEMPLATE_TO_SURVEY_SUCCESS:
      return {
        loading: false,
        success: true,
        res: action?.res,
      };
    case workspaceConstants.TEMPLATE_TO_SURVEY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
