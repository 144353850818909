import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Header, MainMenu } from "../../Common/Header";
import CalendarModal from "./CalendarModal";
import { useDispatch } from "react-redux";
import { appointmentActions } from "../../../actions/appointmentActions";

const localizer = momentLocalizer(moment);

const initialAppointments = [
  { id: 1, title: "Team Meeting", Organizer: "", organizerId:"",location: "California", start: new Date(2024, 10, 8, 10, 0), end: new Date(2024, 10, 8, 11, 0), people: ["John", "Jane"], peopleIds: [] },
  { id: 2, title: "Project Discussion",Organizer: "",organizerId:"", location: "USA", start: new Date(2024, 10, 9, 14, 0), end: new Date(2024, 10, 9, 15, 0), people: ["Alice"], peopleIds: [] },
  { id: 3, title: "Project Explanation",Organizer: "",organizerId:"", location: "USA", start: new Date(2024, 11, 9, 14, 0), end: new Date(2024, 11, 9, 15, 0), people: ["Jane"], peopleIds: [] },
];

const VocComponent = () => {

  const dispatch = useDispatch();

  const [appointments, setAppointments] = useState(initialAppointments);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({ title: "",Organizer: "",organizerId: null, location: "", start: "", end: "", people: [], peopleIds: [] });

  const handleSelectAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setFormValues({
      title: appointment.title,
      Organizer: appointment.Organizer,
      organizerId: appointment.organizerId,
      location: appointment.location,
      start: appointment.start,
      end: appointment.end,
      people: appointment.people || [],
      peopleIds: []
    });
    setModalOpen(true);
  };

  const handleAddClick = () => {
    setSelectedAppointment(null);
    setFormValues({ title: "", Organizer: "", organizerId:"",location: "", start: new Date(), end: new Date(), people: [], peopleIds: [] });
    setModalOpen(true);
  };

  const handleSave = (newAppointment) => {
    // if (selectedAppointment) {
    //   setAppointments(appointments.map((appt) => (appt.id === selectedAppointment.id ? newAppointment : appt)));
    // } else {
    //   setAppointments([...appointments, newAppointment]);
    // }

    dispatch(appointmentActions.addAppointments(newAppointment))
    setModalOpen(false);
    setSelectedAppointment(null);
  };

  const handleDelete = () => {
    if (selectedAppointment) {
      setAppointments(appointments.filter((appt) => appt.id !== selectedAppointment.id));
      setModalOpen(false);
      setSelectedAppointment(null);
    }
  };

  return (
    <div>
      <Header />
      <MainMenu />
      <div style={{ padding: 20 }}>
        <Typography variant="h4" gutterBottom>Calendar</Typography>
        <Calendar
          localizer={localizer}
          events={appointments}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, marginBottom: 20 }}
          selectable
          onSelectEvent={handleSelectAppointment}
        />

        <Fab color="primary" aria-label="add" onClick={handleAddClick} style={{ position: "fixed", bottom: 20, right: 20 }}>
          <AddIcon />
        </Fab>

        <CalendarModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          formValues={formValues}
          setFormValues={setFormValues}
          selectedAppointment={selectedAppointment}
        />
      </div>
    </div>
  );
};

export default VocComponent;
