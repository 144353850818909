import api from "./api";
import axios from "axios";
import { getLocalAccessToken } from "../helpers";
import devInstance from "./devapi";


async function getAllCustomers() {
  const result = await api.get(`/voc_crm/customers`);
  return result;
}

async function getAllScheduledCalls(customerId) {
  const result = await api.get(
    `/voc_crm/customers/${customerId}/scheduled-calls/`
  );
  return result;
}


async function getAllCallsLogs(customerId) {
  const result = await api.get(`/voc_crm/customers/${customerId}/call/logs`);
  return result;
}


async function addCustomer(customerInfo) {
  const result = await api.post(`/voc_crm/customers`, customerInfo);
  return result;
}

async function updateCustomerDataFormAPI(customerId, updateCustomerPayload) {
  const result = await api.put(
    `/voc_crm/customers/${customerId}`,
    updateCustomerPayload
  );
  return result;
}

async function getCustomerDataById(customerId) {
  const result = await api.get(`/voc_crm/customers/${customerId}`);
  return result;
}

async function getCustomerDataByNo(no) {
  const result = await api.get(`/voc_crm/customers?phone_number=${no}`);
  return result;
}


async function deleteCustomer(customerId) {
  const result = await api.delete(`/voc_crm/customers/${customerId}`);
  return result;
}

// API calls for CSV unique data from CSV
async function uploadCustomers(csvData, userID) {
  // const result = await api.post(`/voc_crm/upload_customers?user_id=${userID}`, csvData);
  const result = await api.post(`/voc_crm/customers/upload`, csvData);
  return result;
}

async function uploadDuplicateData(duplicateCSVData, customerId) {
  const result = await api.post(
    `/voc_crm/customers/${customerId}`,
    duplicateCSVData
  );
  return result;
}

//API Call for notes sections
async function getAllNotes(id) {
  const result = await api.get(`/voc_crm/customers/${id}/notes`);
  return result;
}

// async function addNotes(addNotesData, Id) {
//   const result = await api.post(`/voc_crm/customers/${Id}/notes`, addNotesData);
//   return result;
// }

async function editNotes(customerId, notesId, editNotesPayload) {
  const result = await api.put(
    `/voc_crm/customers/${customerId}/notes/${notesId}`,
    editNotesPayload
  );
  return result;
}

async function deleteNotes(customerId, notesId) {
  const result = await api.delete(
    `/voc_crm/customers/${customerId}/notes/${notesId}`
  );
  return result;
}

//below API for sending email
async function getEmailDetails(surveyId) {
  // /voc_crm/customers/22/send-email
  const result = await api.get(`/getAudience?survey_id=${surveyId}`);
  return result;
}

const sendEmail = async (params, customerId) => {
  const result = await api.post(
    `/voc_crm/customers/${customerId}/send-email`,
    params
  );
  return result;
};

async function getSummaryResponse() {
  const result = await api.get(`/voc_crm/summary_response`);
  return result;
}


async function addNotes(addNotesData, Id) {
  const result = await api.post(`/voc_crm/customers/${Id}/notes`, addNotesData);
  return result;
}


const createCallLogs = async (payload, Id) => {
  const result = await api.post(`/voc_crm/customers/${Id}/call/logs`, payload);
  return result;
};


export const CrmService = {
  getAllCustomers,
  getAllScheduledCalls,
  uploadCustomers,
  getAllNotes,
  addCustomer,
  addNotes,
  uploadDuplicateData,
  getCustomerDataById,
  getCustomerDataByNo,
  updateCustomerDataFormAPI,
  getEmailDetails,
  editNotes,
  deleteCustomer,
  deleteNotes,
  sendEmail,
  getAllCallsLogs,
  getSummaryResponse,
  createCallLogs
};
