import { Header } from "../../Common/Header";
import { MainMenu } from "../../Common/Header";

import ViewNotesTable from "../../Table/ViewNotesTable";

const ViewNotes = () => {
  return (
    <>
      <Header />
      <MainMenu />
      <div style={{ width: "1000px", margin: "50px auto" }}>
        <h3>View Notes Table</h3>
        <ViewNotesTable />
      </div>
    </>
  );
};

export default ViewNotes;
