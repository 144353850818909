import React from 'react';
import './Settings.scss';
import CustomerTable from '../CRM/CustomerTable/CustomerTable';

const PlanDetail = ({data}) => {

    return  <div className='profile__form'>
        <div className="form-group">
            <ul className="plan__list">
                <li className="plan__list--item">
                    <h2>Subscription Plan</h2>
                    <p>{ data?.plan_name || "" }</p>
                </li>
                <li className="plan__list--item">
                    <h2>Billing</h2>
                    <p style={{textTransform: 'capitalize'}}>{ data?.plan_period || "" }</p>
                </li>
                <li className="plan__list--item">
                    <h2>{ data?.currency || "USD" }</h2>
                    <p>{ data?.plan_price_display || "0" }/month</p>
                </li>
            </ul>
        </div>
        {/*<button type="submit" className="form-submit">Update</button>*/}
    </div>
};

export default PlanDetail;