import { agentsConstants } from "../constants/agentsConstants";
import { AgentsService } from "../services/AgentsService";
import { swalAlert } from "../voc/Common/SwalAlert";

const addAgents = (addAgentsData, Id) => {
  const request = () => ({
    type: agentsConstants.ADD_AGENTS_REQUEST,
  });

  const success = (agents) => ({
    type: agentsConstants.ADD_AGENTS_SUCCESS,
    agents,
  });

  const failure = (error) => ({ 
    type: agentsConstants.ADD_AGENTS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    AgentsService.addAgents(addAgentsData, Id).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Agent added successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const editAgent = (addAgentsData, agentId) => {
  
  const request = () => ({
    type: agentsConstants.EDIT_AGENTS_REQUEST,
  });

  const success = (alerts) => ({
    type: agentsConstants.ADD_AGENTS_SUCCESS,
    alerts,
  });

  const failure = (error) => ({
    type: agentsConstants.EDIT_AGENTS_FAILURE,
    error,
  });
  return (dispatch) => {
    dispatch(request());
    AgentsService.editAgents(addAgentsData, agentId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Agents updated successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const deleteAgent = (agentId) => {  
  const request = () => ({
    type: agentsConstants.DELETE_AGENTS_REQUEST,
  });
  
  const success = (noteId) => ({
    type: agentsConstants.ADD_AGENTS_SUCCESS,
    noteId,
  });
  
  const failure = (error) => ({
    type: agentsConstants.EDIT_AGENTS_FAILURE,
    error,
  });
  
  return (dispatch) => {
    dispatch(request());
    AgentsService.deleteAgents(agentId).then(
      (alerts) => {
        dispatch(success(alerts));
        swalAlert({
          icon: "success",
          title: "Agent deleted successfully!",
          showConfirmButton: true,
        });
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

export const agentsAction = {
  addAgents,
  editAgent,
  deleteAgent
};
