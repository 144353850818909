export const dropDownConstants = {
  FETCH_COUNTRIES_REQUEST: "FETCH_COUNTRIES_REQUEST",
  FETCH_COUNTRIES_SUCCESS: "FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_FAILURE: "FETCH_COUNTRIES_FAILURE",

  FETCH_STATES_REQUEST: "FETCH_STATES_REQUEST",
  FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
  FETCH_STATES_FAILURE: "FETCH_STATES_FAILURE",
  
};
