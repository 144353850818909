import { useEffect, useState } from "react";
import { useLocation,withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-101749341-5");
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
      console.log("googleanalytics",location.pathname + location.search)
    }
  }, [initialized, location]);
};

export default withRouter(usePageTracking);