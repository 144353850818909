import api from "./api";

async function getAlerts() {
  const result = await api.get(`/getAlerts`);
  return result;
}

async function updateAlert(id) {
  const result = await api.post(`/updateAlert`, {
    id: id,
  });
  return result;
}

async function getAllAlerts() {
  const result = await api.get(`/getAllAlerts`);
  return result;
}

export const alertServices = {
  getAlerts,
  updateAlert,
  getAllAlerts,
};
