import { alertConstants } from '../constants'

export function alert(state = {}, action) {
    switch(action.type) {
        case alertConstants.REQUEST:
            return {
                loading: true
            };
        case alertConstants.SUCCESS:
            return {
                ...state,
                loading: false,
                items: action?.message?.data?.alerts,
                type: 'alert-success',
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                loading: false,
                message: action.message,
                type: 'alert-error'
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}
