export const teamConstants = {
    GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    SEND_TO_TEAM_SUCCESS: 'SEND_TO_TEAM_SUCCESS',
    SEND_TO_TEAM_FAILURE: 'SEND_TO_TEAM_FAILURE',
    SEND_TO_TEAM_REQUEST: 'SEND_TO_TEAM_REQUEST',
    CREATE_CONTACT_US_SUCCESS: 'CREATE_CONTACT_US_SUCCESS',
    CREATE_CONTACT_US_FAILURE: 'CREATE_CONTACT_US_FAILURE',
    CREATE_CONTACT_US_REQUEST: 'CREATE_CONTACT_US_REQUEST'
};