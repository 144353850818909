export const emailConstants = {
        CREATE_EMAIL_LINKS_REQUEST: 'CREATE_EMAIL_LINKS_REQUEST',
        CREATE_EMAIL_LINKS_SUCCESS: 'CREATE_EMAIL_LINKS_SUCCESS',
        CREATE_EMAIL_LINKS_FAILURE: 'CREATE_EMAIL_LINKS_FAILURE',

        CREATE_LINK_TEMPLATE_REQUEST: 'CREATE_LINK_TEMPLATE_REQUEST',
        CREATE_LINK_TEMPLATE_SUCCESS: 'CREATE_LINK_TEMPLATE_REQUEST',
        CREATE_LINK_TEMPLATE_FAILURE: 'CREATE_LINK_TEMPLATE_FAILURE',

        UPDATE_LINK_REQUEST: 'UPDATE_LINK_REQUEST',
        UPDATE_LINK_SUCCESS: 'UPDATE_LINK_SUCCESS',
        UPDATE_LINK_FAILURE: 'UPDATE_LINK_FAILURE',

        CREATE_GROUP_REQUEST: 'CREATE_GROUP_REQUEST',
        CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
        CREATE_GROUP_FAILURE: 'CREATE_GROUP_FAILURE',

        UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
        UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
        UPDATE_GROUP_FAILURE: 'UPDATE_GROUP_FAILURE',

        DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
        DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
        DELETE_GROUP_FAILURE: 'DELETE_GROUP_FAILURE',

        GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
        GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
        GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',

        GET_GROUP_BY_NAME_REQUEST: 'GET_GROUP_BY_NAME_REQUEST',
        GET_GROUP_BY_NAME_SUCCESS: 'GET_GROUP_BY_NAME_SUCCESS',
        GET_GROUP_BY_NAME_FAILURE: 'GET_GROUP_BY_FAILURE',

        DELETE_GROUP_MEMBER_REQUEST: 'DELETE_GROUP_MEMBER_REQUEST',
        DELETE_GROUP_MEMBER_SUCCESS: 'DELETE_GROUP_MEMBER_SUCCESS',
        DELETE_GROUP_MEMBER_FAILURE: 'DELETE_GROUP_MEMBER_FAILURE',

        GET_AUDIENCE_REQUEST: 'GET_AUDIENCE_REQUEST',
        GET_AUDIENCE_SUCCESS: 'GET_AUDIENCE_SUCCESS',
        GET_AUDIENCE_FAILURE: 'GET_AUDIENCE_FAILURE',

        UPDATE_AUDIENCE_REQUEST: 'UPDATE_AUDIENCE_REQUEST',
        UPDATE_AUDIENCE_SUCCESS: 'UPDATE_AUDIENCE_SUCCESS',
        UPDATE_AUDIENCE_FAILURE: 'UPDATE_AUDIENCE_FAILURE',

        GET_PERSON_BY_EMAIL_REQUEST: 'GET_PERSON_BY_EMAIL_REQUEST',
        GET_PERSON_BY_EMAIL_SUCCESS: 'GET_PERSON_BY_EMAIL_SUCCESS',
        GET_PERSON_BY_EMAIL_FAILURE: 'GET_PERSON_BY_EMAIL_FAILURE',

        UPDATE_PERSON_REQUEST: 'UPDATE_AUDIENCE_REQUEST',
        UPDATE_PERSON_SUCCESS: 'UPDATE_PERSON_SUCCESS',
        UPDATE_PERSON_FAILURE: 'UPDATE_PERSON_FAILURE',

        UPDATE_PERSON_RESPONSE_REQUEST: 'UPDATE_PERSON_RESPONSE_REQUEST',
        UPDATE_PERSON_RESPONSE_SUCCESS: 'UPDATE_PERSON_RESPONSE_SUCCESS',
        UPDATE_PERSON_RESPONSE_FAILURE: 'UPDATE_PERSON_RESPONSE_FAILURE',

        GET_GROUP_ANSWERS_REQUEST: 'GET_GROUP_ANSWERS_REQUEST',
        GET_GROUP_ANSWERS_SUCCESS: 'GET_GROUP_ANSWERS_SUCCESS',
        GET_GROUP_ANSWERS_FAILURE: 'GET_GROUP_ANSWERS_FAILURE',

        CREATE_GENERAL_GROUP_REQUEST: 'CREATE_GENERAL_GROUP_REQUEST',
        CREATE_GENERAL_GROUP_SUCCESS: 'CREATE_GENERAL_GROUP_SUCCESS',
        CREATE_GENERAL_GROUP_FAILURE: 'CREATE_GENERAL_GROUP_FAILURE',

        GET_ANSWERS_IN_ALL_GROUPS_REQUEST: 'GET_ANSWERS_IN_ALL_GROUPS_REQUEST',
        GET_ANSWERS_IN_ALL_GROUPS_SUCCESS: 'GET_ANSWERS_IN_ALL_GROUPS_SUCCESS',
        GET_ANSWERS_IN_ALL_GROUPS_FAILURE: 'GET_ANSWERS_IN_ALL_GROUPS_FAILURE',


        SEND_SURVEY_REQUEST: 'SEND_SURVEY_REQUEST',
        SEND_SURVEY_REQUEST_SUCCESS: 'SEND_SURVEY_REQUEST_SUCCESS',
        SEND_SURVEY_REQUEST_FAILURE: 'SEND_SURVEY_REQUEST_FAILURE',


        SAVE_SURVEY_REC_REQUEST: 'SAVE_SURVEY_REC_REQUEST',
        SAVE_SURVEY_REC_SUCCESS: 'SAVE_SURVEY_REC_SUCCESS',
        SAVE_SURVEY_REC_FAILURE: 'SAVE_SURVEY_REC_FAILURE',

        UPDATE_GENERAL_GROUP_ID_REQUEST: 'UPDATE_GENERAL_GROUP_ID_REQUEST',
        UPDATE_GENERAL_GROUP_ID_SUCCESS: 'UPDATE_GENERAL_GROUP_ID_SUCCESS',
        UPDATE_GENERAL_GROUP_ID_FAILURE: 'UPDATE_GENERAL_GROUP_ID_FAILURE'

}