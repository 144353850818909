export const callAIConstant = {
  // GET DATA
  GET_CALL_REQUEST: "GET_CALL_REQUEST",
  GET_CALL_SUCCESS: "GET_CALL_SUCCESS",
  GET_CALL_ERROR: "GET_CALL_ERROR",
  GET_CALL_CLEAR: "GET_CALL_CLEAR",

  // ADD DATA
  ADD_CALL_REQUEST: "ADD_CALL_REQUEST",
  ADD_CALL_SUCCESS: "ADD_CALL_SUCCESS",
  ADD_CALL_FAILUER: "ADD_CALL_FAILUER",
};
