// import { rulesConstants } from "../constants/rulesConstants";

// export function rulesLogic(state = {}, action) {
//     switch (action.type) {

//         //addRules
//         case rulesConstants.ADD_RULES_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case rulesConstants.ADD_RULES_SUCCESS:
//       return {
//         ...state, 
//         loading: false,
//         isAgentAdded: true, 
//         type: "ADD_RULES_SUCCESS",
//       };
//     case rulesConstants.ADD_RULES_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         message: action.error,
//         type: "error",
//       };
//       case appointmentConstants.RESET_APPOINTMENT_ADDED:
//         return {
//           ...state,
//           isRuleAdded: false,
//         };

//     default:
//         return state;
//     }
// }

import { rulesConstants } from "../constants/rulesConstants";

const initialState = {
  loading: false,
  isRuleAdded: false,
  isRuleDeleted: false,
  rules: [],
  message: "",
};

export function rulesLogic(state = initialState, action) {
  switch (action.type) {
    case rulesConstants.ADD_RULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case rulesConstants.ADD_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        isRuleAdded: true, // Set success flag to true
        agents: [...state.rules, action.rules], // Optional: update agents list in state if needed
      };
    case rulesConstants.DELETE_RULES_FAILURE:
      return {
        ...state,
        loading: false, 
        message: action.error,
        type: "error",
      };
      case rulesConstants.RESET_RULES_ADDED:
  return {
    ...state,
    isRuleAdded: false,
  };

  // Handle delete rule actions
  case rulesConstants.DELETE_RULES_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case rulesConstants.DELETE_RULES_SUCCESS:
    return {
      ...state,
      loading: false,
      isRuleDeleted: true, // Set delete flag to true
      rules: state.rules.filter(rule => rule.id !== action.ruleId), // Remove deleted rule from state
    };
  case rulesConstants.DELETE_RULES_FAILURE:
    return {
      ...state,
      loading: false,
      message: action.error,
      type: "error",
    };
  case rulesConstants.RESET_RULES_DELETED:
    return {
      ...state,
      isRuleDeleted: false, // Reset delete flag after re-fetching data
    };

    default:
      return state;
  }
}
