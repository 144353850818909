export const queryBuilderConstants = {
  REQUEST: "QUERYBUILDER_REQUEST",
  SUCCESS: "QUERYBUILDER_SUCCESS",
  ERROR: "QUERYBUILDER_ERROR",

  SAVE_PIVOTSTATE_REQUEST: "SAVE_PIVOTSTATE_REQUEST",
  SAVE_PIVOTSTATE_SUCCESS: "SAVE_PIVOTSTATE_SUCCESS",
  SAVE_PIVOTSTATE_FAILURE: "SAVE_PIVOTSTATE_FAILURE",

  GET_QUERIES_BY_ID_REQUEST: "GET_QUERIES_BY_ID_REQUEST",
  GET_QUERIES_BY_ID_SUCCESS: "GET_QUERIES_BY_ID_SUCCESS",
  GET_QUERIES_BY_ID_FAILURE: "GET_QUERIES_BY_ID_FAILURE",

  DELETE_QUERY_REQUEST: "DELETE_QUERY_REQUEST",
  DELETE_QUERY_SUCCESS: "DELETE_QUERY_SUCCESS",
  DELETE_QUERY_FAILURE: "DELETE_QUERY_FAILURE",
  CLEAR: "QUERYBUILDER_CLEAR",
};
