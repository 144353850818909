export const dashboardConstants = {

    GET_LATEST_SURVEYS_REQUEST: 'GET_LATEST_SURVEYS_REQUEST',
    GET_LATEST_SURVEYS_SUCCESS: 'GET_LATEST_SURVEYS_SUCCESS',
    GET_LATEST_SURVEYS_FAILURE: 'GET_LATEST_SURVEYS_FAILURE',

    GET_ALL_THEMES_REQUEST: 'GET_ALL_THEMES_REQUEST',
    GET_ALL_THEMES_SUCCESS: 'GET_ALL_THEMES_SUCCESS',
    GET_ALL_THEMES_FAILURE: 'GET_ALL_THEMES_FAILURE',

}