
export const rulesConstants = {
// get all rules
GET_ALL_RULES_REQUEST: "GET_ALL_RULES_REQUEST",
GET_ALL_RULES_SUCCESS: "GET_ALL_RULES_SUCCESS",
GET_ALL_RULES_FAILURE: "GET_ALL_RULES_FAILURE",

// add rules
ADD_RULES_REQUEST: "ADD_RULES_REQUEST",
ADD_RULES_SUCCESS: "ADD_RULES_SUCCESS",
ADD_RULES_FAILURE: "ADD_RULES_FAILURE",
RESET_RULES_ADDED: "RESET_RULES_ADDED",

// edit rules
EDIT_RULES_REQUEST: "EDIT_RULES_REQUEST",
EDIT_RULES_SUCCESS: "EDIT_RULES_SUCCESS",
EDIT_RULES_FAILURE: "EDIT_RULES_FAILURE",

// delete rules
DELETE_RULES_REQUEST: "DELETE_RULES_REQUEST",
DELETE_RULES_SUCCESS: "DELETE_RULES_SUCCESS",
DELETE_RULES_FAILURE: "DELETE_RULES_FAILURE",
RESET_RULES_DELETED: "RESET_RULES_DELETED"

}