import React, {useState, useEffect} from 'react';
import { getUser,checkPhone } from '../Common/Utils';
import Backdrop from '../Common/Backdrop';
import api from "../../services/api";
import './Settings.scss';

const UpdateUser = async (data) => {
    const result = await api.put(
        `/updateUser`,
        data
    );
    return result;
};

const BasicInfo = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");

    useEffect(()=> {
        const usr = getUser();
        if(usr){
           setData({
               first_name: usr.firstName,
               last_name: usr.lastName,
               email: usr.email,
               mobile: usr.mobile
           })
        }
    }, []);


    const onChange = (e) => {
        const {name, value} = e && e.target || {};
        setData(data => ({...data, [name]: value }));
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && update(data);
    };

    const phoneValidation = (e) => {
        const {value} = e?.target || {};
        if(value && value.length){
            const isValid = checkPhone(value);
            setErr(isValid ? "" : "Enter a valid telephone number");
        }
    };

    const update = () => {
        if(data.first_name.length && data.last_name.length){
            updateBasicInfo();
        }else {
            setErr("Fill the all required fields")
        }
    };

    const updateBasicInfo = async () => {
        if(err && err.length){
            return;
        }
        setLoading(true);
        UpdateUser(data).then( (res) =>{
                if(res && res.status === 200 && res.data.data){
                    let user = localStorage.getItem("user");
                    user = user && JSON.parse(user);
                    localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data}));
                }
                setLoading(false);
            },
            (error) => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e?.length ? e : "Something went wrong");
                setLoading(false);
        });
    };

    return <div className='profile__form'>
        <div className="form-group">
            <label htmlFor="firstname" className="form-label">First Name<p className="star">*</p></label>
            <input type="text" id="firstname" className="form-input" name="first_name"
                   value={data.first_name ||''} style={{textTransform: 'capitalize'}}
                   placeholder="Enter Firstname" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="lastname" className="form-label">Last Name<p className="star">*</p></label>
            <input type="text" id="lastname" className="form-input" name="last_name"
                   value={data.last_name || ''} style={{textTransform: 'capitalize'}}
                   placeholder="Enter Lastname" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" id="email" className="form-input" name="email" value={data.email || ''}
                   placeholder="Enter Email" readOnly={true}/>
        </div>
        <div className="form-group">
            <label htmlFor="tel" className="form-label">Telephone </label>
            <input type="tel" id="tel" className="form-input" name="mobile" value={data.mobile || ''}
                   placeholder="Enter Telephone" onChange={onChange} onKeyPressCapture={onKeyPress} onBlur={phoneValidation}/>
        </div>
        { err.length ? <span style={{color:'red'}}>{err}</span> : null }
        <button className="form-submit" onClick={update}>Update</button>
        <Backdrop show={loading}/>
    </div>

};

export default BasicInfo;
