import api from "./api";

async function getQueryBuilderData(surveyId) {
  const result = await api.get(`/answerForQueryBuilder?survey_id=${surveyId}`);
  return result;
}

async function savePivotState(
  detail_id,
  query_name,
  query_description,
  questions,
  pivotState
) {
  const params = {
    detail_id: detail_id,
    query_name: query_name,
    query_description: query_description,
    questions: questions,
    pivot_state: JSON.stringify(pivotState),
  };

  const result = await api.post(
    `/createSavedQuery`,

    { params }
  );
  console.log("In savepivotstate");
  console.log(params);
  return result;
}

async function deleteQuery(detail_id, query_name) {
  const result = await api.post(`/deleteQuery`, {
    params: {
      detail_id: detail_id,
      query_name: query_name,
    },
  });
  console.log("GETQQ RES", result);

  return result;
}
async function getQueriesById(detail_id) {
  const result = await api.get(`/getQueriesById`, {
    params: {
      detail_id: detail_id,
    },
  });
  console.log("GETQQ RES", result);

  return result;
}
export const queryBuilderService = {
  getQueryBuilderData,
  savePivotState,
  deleteQuery,
  getQueriesById,
};
