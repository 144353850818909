import { emailConstants } from "../constants";
import { emailService } from "../services/emailService";
import { alertActions, userActions } from "./";
import { history } from "../helpers";
import { swalAlert } from "../voc/Common/SwalAlert";

const createEmailLinks = (audience) => {
  const request = (audience) => ({
    type: emailConstants.CREATE_EMAIL_LINKS_REQUEST,
    audience,
  });
  const success = (audience) => ({
    type: emailConstants.CREATE_EMAIL_LINKS_SUCCESS,
    audience,
  });
  const failure = (audience) => ({
    type: emailConstants.CREATE_EMAIL_LINKS_FAILURE,
    audience,
  });
  // console.log("email actions?", audience);
  let req = audience;
  return (dispatch) => {
    dispatch(request(audience));
    emailService.createEmailLinks(audience).then(
      (audience) => {
        // history.push('/'); // WHAT SHOULD THIS BE?
        dispatch(success(audience));
        dispatch(alertActions.success("Email Links creation successful"));
      },
      (error) => {
        dispatch(failure(audience, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createGroup = (group) => {
  const request = (group) => ({
    type: emailConstants.CREATE_GROUP_REQUEST,
    group,
  });
  const success = (group) => ({
    type: emailConstants.CREATE_GROUP_SUCCESS,
    group,
  });
  const failure = (group) => ({
    type: emailConstants.CREATE_GROUP_FAILURE,
    group,
  });

  return (dispatch) => {
    dispatch(request(group));

    emailService.createGroup(group).then(
      (group) => {
        history.push("/");
        dispatch(success(group));
        dispatch(alertActions.success("Group creation successful"));
      },
      (error) => {
        dispatch(failure(group, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateGeneralGroupId = (survey_id, new_survey_id) => {
  const request = (survey_id) => ({
    type: emailConstants.UPDATE_GENERAL_GROUP_ID_REQUEST,
    survey_id,
  });
  const success = (survey_id) => ({
    type: emailConstants.UPDATE_GENERAL_GROUP_ID_SUCCESS,
    survey_id,
  });
  const failure = (survey_id) => ({
    type: emailConstants.UPDATE_GENERAL_GROUP_ID_FAILURE,
    survey_id,
  });

  return (dispatch) => {
    dispatch(request(survey_id));

    emailService.updateGeneralGroupId(survey_id, new_survey_id).then(
      (res) => {
        // history.push('/');
        // window.location.reload();

        dispatch(success(res));
        dispatch(alertActions.success("General group id update successful"));
        //window.location.reload()
      },
      (error) => {
        dispatch(
          failure(survey_id, error?.response?.data?.message?.toString())
        );
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};
const createGeneralGroup = (group) => {
  const request = (group) => ({
    type: emailConstants.CREATE_GENERAL_GROUP_REQUEST,
    group,
  });
  const success = (group) => ({
    type: emailConstants.CREATE_GENERAL_GROUP_SUCCESS,
    group,
  });
  const failure = (group) => ({
    type: emailConstants.CREATE_GENERAL_GROUP_FAILURE,
    group,
  });

  return (dispatch) => {
    dispatch(request(group));

    emailService.createGroup(group).then(
      (group) => {
        // history.push('/');
        // window.location.reload();

        dispatch(success(group));
        dispatch(alertActions.success("General group creation successful"));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(group, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const updateGroup = (group) => {
  const request = (group) => ({
    type: emailConstants.UPDATE_GROUP_REQUEST,
    group,
  });
  const success = (group) => ({
    type: emailConstants.UPDATE_GROUP_SUCCESS,
    group,
  });
  const failure = (group) => ({
    type: emailConstants.UPDATE_GROUP_FAILURE,
    group,
  });

  return (dispatch) => {
    dispatch(request(group));

    emailService.updateGroup(group).then(
      (group) => {
        history.push("/");
        dispatch(success(group));
        dispatch(alertActions.success("Group update successful"));
      },
      (error) => {
        dispatch(failure(group, error?.response?.data?.message?.toString()));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getGroups = (survey_id) => {
  const request = (survey_id) => ({
    type: emailConstants.GET_GROUPS_REQUEST,
    survey_id,
  });
  const success = (groups) => ({
    type: emailConstants.GET_GROUPS_SUCCESS,
    groups,
  });
  const failure = (error) => ({
    type: emailConstants.GET_GROUPS_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(survey_id));

    emailService.getGroups(survey_id).then(
      (groups) => {
        dispatch(success(groups));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getGroupByName = (name) => {
  const request = (name) => ({
    type: emailConstants.GET_GROUP_BY_NAME_REQUEST,
    name,
  });
  const success = (group) => ({
    type: emailConstants.GET_GROUP_BY_NAME_SUCCESS,
    group,
  });
  const failure = (error) => ({
    type: emailConstants.GET_GROUP_BY_NAME_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(name));

    emailService.getGroupByName(name).then(
      (group) => {
        dispatch(success(group));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const deleteGroup = (group) => {
  const request = (group) => ({
    type: emailConstants.DELETE_GROUP_REQUEST,
    group,
  });
  const success = (group) => ({
    type: emailConstants.DELETE_GROUP_SUCCESS,
    group,
  });
  const failure = (group) => ({
    type: emailConstants.DELETE_GROUP_FAILURE,
    group,
  });

  return (dispatch) => {
    dispatch(request(group));
    emailService.deleteGroup(group).then(
      (group) => {
        dispatch(success(group));
        dispatch(alertActions.success("Group deletion successful"));
      },
      (error) => {
        dispatch(failure(group));
        dispatch(
          alertActions.error(error?.response?.data?.message?.toString())
        );
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAudience = (survey_id) => {
  // console.log("GET AUDIENCE ACTION");
  const request = (survey_id) => ({
    type: emailConstants.GET_AUDIENCE_REQUEST,
    survey_id,
  });
  const success = (audience) => ({
    type: emailConstants.GET_AUDIENCE_SUCCESS,
    audience,
  });
  const failure = (error) => ({
    type: emailConstants.GET_AUDIENCE_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request(survey_id));

    emailService.getAudience(survey_id).then(
      (audience) => {
        dispatch(success(audience));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const updateAudience = (survey_id) => {
  const request = (survey_id) => ({
    type: emailConstants.GET_AUDIENCE_REQUEST,
    survey_id,
  });
  const success = (survey_id) => ({
    type: emailConstants.GET_AUDIENCE_SUCCESS,
    survey_id,
  });
  const failure = (survey_id) => ({
    type: emailConstants.GET_AUDIENCE_FAILURE,
    survey_id,
  });
  return (dispatch) => {
    dispatch(request(survey_id));
    emailService.updateAudience(survey_id).then(
      (audience) => {
        dispatch(success(audience));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const updateLink = (survey_id, email, link) => {
  const request = (link) => ({
    type: emailConstants.UPDATE_LINK_REQUEST,
    link,
  });
  const success = (link) => ({
    type: emailConstants.UPDATE_LINK_SUCCESS,
    link,
  });
  const failure = (link) => ({
    type: emailConstants.UPDATE_LINK_FAILURE,
    link,
  });
  return (dispatch) => {
    dispatch(request(link));
    emailService.updateLink(survey_id, email, link).then(
      (res) => {
        dispatch(success(res));
        window.location.reload();
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const updatePerson = (person_id) => {
  const request = (person_id) => ({
    type: emailConstants.GET_PERSON_REQUEST,
    person_id,
  });
  const success = (person_id) => ({
    type: emailConstants.GET_PERSON_SUCCESS,
    person_id,
  });
  const failure = (person_id) => ({
    type: emailConstants.GET_PERSON_FAILURE,
    person_id,
  });
  return (dispatch) => {
    dispatch(request(person_id));
    emailService.updatePerson(person_id).then(
      (person) => {
        dispatch(success(person));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const updatePersonResponse = (survey_id, email, responded) => {
  const request = (email) => ({
    type: emailConstants.UPDATE_PERSON_RESPONSE_REQUEST,
    email,
  });
  const success = (email) => ({
    type: emailConstants.UPDATE_PERSON_RESPONSE_SUCCESS,
    email,
  });
  const failure = (email) => ({
    type: emailConstants.UPDATE_PERSON_RESPONSE_FAILURE,
    email,
  });
  return (dispatch) => {
    dispatch(request(email));
    emailService.updatePersonResponse(survey_id, email, responded).then(
      (email) => {
        dispatch(success(email));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        //history.push("/");
      }
    );
  };
};

const updateSurveySent = (survey_id, email, survey_sent) => {
  const request = (email) => ({
    type: emailConstants.SEND_SURVEY_REQUEST,
    email,
  });
  const success = (email) => ({
    type: emailConstants.SEND_SURVEY_REQUEST_SUCCESS,
    email,
  });
  const failure = (email) => ({
    type: emailConstants.SEND_SURVEY_REQUEST_FAILURE,
    email,
  });

  return (dispatch) => {
    dispatch(request(email));
    emailService.updateSurveySent(survey_id, email, survey_sent).then(
      (email) => {
        dispatch(success(email));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getPersonByEmail = (survey_id, email) => {
  const request = (email) => ({
    type: emailConstants.GET_PERSON_BY_EMAIL_REQUEST,
    email,
  });
  const success = (email) => ({
    type: emailConstants.GET_PERSON_BY_EMAIL_SUCCESS,
    email,
  });
  const failure = (email) => ({
    type: emailConstants.GET_PERSON_BY_EMAIL_FAILURE,
    email,
  });
  return (dispatch) => {
    dispatch(request(email));
    emailService.getPersonByEmail(survey_id, email).then(
      (email) => {
        dispatch(success(email));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const getAnswersInGroup = (survey_id, group) => {
  const request = (group) => ({
    type: emailConstants.GET_GROUP_ANSWERS_REQUEST,
    group,
  });
  const success = (group) => ({
    type: emailConstants.GET_GROUP_ANSWERS_SUCCESS,
    group,
  });
  const failure = (group) => ({
    type: emailConstants.GET_GROUP_ANSWERS_FAILURE,
    group,
  });
  return (dispatch) => {
    dispatch(request(group));
    emailService.getAnswersInGroup(survey_id, group).then(
      (group) => {
        dispatch(success(group));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const getAnswersInAllGroups = (survey_id, group_list) => {
  const request = (group_list) => ({
    type: emailConstants.GET_GROUP_ANSWERS_REQUEST,
    group_list,
  });
  const success = (group_list) => ({
    type: emailConstants.GET_GROUP_ANSWERS_SUCCESS,
    group_list,
  });
  const failure = (group_list) => ({
    type: emailConstants.GET_GROUP_ANSWERS_FAILURE,
    group_list,
  });
  return (dispatch) => {
    dispatch(request(group_list));
    emailService.getAnswersInAllGroups(survey_id, group_list).then(
      (group) => {
        dispatch(success(group_list));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
        history.push("/");
      }
    );
  };
};

const saveSurveyRec = (rec) => {
  const request = (rec_input) => ({
    type: emailConstants.SAVE_SURVEY_REC_REQUEST,
    rec_input,
  });
  const success = (rec_input) => ({
    type: emailConstants.SAVE_SURVEY_REC_SUCCESS,
    rec_input,
  });
  const failure = (rec_input) => ({
    type: emailConstants.SAVE_SURVEY_REC_FAILURE,
    rec_input,
  });
  // console.log("REC PASSED IN...", rec);
  return (dispatch) => {
    // console.log("THIS IS THE REC?", rec);
    dispatch(request(rec));

    dispatch(success(rec));
  };
};

export const emailActions = {
  createEmailLinks,
  createGroup,
  updateGroup,
  getGroups,
  getGroupByName,
  deleteGroup,
  getAudience,
  updateAudience,
  updatePerson,
  updateLink,
  getPersonByEmail,
  updatePersonResponse,
  getAnswersInGroup,
  getAnswersInAllGroups,
  createGeneralGroup,
  saveSurveyRec,
  updateSurveySent,
  updateGeneralGroupId,
};
