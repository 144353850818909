import React, {useState, useEffect} from 'react';
import { getUser,checkPhone } from '../Common/Utils';
import Backdrop from '../Common/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import api from "../../services/api";
import './Settings.scss';

const UpdateUser = async (data) => {
    const result = await api.put(
        `/updateUser`,
        data
    );
    return result;
};

const VoipInfo = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [err, setErr] = useState("");
    const [booleanList, setBooleanList] = React.useState([]);

    useEffect(()=> {
        const usr = getUser();
        if(usr){
           setData({
               first_name: usr.firstName,
               last_name: usr.lastName,
               email: usr.email,
               mobile: usr.mobile,
               extension_number: usr.extension_number,
               calling_id: usr.calling_id,
               international_calling_enabled: usr.international_calling_enabled.toString(),
               company_number: usr.company_number,
           })
        }
        setBooleanList([
            {label: "Enabled", value: "true"},
            {label: "Disabled", value: "false"}
        ]);
    }, []);


    const onChange = (e) => {
        const {name, value} = e && e.target || {};
        setData(data => ({...data, [name]: value }));
    };

    const onKeyPress = (e) => {
        e.key === 'Enter' && update(data);
    };

    const phoneValidation = (e) => {
        const {value} = e?.target || {};
        if(value && value.length){
            const isValid = checkPhone(value);
            setErr(isValid ? "" : "Enter a valid telephone number");
        }
    };

    const update = () => {
        if(data.first_name.length && data.last_name.length){
            updateBasicInfo();
        }else {
            setErr("Fill the all required fields")
        }
    };

    const updateBasicInfo = async () => {
        if(err && err.length){
            return;
        }
        setLoading(true);
        UpdateUser(data).then( (res) =>{
                if(res && res.status === 200 && res.data.data){
                    let user = localStorage.getItem("user");
                    user = user && JSON.parse(user);
                    localStorage.setItem("user", JSON.stringify({ ...user, ...res.data.data}));
                }
                setLoading(false);
            },
            (error) => {
                const e = error?.response?.data?.message?.toString() || "";
                setErr(e?.length ? e : "Something went wrong");
                setLoading(false);
        });
    };

    return <div className='profile__form'>
        <div className="form-group">
            <label htmlFor="company_number" className="form-label">Company Number<p className="star">*</p></label>
            <input type="text" id="company_number" className="form-input" name="company_number"
                value={data.company_number ||''} style={{textTransform: 'capitalize'}}
                placeholder="Enter Your Company Number" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="calling_id" className="form-label">Calling Id<p className="star">*</p></label>
            <input type="text" id="calling_id" className="form-input" name="calling_id"
                   value={data.calling_id ||''} style={{textTransform: 'capitalize'}}
                   placeholder="Enter Calling Id" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        <div className="form-group">
            <label htmlFor="international_calling_enabled" className="form-label">International Calling<p className="star">*</p></label>
            <FormControl sx={{ minWidth: 120 }} size="small">
                <Select
                    name="international_calling_enabled"
                    value={data.international_calling_enabled || ""}
                    onChange={onChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ maxHeight: 200 }}
                    MenuProps={{ classes: { paper: "h-max-200" } }}
                >
                    <MenuItem key="Empty" value="" disabled>Select</MenuItem>
                    {booleanList.map(c=><MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
        <div className="form-group">
            <label htmlFor="extension_number" className="form-label">Extension Number<p className="star">*</p></label>
            <input type="text" id="extension_number" className="form-input" name="extension_number"
                   value={data.extension_number ||''} style={{textTransform: 'capitalize'}}
                   placeholder="Enter Extension Number" onChange={onChange} onKeyPressCapture={onKeyPress}/>
        </div>
        { err.length ? <span style={{color:'red'}}>{err}</span> : null }
        <button className="form-submit" onClick={update}>Update</button>
        <Backdrop show={loading}/>
    </div>

};

export default VoipInfo;
