import { audienceConstants } from "../constants";

export function audience(
  state = {
    audienceType: "mail_user",
    audienceAnalysisData: null,

    surveyStatus: "email_sent",

    lexicalAudienceType: "mail_user",
    lexicalAudienceAnalysisData: null,

    lexicalSurveyStatus: "email_sent",
    questionId: null,
  },
  action
) {
  switch (action.type) {
    case audienceConstants.GETAUDIENCE_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case audienceConstants.GETLEXICALAUDIENCE_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case audienceConstants.GETAUDIENCE_TYPE:
      return {
        ...state,

        audienceType: action.audienceType,
      };

    case audienceConstants.GETLEXICALAUDIENCE_TYPE:
      return {
        ...state,

        lexicalAudienceType: action.audienceType,
      };

    case audienceConstants.GETAUDIENCE_SUCCESS:
      return {
        ...state,
        audienceAnalysisData: action.audience.data,
      };

    case audienceConstants.GETLEXICALAUDIENCE_SUCCESS:
      return {
        ...state,
        lexicalAudienceAnalysisData: action.audience.data,
      };
    case audienceConstants.GETAUDIENCE_FAILURE:
      return {
        ...state,

        error: action.error,
      };

    case audienceConstants.GETLEXICALAUDIENCE_FAILURE:
      return {
        ...state,

        error: action.error,
      };

    case audienceConstants.GETQUESTION_ID:
      return {
        ...state,
        questionId: action.questionId,
      };
    case audienceConstants.GETSURVEYSTATUS:
      return {
        ...state,
        surveyStatus: action.surveyStatus,
      };

    case audienceConstants.GETLEXICALSURVEYSTATUS:
      return {
        ...state,
        lexicalSurveyStatus: action.surveyStatus,
      };

    case audienceConstants.GETAUDIENCE_RESET:
      return {
        audienceType: "mail_user",
        audienceAnalysisData: null,

        surveyStatus: "email_sent",
        questionId: null,
        lexicalAudienceType: "mail_user",
        lexicalAudienceAnalysisData: null,

        lexicalSurveyStatus: "email_sent",
      };
    default:
      return state;
  }
}
