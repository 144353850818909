import React from 'react';
import cn from 'classnames';
import './CommonWrapper.css';
import './commonStyle.scss';
import LoadingOverlay from 'react-loading-overlay';
import {Header, MainMenu} from "./Header";

const CommonWrapper = ({ component: Component, className, isLoader = false }) => {
    LoadingOverlay.propTypes = undefined
    return <LoadingOverlay active={isLoader} className= {`${isLoader && "loaderWrap"}`} spinner text='Loading ...'>
        <div className={cn('wrapper newWrapper', className)}>
            <Component />
        </div>
    </LoadingOverlay>
};

const WrapperComp = ({ children, className, isLoader = false }) => {
    LoadingOverlay.propTypes = undefined
    return <LoadingOverlay active={isLoader} className= {`${isLoader && "loaderWrap"}`} spinner text='Loading ...'>
        <div className={className}>
            {children}
        </div>
    </LoadingOverlay>
};



const Wrapper = ({ component: Component, className, otherInfo }) => {
    return <div className={cn(className)}>
        <Component otherInfo={otherInfo} />
    </div>
};

const QuestionAnswerProfile = ({ config, step, setStep, quesAns, setQuesAns, submitHandler, isLoader }) => {
    const { id, question: Question, image: rightImage, imageClass, imageText, imageTextClass, label, type, labelClass, fieldClass, dropDown, subLabel } = config[step],
        Image = () => <div className={"profileSetLeftImgWrap"}><img className={imageClass} src={rightImage} />{imageText && <div className={imageTextClass}>{imageText}</div>}</div>,
        LeftWrapper = () => <Wrapper component={Image} className={'leftWrapper profileLeftWrapper'} />,
        RightWrapper = () => <Wrapper component={Question} className={'rightWrapper profileSetRightWrap'} otherInfo={{ id, setStep, step, setQuesAns, quesAns, label, type, labelClass, fieldClass, dropDown, subLabel, submitHandler }} />,
        Content = () => <div className={"signInSinUpWrap profileQuestionsWrap"}><LeftWrapper /><RightWrapper /></div>;
    return <CommonWrapper component={Content} isLoader={isLoader} />
};

const StartSurvey = ({title, children})=>{
    const SurveyContent = () => {
        return (
            <>
                <Header/>
                <MainMenu dashboardMenu={true}/>
                {/*<div className="start-survey-title">{title}</div>
                <div className="title-underline"></div>*/}
                {children}
            </>
            )
    };

    return <CommonWrapper component={SurveyContent} className="start-survey newSurveyWrapper"/>;
}

export { CommonWrapper, Wrapper, QuestionAnswerProfile, StartSurvey, WrapperComp };
