import {dashboardConstants, campaignConstants} from '../constants';


export function latestSurveys(state = {}, action) {
    switch (action.type) {
        case dashboardConstants.GET_LATEST_SURVEYS_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.GET_LATEST_SURVEYS_SUCCESS:
            return {
                ...state,
                items: action.latest_surveys.data.latestSurveys
            };
        case dashboardConstants.GET_LATEST_SURVEYS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};

export function themes (state = {}, action) {
    switch (action.type) {
        case dashboardConstants.GET_ALL_THEMES_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.GET_ALL_THEMES_SUCCESS:
            return {
                loading: false,
                items: action?.result?.data || {}
            };
        case dashboardConstants.GET_ALL_THEMES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}