import { campaignConstants, dashboardConstants } from "../constants";
import { campaignService, dashboardService } from "../services";
import { workspaceActions } from "./";
import { history } from "../helpers";
import { swalAlert } from "../voc/Common/SwalAlert";

const getLatestSurveys = (team_id) => {
  const request = (latest_surveys) => ({
    type: dashboardConstants.GET_LATEST_SURVEYS_REQUEST,
    latest_surveys,
  });
  const success = (latest_surveys) => ({
    type: dashboardConstants.GET_LATEST_SURVEYS_SUCCESS,
    latest_surveys,
  });
  const failure = (latest_surveys) => ({
    type: dashboardConstants.GET_LATEST_SURVEYS_FAILURE,
    latest_surveys,
  });

  return (dispatch) => {
    dispatch(request());

    dashboardService.getLatestSurveys(team_id).then(
      (latest_surveys) => {
        dispatch(success(latest_surveys));
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions received.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const getAllThemes = (data) => {
  const request = (data) => ({
    type: dashboardConstants.GET_ALL_THEMES_REQUEST,
    data,
  });
  const success = (data) => ({
    type: dashboardConstants.GET_ALL_THEMES_SUCCESS,
    result: data,
  });
  const failure = (data) => ({
    type: dashboardConstants.GET_ALL_THEMES_FAILURE,
    data,
  });

  return (dispatch) => {
    dispatch(request());

    dashboardService.getAllThemes(data).then(
      (data) => {
        dispatch(success(data));
        //dispatch(alertActions.success(`Detail ${detail_id} question descriptions received.`));
      },
      (error) => {
        dispatch(failure(error?.response?.data?.message?.toString()));
        swalAlert({
          icon: "error",
          title: error?.response?.data?.message?.toString(),
          showConfirmButton: true,
        });
      }
    );
  };
};

const createDemo = (type) => {
  const workspaceID = localStorage.getItem("workspaceID");
  const {user_id, default_camp_id} = JSON.parse(localStorage.getItem("user"));
  const params = {survey_type: type, campaign_id: workspaceID || default_camp_id};
  return (dispatch) => {
    return dashboardService.createDemo(params).then(
        (data) => {
          swalAlert({
            title: "Demo is successfully created.",
            showConfirmButton: true,
          }).then(r=>{
            user_id && dispatch(workspaceActions.getWorkspaceByUser(user_id));
          });
          return data;
        },
        (error) => {
          swalAlert({
            icon: "error",
            title: error?.response?.data?.message?.toString(),
            showConfirmButton: true,
          });
        }
    );
  };
};

export const dashboardActions = {
  getLatestSurveys,
  getAllThemes,
  createDemo
};
