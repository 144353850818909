import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger";
import { rootReducer } from "../reducers";

// const loggerMiddleware = createLogger();
// export const store = createStore(
//   rootReducer,
//   applyMiddleware(
//     thunkMiddleware,
//     // Comment next 2 line for production
//     loggerMiddleware
//   ),
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
);
