// createGroup
// updateGroup
// getGroups
// getGroupByName
//deleteGroup
// getAudience
// updateAudience
// updatePerson

import api from "./api";

export const emailService = {
  createEmailLinks,
  getAudience,
  createGroup,
  getGroupByName,
  updateLink,
  getPersonByEmail,
  updatePersonResponse,
  getAnswersInGroup,
  getGroups,
  createGeneralGroup,
  getAnswersInAllGroups,
  updateSurveySent,
  updateGeneralGroupId,
};

async function createEmailLinks(audience) {
  const result = await api.post(`/createEmailLinks`, audience);

  return result;
}

async function updateGeneralGroupId(survey_id, new_survey_id) {
  var params = {
    old_id: survey_id,
    new_id: new_survey_id,
  };
  console.log("UPDATE GENERAL GROUP ID ", params);
  const result = await api.put(`/updateEmailGroupId`, {
    params,
  });
  return result;
}

async function createGeneralGroup(audience) {
  console.log("create general group email service");
  const result = await api.post(`/createEmailLinks`, audience);

  return result;
}

async function updateLink(survey_id, email, personal_link) {
  var params = {
    survey_id: survey_id,
    email: email,
    personal_link: personal_link,
  };
  const result = await api.put(`/updateLink`, params);

  return result;
}

async function updatePersonResponse(survey_id, email, responded) {
  var params = {
    survey_id: survey_id,
    email: email,
    responded: true,
  };
  const result = await api.put(`/updatePersonResponse`, params);

  return result;
}

async function updateSurveySent(survey_id, email, survey_sent) {
  var params = {
    survey_id: survey_id,
    email: email,
    survey_sent: survey_sent,
  };
  const result = await api.put(`/updateSurveySent`, params);

  return result;
}

async function getAnswersInGroup(survey_id, group) {
  // return async dispatch => {
  //     function onSuccess(success) {
  //         dispatch({ type: emailConstants.GET_GROUP_ANSWERS_SUCCESS, payload: success });
  //         return success;
  //     }
  //     function onError(error) {
  //         dispatch({ type: emailConstants.GET_GROUP_ANSWERS_FAILURE, error });
  //         return error;
  //     }
  //     try {
  //         console.log('async await with answers i ngroup?')
  //         const success = await axios.get(`${apiUrl}/getAnswersInGroup`, {
  //                 headers: authHeader(),
  //                 params: {
  //                     survey_id: survey_id,
  //                     group: group
  //                 }
  //             });
  //         return onSuccess(success);
  //     } catch (error) {
  //         return onError(error);
  //     }
  // }

  console.log("GET ANSWERS IN GROUP EMAIL SERVICE", group);
  // var params = {
  //     survey_id: survey_id,
  //     email: email
  // };

  const result = await api.get(`/getAnswersInGroup`, {
    params: {
      survey_id: survey_id,
      group: group,
    },
  });
  console.log("axios get", result);
  return result;
}

function getAnswersInAllGroups(survey_id, group_list) {
  console.log("GET ANSWERS IN ALL GROUPS", group_list);
  // var params = {
  //     survey_id: survey_id,
  //     email: email
  // };
  // const result = axios.get(`${apiUrl}/getAnswersInAllGroups`, {
  //     headers: authHeader(),
  //     params: {
  //         survey_id: survey_id,
  //         group_list: {'groups': group_list}
  //     }
  // });
  // console.log('axios get in get answers in all groups service', result)
  // return result;
}
async function getPersonByEmail(survey_id, email) {
  console.log("get person by email service");
  const result = await api.get(`/getPersonByEmail`, {
    params: {
      survey_id: survey_id,
      email: email,
    },
  });

  return result;
}

async function createGroup(group) {
  console.log("CREATE GROUP EMAIL SERVICE");
  const result = await api.put(`/createGroup`, group);

  return result;
}

async function getGroups(survey_id) {
  const result = api.get(`/getGeneralGroups`, {
    params: {
      survey_id: survey_id,
    },
  });

  return result;
}

async function getGroupByName(group) {
  const result = await api.get(`/getGroup`, {
    params: {
      group: group,
    },
  });

  return result;
}

async function getAudience(survey_id) {
  const result = await api.get(`/getAudience`, {
    params: {
      survey_id: survey_id,
    },
  });

  return result;
}
