import api from "./api";

export const similarCasesService = {
  getSimilarCases,
};

async function getSimilarCases(casetext, ndepth) {
  // var params = { casetext: casetext,ndepth:ndepth};
  console.log("getSimilarCases");
  console.log(casetext);
  const result = await api.post(`/getSimilarCase`, {
    casetext: casetext,
    ndepth: ndepth,
  });

  return result;
}
